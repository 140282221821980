import React, { useState, useEffect } from "react";
import { api } from "../utils/WebApiConfig";
import { handleError, handleSuccess, handleInfo } from "../utils/Toastify";
import { useHistory } from "react-router-dom";
import img_usuario from "../imagens/svg/img_usuario.png";
import img_cadastrar from "../imagens/svg/img_cadastrar.png";
import img_excluir from "../imagens/svg/img_excluir.png";

const Clientes = () => {
  let history = useHistory();
  var [url, setUrl] = useState("api/usuarios");

  const [senha, setSenha] = useState("");
  const [repetirSenha, setRepetirSenha] = useState("");

  const catchResponse = (status) => {
    if (status) {
      if (status === 401) {
        handleInfo("Sessão expirada.");
        history.push("/login");
      }
    }
    handleError("Ocorreu um erro inesperado na execução desta operação");
  };

  const save = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let data;
    if (senha !== repetirSenha) {
      handleInfo("Senha repetida esta diferente da nova senha");
      return false;
    }
    var url = "api/usuarios/" + sessionStorage.getItem("id");
    data = {
      usu_id: sessionStorage.getItem("id"),
      usu_senha: senha,    
    };

    api({
      method: "put",
      url: url,
      data: data,
      headers: {
        Authorization: "bearer " + sessionStorage.getItem("token"),
      },
    })
      .then((response) => {
        handleSuccess(response.headers.statusmessage);   
        limpar();       
      })
      .catch((error) => {
        //  catchResponse();

        catchResponse(error.response.status);
        console.log(error);
      });
  };

  const limpar = () => {
    setRepetirSenha("");
    setSenha("");
  };

  return (
    <>
      <div className="container-fluid">
        <div className="card">
          <div className="card-header"></div>
          <span className="span-card-titulo">
            <img src={img_usuario} className="img-card" alt="logo" />
            Alterar senha
          </span>
          <div id="triangulo-para-baixo"></div>
          <div className="card-body">
            <form id="form_regional" onSubmit={(e) => save(e)}>
              <div className="form-row mb-3">
                <div className="col-md-6 mb-3">
                  <label htmlFor="senha">Nova senha:</label>
                  <input
                    type="password"
                    className="form-control"
                    id="senha"
                    maxLength="10"
                    style={{ width: "100%" }}
                    autoComplete="new-password"
                    placeholder="Digite a nova senha"
                    onChange={(e) => setSenha(e.target.value)}
                    value={senha}
                    required
                  />
                </div>
              </div>
              <div className="form-row mb-3">
                <div className="col-md-6 mb-3">
                  <label htmlFor="repetirSenha">Repita a senha:</label>
                  <input
                    type="password"
                    className="form-control"
                    id="repetirSenha"
                    placeholder="Repita a senha"
                    style={{ width: "100%" }}
                    maxLength="10"
                    autoComplete="new-password"
                    onChange={(e) => setRepetirSenha(e.target.value)}
                    value={repetirSenha}
                    required
                  />
                </div>
              </div>
              <div className="text-left ">
                <button
                  className="btn btn-sm btn-51comvc "
                  type="submit"
                  id="btnAlterar"
                >
                  Alterar
                  <img src={img_cadastrar} className="img-btn" alt="logo" />
                </button>
                &nbsp;&nbsp;
                <button
                  className="btn btn-sm btn-51comvc "
                  onClick={(e) => limpar(e)}
                  type="button"
                  id="btn_limpar"
                >
                  Limpar
                  <img src={img_excluir} className="img-btn" alt="logo" />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default Clientes;
