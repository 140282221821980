import React, { useState, useEffect } from "react";
import { api } from "../utils/WebApiConfig";
import { handleError, handleSuccess, handleInfo } from "../utils/Toastify";
import { TablePagination } from "@material-ui/core";
import { ilikeToRegExp, removeAcento } from "../utils/Mascara";
import { useHistory } from "react-router-dom";
import { Times } from "../utils/Fontawesome";
import img_usuario from "../imagens/svg/img_usuario.png";
import img_cadastrar from "../imagens/svg/img_cadastrar.png";
import img_excluir from "../imagens/svg/img_excluir.png";
import img_alterar from "../imagens/svg/img_alterar.png";
import img_excluir2 from "../imagens/svg/img_excluir2.png";
import pesquisar from "../imagens/svg/img_lupa.png";
const Clientes = () => {
  let history = useHistory();
  var [url, setUrl] = useState("api/regional");

  const [reg_id, setReg_id] = useState("");
  const [reg_nome, setReg_nome] = useState("");

  const [regioes, setRegioes] = useState([]);
  const [nomeModal, setNomeModal] = useState("");
  const [idModal, setIdModal] = useState("");
  // variaveis da paginaçãp
  const [arrayFiltrado, setArrayFiltrado] = useState("");
  const [busca, setBusca] = useState("");
  const [regioesPag, setRegioesPag] = useState([]);

  const [onloadStatus, setOnloadStatus] = useState(0);
  const [lastID, setLastID] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    var inicio = newPage * rowsPerPage + 1;
    var fim = inicio + (rowsPerPage - 1);
    /*  console.log(
       "rowsPerPage:" +
         rowsPerPage +
         " newPage " +
         newPage +
         " " +
         inicio +
         " ---- " +
         fim
     );*/
    if (fim > arrayFiltrado.length) {
      fim = arrayFiltrado.length;
    }
    const cidadePag = [];
    for (let i = inicio - 1; i < fim; i++) {
      cidadePag.push(arrayFiltrado[i]);
    }
    setRegioesPag(cidadePag);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

    var inicio = 0 * event.target.value + 1;
    var fim = inicio + (event.target.value - 1);
    if (fim > arrayFiltrado.length) {
      fim = arrayFiltrado.length;
    }
    const cidadePag = [];
    for (let i = inicio - 1; i < fim; i++) {
      cidadePag.push(arrayFiltrado[i]);
    }
    setRegioesPag(cidadePag);
  };

  useEffect(() => {
    if(document.getElementsByClassName('modal-backdrop')[0]){
      var no = document.getElementsByClassName("modal-backdrop")[0];
      if (no.parentNode) {
        no.parentNode.removeChild(no);
      }
    }
    getRegional();
  }, []);

  const catchResponse = (status) => {
    if (status) {
      if (status === 401) {
        if(document.getElementById("btn_limpar")){
          document.getElementById("btn_limpar").click();
        } 
        handleInfo("Sessão expirada.");
        history.push("/login");
      }
    }
    handleError("Ocorreu um erro inesperado na execução desta operação");
  };

  const getRegional = () => {
    api
      .get("api/regionais", {
        headers: {
          Authorization: "bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((response) => {
        setRegioes(response.data);
        setArrayFiltrado(response.data);
        setTotalPages(response.data.length);
        var inicio = 0;
        var fim = 10;
        if (fim > response.data.length) {
          fim = response.data.length;
        }

        const usuarioPag = [];
        for (let i = inicio; i < fim; i++) {
          usuarioPag.push(response.data[i]);
        }
        setRegioesPag(usuarioPag);
        console.log(response.data);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };

  const editar = (regiao) => {
    document.getElementById("reg_nome").focus();
    setReg_nome(regiao.reg_nome);
    setReg_id(regiao.reg_id);
  };

  const dadosModal = (nome, id) => {
    setNomeModal(nome);
    setIdModal(id);
  };
  const save = (e, metodo) => {
    e.preventDefault();
    e.stopPropagation();
    let data, method;
    if (metodo) {
      method = "delete";
    } else {
      let existeCidade = regioes.findIndex((val) => val.reg_nome == reg_nome);
      if (existeCidade !== -1) {
        handleInfo("Região já existente");
        //  console.log(cidades);
        //  alert(existeCidade);
        return false;
      }
      if (!reg_id) {
        method = "post";
        if (!setReg_nome) {
          handleInfo("Preencha o campo senha.");
          return false;
        }
      } else {
        method = "put";
      }
    }

    if (method === "put") {
      data = {
        reg_id: reg_id,
        reg_nome: reg_nome,
      };
      var url = "api/regionais/" + reg_id;
    } else if (method === "post") {
      data = {
        reg_nome: reg_nome,
      };
      var url = "api/regionais";
    } else if (method === "delete") {
      var url = "api/regionais/" + idModal;
      data = {
        id: idModal,
      };
      setNomeModal("");
      setIdModal("");
    }

    api({
      method: method,
      url: url,
      data: data,
      headers: {
        Authorization: "bearer " + sessionStorage.getItem("token"),
      },
    })
      .then((response) => {
        handleSuccess(response.headers.statusmessage);   
        if(document.getElementById("btn_limpar")){
          document.getElementById("btn_limpar").click();
        } 
          getRegional();
      
      })
      .catch((error) => {
        //  catchResponse();

        catchResponse(error.response.status);
        console.log(error);
      });
  };

  const limpar = () => {
    setReg_id("");
    setReg_nome("");

    setNomeModal("");
    setIdModal("");
  };

  const buscar = async () => {
    let filtrado;
    if (arrayFiltrado) {
      if (!busca) {
        filtrado = regioes;
      } else {
        filtrado = arrayFiltrado.filter(function (obj) {
          return ilikeToRegExp("%" + busca + "%").test(obj.reg_nome);
        });
      }
      var inicio = 0 * rowsPerPage + 1;
      var fim = inicio + rowsPerPage - 1;
      if(!filtrado.length){
        filtrado = regioes;
handleInfo('Nenhum registro encontrado.');
      }
      if (fim > filtrado.length) {
        fim = filtrado.length;
      }
      const cidadePag = [];
      for (let i = inicio - 1; i < fim; i++) {
        cidadePag.push(filtrado[i]);
      }

      await setRegioesPag(cidadePag);
      await setTotalPages(filtrado.length);
      await setArrayFiltrado(filtrado);
      await setPage(0);
    } else {
      handleInfo("Nenhum registro encontrado");
    }
  };
  return (
    <>
      <div className="container-fluid">
        <div
          class="modal fade bd-example-modal-md"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div className="card">
                <div className="card-header"></div>
                <span className="span-card-titulo">
                  <img src={img_usuario} className="img-card" alt="logo" />
                  Cadastro de Regiões
                </span>
                <div id="triangulo-para-baixo"></div>
                <div className="card-body">
                  <form id="form_regional" onSubmit={(e) => save(e)}>
                    <div className="form-row mb-3">
                      <div className="col-md-12 mb-3">
                        <label htmlFor="reg_nome">Nome da Região:<strong className='fild-required'>*</strong></label>
                        <input
                          type="text"
                          className="form-control"
                          id="reg_nome"
                          placeholder="Digite o nome da região"
                          onChange={(e) =>
                            setReg_nome(e.target.value.toUpperCase())
                          }
                          value={reg_nome}
                          required
                        />
                      </div>
                    </div>
                    <div className="text-left ">
                      {reg_id ? (
                        <button
                          className="btn btn-sm btn-51comvc "
                          type="submit"
                          id="btnAlterar"
                        >
                          Alterar
                          <img
                            src={img_cadastrar}
                            className="img-btn"
                            alt="logo"
                          />
                        </button>
                      ) : (
                        <button
                          className="btn btn-sm btn-51comvc "
                          type="submit"
                          id="btnCadastrar"
                        >
                          Cadastrar
                          <img
                            src={img_cadastrar}
                            className="img-btn"
                            alt="logo"
                          />
                        </button>
                      )}{" "}
                      &nbsp;&nbsp;{" "}
                      <button
                        className="btn btn-sm btn-51comvc "
                        onClick={(e) => limpar(e)}
                        type="button"
                        id="btn_limpar"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        Fechar
                        <img src={img_excluir} className="img-btn" alt="logo" />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header"></div>
          <span className="span-card-titulo">
            <img src={img_usuario} className="img-card" alt="logo" />
            Lista de Regiões
          </span>
          <div id="triangulo-para-baixo"></div>
          <div className="card-body card-body-lista">
            <form>
              <div className="form-group">
                <div className="form-row">
                  <div className="col-md-5 col-12">
                    <div className="row">
                      <div
                        className="col-md-10  col-10 col-sm-10"
                        style={{ paddingRight: "0px" }}
                      >
                        <label htmlFor="filtro">Filtrar Tabela:</label>
                        <input
                          id="filtro"
                          className="form-control"
                          style={{ display: "inline" }}
                          type="text"
                          autoComplete="new-password"
                          placeholder="Termo..."
                          onChange={(e) =>
                            setBusca(removeAcento(e.target.value))
                          }
                          value={busca}
                        />
                      </div>
                      <div
                        className="col-md-1 col-1  col-sm-1"
                        style={{ paddingLeft: "0px" }}
                      >
                        {" "}
                        <label htmlFor="btn_visualizar">&nbsp;&nbsp;</label>
                        <button
                          style={{ display: "inline" }}
                          type="button"
                          className="btn  bg-51comvc bl-3"
                          id="btn_visualizar"
                          onClick={(e) => buscar(e)}
                        >
                          <img src={pesquisar} className="img-btn" alt="logo" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <label htmlFor="btn_Cadastrar">&nbsp;&nbsp;&nbsp;</label>
                    <br />
                    <button
                      type="button"
                      className="btn  bg-51comvc btn-block "
                      id="btn_Cadastrar"
                      onClick={(e) => limpar(e)}
                      data-toggle="modal"
                      data-target=".bd-example-modal-md"
                    >
                      Cadastrar
                      <img src={img_cadastrar} className="img-btn" alt="logo" />
                    </button>
                  </div>

                  <div className="col-md-5"></div>
                </div>
              </div>
            </form>

            <div className=" table-responsive">
              <table className="table  table-hover table-striped table-light ">
                <thead className="text-white  bg-dark ">
                  <tr>
                    <th style={{ width: "85%" }} scope="col">
                      Nome
                    </th>
                    <th style={{ width: "15%" }} scope="col">
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {regioesPag.length > 0
                    ? regioesPag.map(function (regiao, i) {
                        return (
                          <>
                            <tr key={regiao.reg_id}>
                              <th>{regiao.reg_nome}</th>
                              <td>
                                <button
                                  type="button"
                                  onClick={(e) => editar(regiao)}
                                  className="btn btn-sm "
                                  data-toggle="modal"
                                  data-target=".bd-example-modal-md"
                                >
                                  <img
                                    src={img_alterar}
                                    className="img-btn-acao"
                                    alt="logo"
                                  />
                                </button>
                                <button
                                  type="button"
                                  data-toggle="modal"
                                  data-target="#exampleModalCenter"
                                  onClick={(e) =>
                                    dadosModal(regiao.reg_nome, regiao.reg_id)
                                  }
                                  className="btn btn-sm btn-excluir"
                                >
                                  <img
                                    src={img_excluir2}
                                    className="img-btn-acao"
                                    alt="logo"
                                  />
                                </button>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    : ""}
                </tbody>
              </table>
              <TablePagination
                component="div"
                count={totalPages}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={"Registros por página"}
              />
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="exampleModalCenter"
          tabIndex="1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="card">
                <div className="card-header"></div>
                <span className="span-card-titulo">
                  <img src={img_usuario} className="img-card" alt="logo" />{" "}
                  Excluir região
                </span>
                <div id="triangulo-para-baixo"></div>
                <div className="card-body">
                  Tem certeza que deseja excluir a região{" "}
                  <i>
                    <b className="text-danger">"</b>
                    {nomeModal}
                    <b className="text-danger">"</b>
                  </i>{" "}
                </div>
                <div className="modal-footer-alert">
                  <button
                    className="btn btn-sm btn-51comvc "
                    type="button"
                    data-dismiss="modal"
                  >
                    {Times}
                    &nbsp;Cancelar
                  </button>
                  &nbsp;&nbsp;
                  <button
                    className="btn btn-sm btn-51comvc "
                    onClick={(e) => save(e, "delete", 0)}
                    type="button"
                    data-dismiss="modal"
                  >
                    Excluir
                    <img src={img_excluir} className="img-btn" alt="logo" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Clientes;
