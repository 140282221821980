import React from "react";

const Footer = () => {
  return (
    <>
      <footer class="footer fixar-rodape bg-dark">
        <div class="container">
          <p class="text-light text-center">
            {" "}
            &copy;{new Date().getFullYear()} -{" "}
            <a href="http://villasoftware.com.br" target="_blank">
              Viaconect{" "}
            </a>
            - All Rights Reserved
          </p>{" "}
        </div>
      </footer>
    </>
  );
};
export default Footer;
