import React, { useState, useEffect } from 'react';
import {
  useLoadScript,
  GoogleMap,
  Marker,
  InfoWindow,
} from '@react-google-maps/api';
import axios from 'axios';
import Select from 'react-select';
import { api } from '../../utils/WebApiConfig';
import { handleError, handleSuccess, handleInfo } from '../../utils/Toastify';
import { useHistory } from 'react-router-dom';
import img_usuario from '../../imagens/svg/img_usuario.png';
import img_excluir from '../../imagens/svg/img_excluir.png';
import blue from '../../imagens/googlemaps/blue.png';
import green from '../../imagens/googlemaps/green.png';
import yellow from '../../imagens/googlemaps/yellow.png';
import purple from '../../imagens/googlemaps/purple.png';
import red from '../../imagens/googlemaps/red.png';
const Cancelar = () => {
  document.getElementById('alert-notificar').style.display = 'none';
};
const Alert = ({ mensagem, tipo, status }) => {
  return (
    <div
      id="alert-notificar"
      class={'alert alert-' + tipo + ' alert-dismissible fade show'}
      role="alert"
    >
      <strong>{mensagem}</strong>
      <button
        type="button"
        class="close"
        onClick={(e) => Cancelar(e)}
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
};

function MyComponent() {
  const [cid_uf, setCid_uf] = useState([]);
  /*const [statusCid_uf, setStatusCid_uf] = useState([
    { value: "ALL", label: "TODOS ESTADOS" },
    { value: "TO", label: "TO - Tocantins" },
    { value: "SP", label: "SP - São Paulo" },
    { value: "SE", label: "SE - Sergipe" },
    { value: "SC", label: "SC - Santa Catarina" },
    { value: "RS", label: "RS - Rio Grande do Sul" },
    { value: "RR", label: "RR - Roraima" },
    { value: "RO", label: "RO - Rondônia" },
    { value: "RN", label: "RN - Rio Grande do Norte" },
    { value: "RJ", label: "RJ - Rio de Janeiro" },
    { value: "PR", label: "PR - Paraná" },
    { value: "PI", label: "PI - Piauí" },
    { value: "PE", label: "PE - Pernambuco" },
    { value: "PB", label: "PB - Paraíba" },
    { value: "PA", label: "PA - Pará" },
    { value: "MT", label: "MT - Mato Grosso" },
    { value: "MS", label: "MS - Mato Grosso do Sul" },
    { value: "MG", label: "MG - Minas Gerais" },
    { value: "MA", label: "MA - Maranhão" },
    { value: "GO", label: "GO - Goiás" },
    { value: "ES", label: "ES - Espírito Santo" },
    { value: "DF", label: "DF - Distrito Federal" },
    { value: "CE", label: "CE - Ceará" },
    { value: "BA", label: "BA - Bahia" },
    { value: "AP", label: "AP - Amapá" },
    { value: "AM", label: "AM - Amazonas" },
    { value: "AL", label: "AL - Alagoas" },
    { value: "AC", label: "AC - Acre" },    
  ]);
*/
  const [statusCid_uf, setStatusCid_uf] = useState([
    { value: 'ALL', label: 'TODOS ESTADOS' },
    { value: 'AC', label: 'AC - Acre' },
    { value: 'AL', label: 'AL - Alagoas' },
    { value: 'AM', label: 'AM - Amazonas' },
    { value: 'AP', label: 'AP - Amapá' },
    { value: 'BA', label: 'BA - Bahia' },
    { value: 'CE', label: 'CE - Ceará' },
    { value: 'DF', label: 'DF - Distrito Federal' },
    { value: 'ES', label: 'ES - Espírito Santo' },
    { value: 'GO', label: 'GO - Goiás' },
    { value: 'MA', label: 'MA - Maranhão' },
    { value: 'MG', label: 'MG - Minas Gerais' },
    { value: 'MS', label: 'MS - Mato Grosso do Sul' },
    { value: 'MT', label: 'MT - Mato Grosso' },
    { value: 'PA', label: 'PA - Pará' },
    { value: 'PB', label: 'PB - Paraíba' },
    { value: 'PE', label: 'PE - Pernambuco' },
    { value: 'PI', label: 'PI - Piauí' },
    { value: 'PR', label: 'PR - Paraná' },
    { value: 'RJ', label: 'RJ - Rio de Janeiro' },
    { value: 'RN', label: 'RN - Rio Grande do Norte' },
    { value: 'RO', label: 'RO - Rondônia' },
    { value: 'RR', label: 'RR - Roraima' },
    { value: 'RS', label: 'RS - Rio Grande do Sul' },
    { value: 'SC', label: 'SC - Santa Catarina' },
    { value: 'SE', label: 'SE - Sergipe' },
    { value: 'SP', label: 'SP - São Paulo' },
    { value: 'TO', label: 'TO - Tocantins' },
  ]);
  const [di_cidade, setDi_cidade] = useState([]);
  const [statusDi_cidade, setStatusDi_cidade] = useState([]);
  const [di_regional, setDi_regional] = useState([]);
  const [statusDi_regional, setStatusDi_regional] = useState([]);
  const [geoStatus, setGeoStatus] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const [status_da_consulta, setStatus_da_consulta] = useState([]);
  const [barGeoCode, setBarGeoCode] = useState([]);
  const [distGeoCode, setDistrGeoCode] = useState([]);
  const [pontoGeoCode, setPontoGeoCode] = useState([]);
  const [coordenadas, setCoordenadas] = useState([]);

  const [store, setStore] = useState([]);
  const [mapRef, setMapRef] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [markerMap, setMarkerMap] = useState({});
  const [center, setCenter] = useState({ lat: -22.5254886, lng: -50.8804367 });
  const [zoom, setZoom] = useState(5);
  const [clickedLatLng, setClickedLatLng] = useState(null);
  const [infoOpen, setInfoOpen] = useState(false);
  const [checkboxBar, setCheckboxBar] = useState(0);
  const [checkboxDist, setCheckboxDist] = useState(0);
  const [checkboxPonto, setCheckboxPonto] = useState(0);
  let history = useHistory();

  const [myPlaces, setMyPlaces] = useState([]);
  // Load the Google maps scripts
  const { isLoaded } = useLoadScript({
    // Enter your own Google Maps API key
    googleMapsApiKey: 'AIzaSyBI7LOX1L6tbGwOeFcixa4rjWx2zvaiuqE',
  });

  // The places I want to create markers for.
  // This could be a data-driven prop.
  const StatusCheckboxDist = () => {
    if (checkboxDist === 1) {
      setCheckboxDist(0);
    } else {
      setCheckboxDist(1);
    }
  };
  const StatusCheckboxPonto = () => {
    if (checkboxPonto === 1) {
      setCheckboxPonto(0);
    } else {
      setCheckboxPonto(1);
    }
  };
  const StatusCheckboxBar = () => {
    if (checkboxBar === 1) {
      setCheckboxBar(0);
    } else {
      setCheckboxBar(1);
    }
  };
  const catchResponse = (status) => {
    if (status) {
      if (status === 401) {
        if (document.getElementById('btn_limpar')) {
          document.getElementById('btn_limpar').click();
        }
        handleInfo('Sessão expirada.');
        history.push('/login');
      }
    }
    handleError('Ocorreu um erro inesperado na execução desta operação');
  };

  const handleChangeUF = (cid_uf) => {
    setCid_uf(cid_uf);
    if (cid_uf.value == 'ALL') {
      setDi_cidade({ value: 0, label: 'TODAS CIDADES' });
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
      setDi_cidade([]);
      getCidade(cid_uf.value);
    }
  };
  const handleChange = (di_cidade) => {
    setDi_cidade(di_cidade);
  };
  const handleChangeRegional = (di_regional) => {
    setDi_regional(di_regional);
  };

  useEffect(() => {
    if (document.getElementsByClassName('modal-backdrop')[0]) {
      var no = document.getElementsByClassName('modal-backdrop')[0];
      if (no.parentNode) {
        no.parentNode.removeChild(no);
      }
    }

    if (sessionStorage.getItem('perfil') !== 'G') {
      setDi_regional({
        value: sessionStorage.getItem('regional'),
        label: sessionStorage.getItem('regional_nome'),
      });
    } else {
      getRegional();
    }
  }, []);

  const createBar = (geocode) => {
    if (geocode.length > 0) {
      var stores = [];
      for (var i = 0; i < geocode.length; i++) {
        let latitude = geocode[i].lat;
        let longitude = geocode[i].lng;

        stores.push({
          id: geocode[i].id,
          icon: geocode[i].icon,
          nome: geocode[i].nome,
          pos: {
            lat: parseFloat(latitude),
            lng: parseFloat(longitude),
          },
        });
      }

      setBarGeoCode(stores);
    }
  };

  const createdistribuidora = (geocode) => {
    if (geocode.length > 0) {
      var stores = [];
      for (var i = 0; i < geocode.length; i++) {
        let latitude = geocode[i].lat;
        let longitude = geocode[i].lng;

        stores.push({
          id: geocode[i].id,
          icon: geocode[i].icon,
          di_codigo: 'Codigo PFJ: ' + geocode[i].di_codigo,
          nome: geocode[i].nome,
          pos: {
            lat: parseFloat(latitude),
            lng: parseFloat(longitude),
          },
        });
      }

      setDistrGeoCode(stores);
    }
  };
  const createPonto = (geocode) => {
    if (geocode.length > 0) {
      var stores = [];
      for (var i = 0; i < geocode.length; i++) {
        let latitude = geocode[i].lat;
        let longitude = geocode[i].lng;

        stores.push({
          id: geocode[i].id,
          icon: geocode[i].icon,
          nome: geocode[i].nome,
          tipo: 'Tipo: ' + geocode[i].tipo,
          pos: {
            lat: parseFloat(latitude),
            lng: parseFloat(longitude),
          },
        });
      }

      setPontoGeoCode(stores);
    }
  };

  const pesquisar = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log(document.getElementById('checkboxBar').checked);
    console.log(document.getElementById('checkboxBar').checked);
    console.log(document.getElementById('checkboxBar').checked);
    console.log(document.getElementById('checkboxBar').checked);
    console.log(document.getElementById('checkboxBar').checked);
    if (
      document.getElementById('checkboxBar').checked === false &&
      document.getElementById('checkboxDist').checked === false &&
      document.getElementById('checkboxPonto').checked === false
    ) {
      handleInfo('Selecione ao menos uma categoria');
      return false;
    }
    if (di_cidade && di_regional && cid_uf) {
      setBarGeoCode([]);
      setDistrGeoCode([]);
      setPontoGeoCode([]);
      setGeoStatus(false);
      setCoordenadas([]);
      setMyPlaces([]);
      var cidade = '';
      switch (di_cidade.value) {
        case 'TO':
          cidade = 1;
          break;
        case 'SP':
          cidade = 2;
          break;
        case 'SE':
          cidade = 3;
          break;
        case 'SC':
          cidade = 4;
          break;
        case 'RS':
          cidade = 5;
          break;
        case 'RR':
          cidade = 6;
          break;
        case 'RO':
          cidade = 7;
          break;
        case 'RN':
          cidade = 8;
          break;
        case 'RJ':
          cidade = 9;
          break;
        case 'PR':
          cidade = 10;
          break;
        case 'PI':
          cidade = 11;
          break;
        case 'PE':
          cidade = 12;
          break;
        case 'PB':
          cidade = 13;
          break;
        case 'PA':
          cidade = 14;
          break;
        case 'MT':
          cidade = 15;
          break;
        case 'MS':
          cidade = 16;
          break;
        case 'MG':
          cidade = 17;
          break;
        case 'MA':
          cidade = 18;
          break;
        case 'GO':
          cidade = 19;
          break;
        case 'ES':
          cidade = 20;
          break;
        case 'DF':
          cidade = 21;
          break;
        case 'CE':
          cidade = 22;
          break;
        case 'BA':
          cidade = 23;
          break;
        case 'AP':
          cidade = 24;
          break;
        case 'AM':
          cidade = 25;
          break;
        case 'AL':
          cidade = 26;
          break;
        case 'AC':
          cidade = 27;
          break;
        default:
          cidade = di_cidade.value;
      }
      try {
        if (document.getElementById('checkboxBar').checked === true) {
          await geoCodeBar(di_regional.value, cidade);
        }
        if (document.getElementById('checkboxDist').checked === true) {
          await geoCodeDistribuidora(di_regional.value, cidade);
        }
        if (document.getElementById('checkboxPonto').checked === true) {
          await geoCodePonto(di_regional.value, cidade);
        }
      } catch (error) {
        console.log('falha em getPesquisar');
      }
      /*  Promise.all([getPesquisar(cidade)]).then((values) => {
        setGeoStatus(true);
      });*/
      setGeoStatus(true);
    }
  };

  const alert_store = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (di_cidade && di_regional && cid_uf) {
      var bar = barGeoCode;
      var dist = distGeoCode;
      var ponto = pontoGeoCode;
      var bar_dist = bar.concat(dist);
      var bar_dist_ponto = bar_dist.concat(ponto);

      setMyPlaces(bar_dist_ponto);
      setGeoStatus(false);
      if (bar_dist_ponto.length < 1) {
        var alert =
          ' <div class="alert alert-primary alert-dismissible fade show" role="alert">' +
          ' Nenhuma coordenada encontrada nesta pesquisa...' +
          '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
          ' <span aria-hidden="true">&times;</span>' +
          ' </button>' +
          ' </div>';
        document.getElementById('alert-info').innerHTML = alert;
      } else {
        document.getElementById('alert-info').innerHTML = '';
      }
    }
  };

  const getCidade = (uf) => {
    api
      .get('api/cidades?uf=' + uf, {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
        },
      })
      .then((response) => {
        const arrayCidade = [];
        arrayCidade.push({
          value: uf,
          label: 'TODAS CIDADES',
        });
        for (let i = 0; i < response.data.length; i++) {
          arrayCidade.push({
            value: response.data[i].cid_id,
            label: response.data[i].cid_municipio,
          });
        }

        setStatusDi_cidade(arrayCidade);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };
  const getRegional = () => {
    api
      .get('api/regionais', {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
        },
      })
      .then((response) => {
        const arrayRegional = [];

        for (let i = 0; i < response.data.length; i++) {
          arrayRegional.push({
            value: response.data[i].reg_id,
            label:
              response.data[i].reg_id != 0
                ? response.data[i].reg_nome
                : 'TODAS REGIONAIS',
          });
        }

        setStatusDi_regional(arrayRegional);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };

  const geoCodeBar = async (regional, cidade) => {
    /*  const stores = [];
  this.setState({ stores });

*/
    await api
      .get('api/bares?regional=' + regional + '&select=1&cidade=' + cidade, {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
        },
      })
      .then((response) => {
        //  console.log(response);
        var coordenadas = [];
        var barGeoCode = [];
        setCoordenadas(coordenadas);
        setBarGeoCode(barGeoCode);
        for (var i = 0; i < response.data.length; i++) {
          if (
            !response.data[i].bar_latitude ||
            !response.data[i].bar_longitude
          ) {
            let bar_id = response.data[i].bar_id;
            axios
              .get(
                'https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyBI7LOX1L6tbGwOeFcixa4rjWx2zvaiuqE&sensor=false&address=' +
                  response.data[i].bar_endereco +
                  ',' +
                  response.data[i].bar_numero +
                  ',' +
                  response.data[i].bar_bairro +
                  ',' +
                  response.data[i].cid_municipio +
                  ',' +
                  response.data[i].cid_uf +
                  '&components=country:BR',
              )
              .then((response) => {
                barGeoCode.push({
                  id: bar_id,
                  icon: blue,
                  lat: response.data.results[0].geometry.location.lat,
                  lng: response.data.results[0].geometry.location.lng,
                });
                let data = {
                  bar_id: bar_id,
                  bar_latitude: response.data.results[0].geometry.location.lat,
                  bar_longitude: response.data.results[0].geometry.location.lng,
                  bar_coordendas: true,
                };
                let url = 'api/bares/' + bar_id;
                api({
                  method: 'put',
                  url: url,
                  data: data,
                  headers: {
                    Authorization: 'bearer ' + sessionStorage.getItem('token'),
                  },
                })
                  .then((response) => {
                    handleSuccess('bar ' + bar_id + ' cadastrado');
                  })
                  .catch((error) => {
                    //  catchResponse();

                    catchResponse(error.response.status);
                    console.log(error);
                  });

                setBarGeoCode(barGeoCode);
                if (i == 24) {
                  if (barGeoCode.length < 1) {
                    setBarGeoCode([]);
                  } else {
                    createBar(barGeoCode);
                  }
                }
                //this.create();
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            coordenadas.push({
              id: 'bar' + response.data[i].bar_id,
              icon: blue,
              nome: response.data[i].bar_nomebar,
              lat: response.data[i].bar_latitude,
              lng: response.data[i].bar_longitude,
            });
          }
        }
        if (coordenadas.length < 1) {
          setCoordenadas([]);
        } else {
          createBar(coordenadas);
        }

        //  console.log(JSON.stringify(coordenadas));
      })
      .catch((error) => {
        setCoordenadas([]);
      });
  };

  const geoCodeDistribuidora = async (regional, cidade) => {
    /*  const stores = [];
    this.setState({ stores });
  
  */
    await api
      .get(
        'api/distribuidoras?regional=' +
          regional +
          '&select=2&cidade=' +
          cidade,
        {
          headers: {
            Authorization: 'bearer ' + sessionStorage.getItem('token'),
          },
        },
      )
      .then((response) => {
        //  console.log(response);
        var coordenadas = [];
        var distGeoCode = [];
        setCoordenadas(coordenadas);
        setDistrGeoCode(distGeoCode);
        for (var i = 0; i < response.data.length; i++) {
          if (!response.data[i].di_latitude || !response.data[i].di_longitude) {
            let di_id = response.data[i].di_id;
            let di_codigo = response.data[i].di_codigo;
            let di_nome = response.data[i].di_nome;
            axios
              .get(
                'https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyBI7LOX1L6tbGwOeFcixa4rjWx2zvaiuqE&sensor=false&address=' +
                  response.data[i].di_endereco +
                  ',' +
                  response.data[i].di_numero +
                  ',' +
                  response.data[i].di_bairro +
                  ',' +
                  response.data[i].cid_municipio +
                  ',' +
                  response.data[i].cid_uf +
                  '&components=country:BR',
              )
              .then((response) => {
                distGeoCode.push({
                  id: di_id,
                  di_codigo: di_codigo,
                  nome: di_nome,
                  icon: green,
                  lat: response.data.results[0].geometry.location.lat,
                  lng: response.data.results[0].geometry.location.lng,
                });
                let data = {
                  di_id: di_id,
                  di_latitude: response.data.results[0].geometry.location.lat,
                  di_longitude: response.data.results[0].geometry.location.lng,
                  di_coordendas: true,
                };
                let url = 'api/distribuidoras/' + di_id;
                api({
                  method: 'put',
                  url: url,
                  data: data,
                  headers: {
                    Authorization: 'bearer ' + sessionStorage.getItem('token'),
                  },
                })
                  .then((response) => {
                    handleSuccess('distribuidora ' + di_id + ' cadastrada');
                  })
                  .catch((error) => {
                    //  catchResponse();

                    catchResponse(error.response.status);
                    console.log(error);
                  });

                setDistrGeoCode(distGeoCode);
                if (i == 24) {
                  if (distGeoCode.length < 1) {
                    setDistrGeoCode([]);
                  } else {
                    createdistribuidora(distGeoCode);
                  }
                }
                //this.create();
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            coordenadas.push({
              id: 'di' + response.data[i].di_id,
              icon: green,
              di_codigo: response.data[i].di_codigo,
              nome: response.data[i].di_nome,
              lat: response.data[i].di_latitude,
              lng: response.data[i].di_longitude,
            });
          }
        }
        if (coordenadas.length < 1) {
          setCoordenadas([]);
        } else {
          console.log(coordenadas);
          createdistribuidora(coordenadas);
        }

        //  console.log(JSON.stringify(coordenadas));
      })
      .catch((error) => {
        setCoordenadas([]);
      });
  };

  const geoCodePonto = async (regional, cidade) => {
    /*  const stores = [];
      this.setState({ stores });
    
    */
    await api
      .get(
        'api/pontodemidias?regional=' + regional + '&select=2&cidade=' + cidade,
        {
          headers: {
            Authorization: 'bearer ' + sessionStorage.getItem('token'),
          },
        },
      )
      .then((response) => {
        //  console.log(response);
        var coordenadas = [];
        var pontoGeoCode = [];

        setCoordenadas(coordenadas);
        setPontoGeoCode(pontoGeoCode);
        for (var i = 0; i < response.data.length; i++) {
          if (!response.data[i].pm_latitude || !response.data[i].pm_longitude) {
            let pm_id = response.data[i].pm_id;
            axios
              .get(
                'https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyBI7LOX1L6tbGwOeFcixa4rjWx2zvaiuqE&sensor=false&address=' +
                  response.data[i].pm_endereco +
                  ',' +
                  response.data[i].pm_numero +
                  ' ' +
                  response.data[i].pm_bairro +
                  ',' +
                  response.data[i].cid_municipio +
                  ',' +
                  response.data[i].cid_uf +
                  '&components=country:BR',
              )
              .then((response) => {
                pontoGeoCode.push({
                  id: pm_id,
                  icon: yellow,
                  lat: response.data.results[0].geometry.location.lat,
                  lng: response.data.results[0].geometry.location.lng,
                });
                let data = {
                  pm_id: pm_id,
                  pm_latitude: response.data.results[0].geometry.location.lat,
                  pm_longitude: response.data.results[0].geometry.location.lng,
                  pm_coordendas: true,
                };
                let url = 'api/pontodemidias/' + pm_id;
                api({
                  method: 'put',
                  url: url,
                  data: data,
                  headers: {
                    Authorization: 'bearer ' + sessionStorage.getItem('token'),
                  },
                })
                  .then((response) => {
                    handleSuccess('Ponto de midia ' + pm_id + ' alterado ');
                  })
                  .catch((error) => {
                    //  catchResponse();

                    catchResponse(error.response.status);
                    console.log(error);
                  });

                setPontoGeoCode(pontoGeoCode);
                if (i == 24) {
                  if (pontoGeoCode.length < 1) {
                    setPontoGeoCode([]);
                  } else {
                    createPonto(pontoGeoCode);
                  }
                }
                //this.create();
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            coordenadas.push({
              id: 'midia' + response.data[i].pm_id,
              icon: yellow,
              nome: response.data[i].pm_descricao,
              tipo: response.data[i].pmt_nome,
              lat: response.data[i].pm_latitude,
              lng: response.data[i].pm_longitude,
            });
          }
        }
        if (coordenadas.length < 1) {
          setCoordenadas([]);
        } else {
          createPonto(coordenadas);
        }

        //  setGeoStatus(true);

        //  console.log(JSON.stringify(coordenadas));
      })
      .catch((error) => {
        setCoordenadas([]);
      });
  };
  // Iterate myPlaces to size, center, and zoom map to contain all markers
  const fitBounds = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    myPlaces.map((place) => {
      bounds.extend(place.pos);
      return place.id;
    });
    map.fitBounds(bounds);
  };

  const loadHandler = (map) => {
    // Store a reference to the google map instance in state
    setMapRef(map);
    // Fit map bounds to contain all markers
    fitBounds(map);
  };

  // We have to create a mapping of our places to actual Marker objects
  const markerLoadHandler = (marker, place) => {
    return setMarkerMap((prevState) => {
      return { ...prevState, [place.id]: marker };
    });
  };

  const markerClickHandler = (event, place) => {
    // Remember which place was clicked
    // console.log(place);
    setSelectedPlace(place);

    // Required so clicking a 2nd marker works as expected
    if (infoOpen) {
      setInfoOpen(false);
    }

    setInfoOpen(true);

    // If you want to zoom in a little on marker click
    if (zoom < 13) {
      setZoom(13);
    }

    // if you want to center the selected Marker
    //setCenter(place.pos)
  };

  const limpar = () => {
    setCid_uf([]);
    setDi_cidade([]);
    setDi_regional([]);
    setBarGeoCode([]);
    setDistrGeoCode([]);
    setPontoGeoCode([]);
    setGeoStatus(false);
    setCoordenadas([]);
    setMyPlaces([]);
    setIsDisabled(false);
    document.getElementById('alert-info').innerHTML = '';
  };

  const renderMap = () => {
    return (
      <>
        {myPlaces.length > 0 ? (
          <>
            <div class="card">
              <div class="card-body row">
                <span
                  style={{
                    fontWeight: 'bold',
                    padding: '8px',
                    margin: '3px',
                    borderRadius: '8px',
                    background: 'blue',
                    color: 'white',
                  }}
                >
                  Bar
                </span>
                <span
                  style={{
                    fontWeight: 'bold',
                    padding: '8px',
                    margin: '3px',
                    borderRadius: '8px',
                    background: 'green',
                    color: 'white',
                  }}
                >
                  Distribuidora{' '}
                </span>
                <span
                  style={{
                    fontWeight: 'bold',
                    padding: '8px',
                    margin: '3px',
                    borderRadius: '8px',
                    background: '#fdf569',
                    color: 'white',
                  }}
                >
                  Ponto de Mídia
                </span>
              </div>
            </div>

            <GoogleMap
              // Do stuff on map initial laod
              onLoad={loadHandler}
              // Save the current center position in state
              // onCenterChanged={() => setCenter(mapRef.getCenter().toJSON())}
              // Save the user's map click position
              onClick={(e) => setClickedLatLng(e.latLng.toJSON())}
              center={center}
              zoom={zoom}
              mapContainerStyle={{
                height: '70vh',
                width: '100%',
              }}
            >
              {myPlaces.map((place, i) => (
                <Marker
                  key={place.id}
                  position={place.pos}
                  onLoad={(marker) => markerLoadHandler(marker, place)}
                  onClick={(event) => markerClickHandler(event, place)}
                  // Not required, but if you want a custom icon:
                  icon={{
                    url: place.icon,
                    fillColor: '#0000ff',
                    fillOpacity: 1.0,
                    strokeWeight: 0,
                    scale: 1.25,
                  }}
                />
              ))}

              {infoOpen && selectedPlace && (
                <InfoWindow
                  anchor={markerMap[selectedPlace.id]}
                  onCloseClick={() => setInfoOpen(false)}
                >
                  <div>
                    <div>
                      <strong>{selectedPlace.nome}</strong>
                    </div>
                    <br />
                    <div>{selectedPlace.di_codigo}</div>
                    <div>{selectedPlace.tipo}</div>
                  </div>
                </InfoWindow>
              )}
            </GoogleMap>
          </>
        ) : (
          ''
        )}

        {/* Our center position always in state */}
        <h3>
          {myPlaces.length > 0
            ? 'Center ' + center.lat + ' , ' + center.lng
            : ''}
        </h3>

        {/* Position of the user's map click */}
        {/*clickedLatLng && (
          <h3>
            You clicked: {clickedLatLng.lat}, {clickedLatLng.lng}
          </h3>
        )*/}

        {/* Position of the user's map click */}
        {selectedPlace && <h3>Selected Marker: {selectedPlace.id}</h3>}
      </>
    );
  };
  // console.log('antes do return '+JSON.stringify(barGeoCode));
  return (
    <>
      <div class="container">
        <div class="card shadow-lg  mb-5 bg-white rounded">
          {' '}
          <div className="card-header"></div>
          <span className="span-card-titulo">
            <img src={img_usuario} className="img-card" alt="logo" />
            Google Maps
          </span>
          <div id="triangulo-para-baixo"></div>
          <div class="card-body">
            <form onSubmit={(e) => (geoStatus ? alert_store(e) : pesquisar(e))}>
              <div className="form-row">
                <div className="col-md-4 mb-3">
                  <label htmlFor="cid_uf">
                    Estado / UF:<strong className="fild-required">*</strong>
                  </label>
                  <Select
                    id="cid_uf"
                    value={cid_uf}
                    onChange={handleChangeUF}
                    options={statusCid_uf}
                  />
                  <input
                    style={{
                      top: '43px',
                      position: 'absolute',
                      zIndex: '-1',
                    }}
                    type="password"
                    id="cid_uf_html5_validate"
                    autoComplete="new-password"
                    value={cid_uf}
                    required
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor="di_cidade">
                    Cidade:<strong className="fild-required">*</strong>
                  </label>
                  <Select
                    id="di_cidade"
                    value={di_cidade}
                    onChange={handleChange}
                    isDisabled={isDisabled}
                    options={statusDi_cidade}
                    placeholder={<div>Selecione o estado antes</div>}
                  />
                  <input
                    style={{
                      top: '43px',
                      position: 'absolute',
                      zIndex: '-1',
                    }}
                    type="password"
                    id="di_cidade_html5_validate"
                    autoComplete="new-password"
                    value={di_cidade}
                    required
                  />
                </div>

                <div className="col-md-4 mb-3">
                  <label htmlFor="di_regional">
                    Regional:<strong className="fild-required">*</strong>
                  </label>
                  <Select
                    id="di_regional"
                    value={di_regional}
                    onChange={handleChangeRegional}
                    options={statusDi_regional}
                  />
                  <input
                    style={{
                      top: '43px',
                      position: 'absolute',
                      zIndex: '-1',
                      width: '5px',
                    }}
                    type="password"
                    id="di_regional_html5_validate"
                    autoComplete="new-password"
                    value={di_regional}
                    required
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="mb-3 mt-3 col-md-12 col-12">
                  <label>
                    Selecione de um a três categorias abaixo, para exibi-los no
                    mapa
                  </label>
                </div>
                <div className="col-md-4 col-6 mb-3">
                  <label htmlFor="checkboxBar">
                    <b>Bar</b>
                  </label>
                  <div class="material-switch   pull-right">
                    <input
                      id="checkboxBar"
                      name="checkboxBar"
                      type="checkbox"
                    />
                    <label
                      htmlFor="checkboxBar"
                      style={{ backgroundColor: '#c9ba88' }}
                    ></label>
                  </div>
                </div>
                <div className="col-md-4 col-6 mb-3">
                  <label htmlFor="checkboxDist">
                    <b>Distribuidora</b>
                  </label>
                  <div class="material-switch   pull-right">
                    <input
                      id="checkboxDist"
                      name="checkboxDist"
                      type="checkbox"
                    />
                    <label
                      htmlFor="checkboxDist"
                      style={{ backgroundColor: '#c9ba88' }}
                    ></label>
                  </div>
                </div>
                <div className="col-md-4 col-6 mb-3">
                  <label htmlFor="checkboxPonto">
                    <b>Ponto de Mídia</b>
                  </label>
                  <div class="material-switch   pull-right">
                    <input
                      id="checkboxPonto"
                      name="checkboxPonto"
                      type="checkbox"
                    />
                    <label
                      htmlFor="checkboxPonto"
                      style={{ backgroundColor: '#c9ba88' }}
                    ></label>
                  </div>
                </div>{' '}
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="text-left ">
                    {geoStatus ? (
                      <button
                        className="btn btn-sm btn-51comvc "
                        type="submit"
                        id="btnCadastrar"
                      >
                        Plotar
                      </button>
                    ) : (
                      <button
                        className="btn btn-sm btn-51comvc "
                        type="submit"
                        id="btnCadastrar"
                      >
                        Pesquisar
                      </button>
                    )}
                    &nbsp;&nbsp;
                    <button
                      className="btn btn-sm btn-51comvc "
                      type="button"
                      id="btn_limpar"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={(e) => limpar(e)}
                    >
                      Limpar
                      <img src={img_excluir} className="img-btn" alt="logo" />
                    </button>
                  </div>
                </div>

                <div
                  className="col-md-12"
                  style={{
                    display: 'list-item',
                    paddingTop: '45px',
                    paddingBottom: '500px',
                    marginBottom: '50px',
                  }}
                >
                  {' '}
                  {/*status_da_consulta[0].cor ? (
                    <>
                      <Alert
                        mensagem={status_da_consulta[0].mensagem}
                        tipo={status_da_consulta[0].cor}
                      />
                    </>
                  ) : (
                    ""
                  )*/}
                  {isLoaded ? renderMap() : ''}
                  <div id="alert-info"></div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyComponent;
