import React, { useState, useEffect } from "react";
import axios from "axios";
import { api } from "../utils/WebApiConfig";

import { handleError, handleSuccess, handleInfo } from "../utils/Toastify";
import { useHistory } from "react-router-dom";
import { Times } from "../utils/Fontawesome";
import { TablePagination } from "@material-ui/core";
import { ilikeToRegExp, removeAcento, mask_Numeros } from "../utils/Mascara";
import img_usuario from "../imagens/svg/img_usuario.png";
import img_cadastrar from "../imagens/svg/img_cadastrar.png";
import img_excluir from "../imagens/svg/img_excluir.png";
import img_alterar from "../imagens/svg/img_alterar.png";
import img_excluir2 from "../imagens/svg/img_excluir2.png";
import pesquisar from "../imagens/svg/img_lupa.png";
const Clientes = () => {
  let history = useHistory();

  const [cid_id, setCid_id] = useState("");
  const [cid_id_old, setCid_id_old] = useState("");
  const [cid_status_acao, setCid_status_acao] = useState("");
  const [cid_municipio, setCid_municipio] = useState("");
  const [cid_uf, setCid_uf] = useState("");
  const [statusCid_uf, setStatusCid_uf] = useState([
    { value: "TO", label: "TO - Tocantins" },
    { value: "SP", label: "SP - São Paulo" },
    { value: "SE", label: "SE - Sergipe" },
    { value: "SC", label: "SC - Santa Catarina" },
    { value: "RS", label: "RS - Rio Grande do Sul" },
    { value: "RR", label: "RR - Roraima" },
    { value: "RO", label: "RO - Rondônia" },
    { value: "RN", label: "RN - Rio Grande do Norte" },
    { value: "RJ", label: "RJ - Rio de Janeiro" },
    { value: "PR", label: "PR - Paraná" },
    { value: "PI", label: "PI - Piauí" },
    { value: "PE", label: "PE - Pernambuco" },
    { value: "PB", label: "PB - Paraíba" },
    { value: "PA", label: "PA - Pará" },
    { value: "MT", label: "MT - Mato Grosso" },
    { value: "MS", label: "MS - Mato Grosso do Sul" },
    { value: "MG", label: "MG - Minas Gerais" },
    { value: "MA", label: "MA - Maranhão" },
    { value: "GO", label: "GO - Goiás" },
    { value: "ES", label: "ES - Espírito Santo" },
    { value: "DF", label: "DF - Distrito Federal" },
    { value: "CE", label: "CE - Ceará" },
    { value: "BA", label: "BA - Bahia" },
    { value: "AP", label: "AP - Amapá" },
    { value: "AM", label: "AM - Amazonas" },
    { value: "AL", label: "AL - Alagoas" },
    { value: "AC", label: "AC - Acre" },
  ]);
  const [cidades, setCidades] = useState([]);
  const [cidadePag, setCidadePag] = useState([]);
  const [nomeModal, setNomeModal] = useState("");
  const [idModal, setIdModal] = useState("");

  // variaveis da paginaçãp
  const [arrayFiltrado, setArrayFiltrado] = useState("");
  const [busca, setBusca] = useState("");

  const [onloadStatus, setOnloadStatus] = useState(0);
  const [lastID, setLastID] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    var inicio = newPage * rowsPerPage + 1;
    var fim = inicio + (rowsPerPage - 1);
    /*  console.log(
      "rowsPerPage:" +
        rowsPerPage +
        " newPage " +
        newPage +
        " " +
        inicio +
        " ---- " +
        fim
    );*/
    if (fim > arrayFiltrado.length) {
      fim = arrayFiltrado.length;
    }
    const cidadePag = [];
    for (let i = inicio - 1; i < fim; i++) {
      cidadePag.push(arrayFiltrado[i]);
    }
    setCidadePag(cidadePag);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

    var inicio = 0 * event.target.value + 1;
    var fim = inicio + (event.target.value - 1);
    if (fim > arrayFiltrado.length) {
      fim = arrayFiltrado.length;
    }
    const cidadePag = [];
    for (let i = inicio - 1; i < fim; i++) {
      cidadePag.push(arrayFiltrado[i]);
    }
    setCidadePag(cidadePag);
  };

  useEffect(() => {
    if(document.getElementsByClassName('modal-backdrop')[0]){
      var no = document.getElementsByClassName("modal-backdrop")[0];
      if (no.parentNode) {
        no.parentNode.removeChild(no);
      }
    }
    getCidade();
    document.getElementById("cid_municipio").readOnly = true;
  }, []);

  const catchResponse = (status) => {
    if (status) {
      if (status === 401) {
        if(document.getElementById("btn_limpar")){
          document.getElementById("btn_limpar").click();
        } 
        handleInfo("Sessão expirada.");
        history.push("/login");
      }
    }
    handleError("Ocorreu um erro inesperado na execução desta operação");
  };

  const getCidade = () => {
    api
      .get("api/cidades", {
        headers: {
          Authorization: "bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((response) => {
        setCidades(response.data);
        setArrayFiltrado(response.data);
        setTotalPages(response.data.length);
        var inicio = 0;
        var fim = 10;
        const cidadePag = [];
        if (fim > response.data.length) {
          fim = response.data.length;
        }
        for (let i = inicio; i < fim; i++) {
          cidadePag.push(response.data[i]);
        }
        setCidadePag(cidadePag);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };

  const handleChangeUF = (cid_uf) => {
    setCid_uf(cid_uf);
  };

  const editar = (cidade) => {
    document.getElementById("cid_municipio").focus();
    setCid_municipio(cidade.cid_municipio);
    setCid_status_acao("alterar");
    setCid_uf(cidade.cid_uf);
    setCid_id(cidade.cid_id);
    setCid_id_old(cidade.cid_id);
  };

  const dadosModal = (nome, id) => {
    setNomeModal(nome);
    setIdModal(id);
  };
  const save = (e, metodo) => {
    e.preventDefault();
    e.stopPropagation();
    let data, method;
    if (metodo) {
      method = "delete";
    } else {
      if (!cid_id) {
        handleInfo("Preencha o campo de IBGE da cidade.");
        return false;
      }
      if (!cid_municipio) {
        handleInfo("Insira um código IBGE válido.");
        return false;
      }

      if (!cid_status_acao) {
        method = "post";
      } else {
        method = "put";
      }
    }

    if (method === "put") {
      data = {
        cid_id: cid_id,
        cid_municipio: cid_municipio,
        cid_uf: cid_uf,
        cid_id_old: cid_id_old,
      };
      var url = "api/cidades/" + cid_id_old;
    } else if (method === "post") {
      let existeCidade = cidades.findIndex((val) => val.cid_id == cid_id);
      if (existeCidade !== -1) {
        handleInfo("Cidade já existente");
        //  console.log(cidades);
        //  alert(existeCidade);
        return false;
      }

      data = {
        cid_id: cid_id,
        cid_municipio: cid_municipio,
        cid_uf: cid_uf,
      };
      var url = "api/cidades";
    } else if (method === "delete") {
      var url = "api/cidades/" + idModal;
      data = {
        id: idModal,
      };
      setNomeModal("");
      setIdModal("");
    }
    /*console.log(data);
    return false;*/
    api({
      method: method,
      url: url,
      data: data,
      headers: {
        Authorization: "bearer " + sessionStorage.getItem("token"),
      },
    })
      .then((response) => {
        handleSuccess(response.headers.statusmessage);   
        if(document.getElementById("btn_limpar")){
          document.getElementById("btn_limpar").click();
        } 
          getCidade();
       
      })
      .catch((error) => {
        //  catchResponse();
        catchResponse(error.response.status);

        console.log(error);
      });
  };

  const limpar = () => {
    setCid_id("");
    setCid_municipio("");
    setCid_uf("");
    setCid_status_acao("");
    setNomeModal("");
    setIdModal("");
  };

  const ibge = (value) => {
    if (value) {
      if (value.length > 5) {
        axios
          .get(
            "https://servicodados.ibge.gov.br/api/v1/localidades/municipios/" +
              value
          )
          .then((response) => {
            if (response.data.id) {
              // setStatusBar_cidade(arrayCidade);
              setCid_municipio(removeAcento(response.data.nome));
              setCid_uf(response.data.microrregiao.mesorregiao.UF.sigla);
            } else {
              setCid_municipio("");
            }
          })
          .catch((error) => {
            if (error.response) {
              catchResponse(error.response.status);
            }
          });
      }
    }
    return value;
  };
  const buscar = async () => {
    var filtrado;
    if (arrayFiltrado) {
      if (!busca) {
        filtrado = cidades;
      } else {
        filtrado = arrayFiltrado.filter(function (obj) {
          return ilikeToRegExp("%" + busca + "%").test(obj.cid_municipio);
        });
      }
      var inicio = 0 * rowsPerPage + 1;
      var fim = inicio + rowsPerPage - 1;

      if(!filtrado.length){
        filtrado = cidades;
handleInfo('Nenhum registro encontrado.');
      }
      if (fim > filtrado.length) {
        fim = filtrado.length;
      }
      const cidadePag = [];
      for (let i = inicio - 1; i < fim; i++) {
        cidadePag.push(filtrado[i]);
      }

      await setCidadePag(cidadePag);
      await setTotalPages(filtrado.length);
      await setArrayFiltrado(filtrado);
      await setPage(0);
    } else {
      handleInfo("Nenhum registro encontrado");
    }
  };
  return (
    <>
      <div className="container-fluid">
        <div
          class="modal fade bd-example-modal-lg"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div className="card">
                <div className="card-header"></div>
                <span className="span-card-titulo">
                  <img src={img_usuario} className="img-card" alt="logo" />{" "}
                  Cadastro de Cidades
                </span>
                <div id="triangulo-para-baixo"></div>
                <div className="card-body">
                  <form id="form_cliente" onSubmit={(e) => save(e)}>
                    <div className="form-row mb-3">
                      <div className="col-md-5 mb-3">
                        <div className="row">
                          <div
                            className="col-md-10  col-10 col-sm-10"
                            style={{ paddingRight: "0px" }}
                          >
                            <label htmlFor="filtro">IBGE da cidade:<strong className='fild-required'>*</strong></label>
                            <input
                              id="filtro"
                              className="form-control"
                              style={{ display: "inline" }}
                              type="text"
                              autoComplete="new-password"
                              maxLength="8"
                              placeholder="Código IBGE da cidade"
                              onChange={(e) =>
                                setCid_id(ibge(mask_Numeros(e.target.value)))
                              }
                              value={cid_id}
                            />
                          </div>
                          <div
                            className="col-md-1 col-1  col-sm-1"
                            style={{ paddingLeft: "0px" }}
                          >
                            {" "}
                            <label htmlFor="btn_ibge">&nbsp;&nbsp;</label>
                            <button
                              style={{ display: "inline" }}
                              type="button"
                              className="btn  bg-51comvc bl-3"
                              id="btn_ibge"
                              onClick={(e) => ibge(cid_id)}
                            >
                              <img
                                src={pesquisar}
                                className="img-btn"
                                alt="logo"
                              />
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-10 mb-3">
                        <label htmlFor="cid_municipio">Nome da Cidade:</label>
                        <input
                          type="text"
                          className="form-control"
                          id="cid_municipio"
                          placeholder="Digite um código válido em IBGE"
                          onChange={(e) =>
                            setCid_municipio(
                              removeAcento(e.target.value.toUpperCase())
                            )
                          }
                          value={cid_municipio}
                          required
                        />
                      </div>
                      <div className="paddingrl-0 col-md-1 col-2 mb-3 ">
                        <label htmlFor="cid_uf">UF:</label>
                        <input
                          type="text"
                          className="form-control"
                          id="cid_uf"
                          readOnly
                          placeholder="UF"
                          onChange={(e) =>
                            setCid_uf(e.target.value.toUpperCase())
                          }
                          value={cid_uf}
                          required
                        />
                      </div>
                    </div>
                    <div className="text-left ">
                      {cid_status_acao ? (
                        <button
                          className="btn btn-sm btn-51comvc "
                          type="submit"
                          id="btnAlterar"
                        >
                          Alterar
                          <img
                            src={img_cadastrar}
                            className="img-btn"
                            alt="logo"
                          />
                        </button>
                      ) : (
                        <button
                          className="btn btn-sm btn-51comvc "
                          type="submit"
                          id="btnCadastrar"
                        >
                          Cadastrar
                          <img
                            src={img_cadastrar}
                            className="img-btn"
                            alt="logo"
                          />
                        </button>
                      )}{" "}
                      &nbsp;&nbsp;
                      <button
                        className="btn btn-sm btn-51comvc "
                        onClick={(e) => limpar(e)}
                        type="button"
                        id="btn_limpar"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        Fechar
                        <img src={img_excluir} className="img-btn" alt="logo" />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-header"></div>
          <span className="span-card-titulo">
            <img src={img_usuario} className="img-card" alt="logo" />
            Lista de Cidades
          </span>
          <div id="triangulo-para-baixo"></div>
          <div className="card-body card-body-lista">
            <form>
              <div className="form-group">
                <div className="form-row">
                  <div className="col-md-5 col-12">
                    <div className="row">
                      <div
                        className="col-md-10  col-10 col-sm-10"
                        style={{ paddingRight: "0px" }}
                      >
                        <label htmlFor="filtro">Filtrar Tabela:</label>
                        <input
                          id="filtro"
                          className="form-control"
                          style={{ display: "inline" }}
                          type="text"
                          autoComplete="new-password"
                          placeholder="Termo..."
                          onChange={(e) =>
                            setBusca(removeAcento(e.target.value))
                          }
                          value={busca}
                        />
                      </div>
                      <div
                        className="col-md-1 col-1  col-sm-1"
                        style={{ paddingLeft: "0px" }}
                      >
                        {" "}
                        <label htmlFor="btn_visualizar">&nbsp;&nbsp;</label>
                        <button
                          style={{ display: "inline" }}
                          type="button"
                          className="btn  bg-51comvc bl-3"
                          id="btn_visualizar"
                          onClick={(e) => buscar(e)}
                        >
                          <img src={pesquisar} className="img-btn" alt="logo" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <label htmlFor="btn_Cadastrar">&nbsp;&nbsp;&nbsp;</label>
                    <br />
                    <button
                      type="button"
                      className="btn  bg-51comvc btn-block "
                      id="btn_Cadastrar"
                      onClick={(e) => limpar(e)}
                      data-toggle="modal"
                      data-target=".bd-example-modal-lg"
                    >
                      Cadastrar
                      <img src={img_cadastrar} className="img-btn" alt="logo" />
                    </button>
                  </div>
                  <div className="col-md-5"></div>
                </div>
              </div>
            </form>

            <div className=" table-responsive">
              <table className="table  table-hover table-striped table-light ">
                <thead className="text-white  bg-dark ">
                  <tr>
                    <th style={{ width: "85%" }} scope="col">
                      Nome
                    </th>
                    <th style={{ width: "15%" }} scope="col">
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {cidadePag.length > 0
                    ? cidadePag.map(function (cidade, i) {
                        return (
                          <>
                            <tr key={cidade.cid_id}>
                              <th>
                                {cidade.cid_municipio + " / " + cidade.cid_uf}
                              </th>
                              <td>
                                <button
                                  type="button"
                                  onClick={(e) => editar(cidade)}
                                  className="btn btn-sm "
                                  data-toggle="modal"
                                  data-target=".bd-example-modal-lg"
                                >
                                  <img
                                    src={img_alterar}
                                    className="img-btn-acao"
                                    alt="logo"
                                  />
                                </button>

                                <button
                                  type="button"
                                  data-toggle="modal"
                                  data-target="#exampleModalCenter"
                                  onClick={(e) =>
                                    dadosModal(
                                      cidade.cid_municipio,
                                      cidade.cid_id
                                    )
                                  }
                                  className="btn btn-sm btn-excluir"
                                >
                                  <img
                                    src={img_excluir2}
                                    className="img-btn-acao"
                                    alt="logo"
                                  />
                                </button>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    : ""}
                </tbody>
              </table>
              <TablePagination
                component="div"
                count={totalPages}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={"Registros por página"}
              />
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="exampleModalCenter"
          tabIndex="1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="card">
                <div className="card-header"></div>
                <span className="span-card-titulo">
                  <img src={img_usuario} className="img-card" alt="logo" />{" "}
                  Excluir Cidade
                </span>
                <div id="triangulo-para-baixo"></div>
                <div className="card-body">
                  Tem certeza que deseja excluir a cidade{" "}
                  <i>
                    <b className="text-danger">"</b>
                    {nomeModal}
                    <b className="text-danger">"</b>
                  </i>{" "}
                </div>
                <div className="modal-footer-alert">
                  <button
                    className="btn btn-sm btn-51comvc "
                    type="button"
                    data-dismiss="modal"
                  >
                    {Times}
                    &nbsp;Cancelar
                  </button>
                  &nbsp;&nbsp;
                  <button
                    className="btn btn-sm btn-51comvc "
                    onClick={(e) => save(e, "delete", 0)}
                    type="button"
                    data-dismiss="modal"
                  >
                    Excluir
                    <img src={img_excluir} className="img-btn" alt="logo" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Clientes;
