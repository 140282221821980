import React, { Component } from "react";

import { dominio, api } from "../utils/WebApiConfig";
import { handleError, handleSuccess, handleInfo } from "../utils/Toastify";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";

import { mask_data } from "../utils/Mascara";
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      calendarWeekends: true,
      calendarEvents: [],
      calendarEvents_plugin: [],
      onload: true,
      onload_graficos: true,
      mes: "",
      mes_pesquisado: "",
      modal: [],
      data_modal: "",
      date_atual: [],
      array_nome: [],
    };
  }
  convert_Date_text(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear()].join("/");
  }

  toggleWeekends = () => {
    this.setState({
      // update a property
      calendarWeekends: !this.state.calendarWeekends,
    });
  };

  gotoPast = () => {
    let calendarApi = this.calendarComponentRef.current.getApi();
    calendarApi.gotoDate("2000-01-01"); // call a method on the Calendar object
  };
  handleEventMouseLeave = ({ event, el }) => {
    if (document.getElementsByTagName("tooltip")[0]) {
      // Checking for any "tooltip" element
      document.getElementsByTagName("tooltip")[0].remove(); // Removing old tooltip
    }
  };

  handleEventMouseEnter = ({ event, el }) => {
    let start = String(event.start);
    start = start.split(" ");
    let end = String(event.end);
    end = end.split(" ");

    var tip,
      text,
      base = document.createElement("tooltip"); //Defining all objects
    //console.log(el);

    text = start[4] + " - " + end[4] + " " + event.title;
    tip = document.createTextNode(text);
    if (text != null) {
      // Checking if tooltip is empty or not.
      base.innerHTML = "";
      base.appendChild(tip);
      if (document.getElementsByTagName("tooltip")[0]) {
        // Checking for any "tooltip" element
        document.getElementsByTagName("tooltip")[0].remove(); // Removing old tooltip
      }
      base.style.top = event.pageY + 100 + "px";
      base.style.left = event.pageX + 20 + "px";

      el.appendChild(base);
    }
  };
  handleEventPositioned(event, el) {
    el.popover({
      title: event.title,
      content: event.description,
      trigger: "hover",
      placement: "top",
      container: "body",
    });
  }

  checkDate = (value) => {
    return value.start === document.getElementById("data_atual_clicada").value;
  };
  // quando clica no fundo branco do quadrado do dia do mes
  // parametro arg esta trazendo os dados do evento referente ao campo clicado
  handleDateClick = (arg) => {
    // instancia calendarEvents que contem o array de todos os registros do mes
    const calendarEvents = this.state.calendarEvents;

    // arg.dateStr é a data do dia clicado que é colocada em um input hidden
    document.getElementById("data_atual_clicada").value = arg.dateStr;

    // aqui é a data que vai aparecer no modal
    let data_modal = mask_data(arg.dateStr);
    this.setState({ data_modal });

    // depois com filter eliminamos dos registro do mes todas datas que nao correspondem a data clicada que por fim sera armazenada em data_clicada
    var data_clicada = calendarEvents.filter(this.checkDate);

    // apos o uso do input limpamos ele
    document.getElementById("data_atual_clicada").value = "";

    // função que constroe e popula a tabela do modal

    this.Criar_modal(data_clicada);
  };

  // função para construir e popular a tabela dentro do modal
  Criar_modal = (data_clicada) => {
    /******* utilizando o array de dados referente ao dia clicado , descobrimos quantas pessoas e o nome delas que existem nesse dia
         e armazenamos na variavel array_nome que por fim se tornara um this.state*****************************/
    var array_nome = [];
    var modal = [];
    this.setState({ modal });
    this.setState({ array_nome });
    if (data_clicada.length < 1) {
      return false;
    }

    array_nome = [];
    for (let i = 0; i < data_clicada.length; i++) {
      array_nome.push({ cliente: data_clicada[i].title });
    }

    array_nome = array_nome.filter(function (a) {
      return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
    }, Object.create(null));

    /***********************************************************************************************************/
    /* A seguir Ordenamos o array com os dados dos eventos do dia clicado em ordem de nome do cliente *************/
    modal = [];
    for (let i = 0; i < array_nome.length; i++) {
      modal.push(filtrarArray(data_clicada, array_nome[i].cliente));
    }

    function filtrarArray(array, title) {
      return array.filter(function (val) {
        return val.title === title;
      });
    }
    /****************************************************************************************************/
    /* salvando as variaveis em seus respectivos state modal para os dados do dia e array_nome para o nome dos clientes desse dia */
    this.setState({ modal });
    this.setState({ array_nome });

    if (array_nome.length > 0) {
      // comando para abrir o modal pelo id
      document.getElementById("btnmodal_tabela_diaria").click();
    } else {
      handleInfo("Nenhum evento neste dia");
    }
  };

  checkEventClick(value) {
    return value.dia == document.getElementById("data_atual_clicada").value;
  }

  // quando clica no evento que tem no dia
  handleEventClick = ({ event, el }) => {
    const calendarEvents = this.state.calendarEvents;
    document.getElementById("data_atual_clicada").value =
      event.extendedProps.dia;

    // aqui é a data que vai aparecer no modal
    let data_modal = this.convert_Date_text(event.start);
    this.setState({ data_modal });

    const data_EventClick = calendarEvents.filter(this.checkEventClick);
    // alert(JSON.stringify(data_EventClick));
    document.getElementById("data_atual_clicada").value = "";
    this.Criar_modal(data_EventClick);
  };

  GetCalendarioNomeUnicos = () => {
    var mes = "";
    if (this.state.onload == false) {
      mes = document.getElementById("data_fullcalendar").value;

      if (mes == this.state.mes_pesquisado) {
        return false;
      }
    } else {
      mes = parseInt(new Date().getMonth() + 1);
      document.getElementById("data_fullcalendar").value = mes;
      this.setState({ onload: false });
    }
    const mes_pesquisado = mes;
    this.setState({ mes_pesquisado });

    var url = "cv/agenda/1/" + mes + "/" + new Date().getFullYear();

    api
      .get(url)
      .then((res) => {
        if (res.data != "") {
          // console.log("res do axios " + JSON.stringify(res.data));
          const calendarEvents_plugin = [];

          for (var i = 0; i < res.data.length; i++) {
            calendarEvents_plugin.push({
              title: res.data[i].cli_nome,
              start: res.data[i].cv_data,
              dia: res.data[i].dia,
            });
          }

          this.setState({ calendarEvents_plugin });
          this.GetCalendario(mes_pesquisado);
        }
      })
      .catch((error) => {
        const calendarEvents_plugin = [];
        this.setState({ calendarEvents_plugin });
        return false;
      });
  };

  GetCalendario = (mes) => {
    if (!mes) {
      mes = parseInt(new Date().getMonth() + 1);
    }

    var url = "cv/agenda/1/" + mes + "/" + new Date().getFullYear();

    api
      .get(url)
      .then((res) => {
        if (res.data != "") {
          // console.log("res do axios " + JSON.stringify(res.data));
          const calendarEvents = [];
          var cor = "";
          for (let i = 0; i < res.data.length; i++) {
            if (cor == "") {
              cor = "#009432";
            } else {
              cor = res.data[i].cor;
            }
            calendarEvents.push({
              title: res.data[i].cli_nome,
              start: res.data[i].cv_data,
              color: cor,
              cliente: res.data[i].descricao,
              dia: res.data[i].dia,
            });
          }
          this.setState({ calendarEvents });
          if (this.state.onload_graficos == true) {
            this.setState({ onload_graficos: false });
          }
        }
      })
      .catch((error) => {
        const calendarEvents = [];
        this.setState({ calendarEvents });

        return false;
      });
  };

  componentWillUnmount() {
    clearInterval(this.timerID);
  }
  tick() {
    this.GetCalendarioNomeUnicos();
  }
  componentDidMount = () => {
    if (sessionStorage.getItem("supervisor") < 1) {
      this.GetCalendarioNomeUnicos(parseInt(new Date().getMonth() + 1));
      this.timerID = setInterval(() => this.tick(), 100);
      //this.GetCalendarioNomeUnicos();
      var trocar_data = document.querySelectorAll(
        ".fc-prev-button, .fc-next-button, .fc-today-button"
      );

      if (trocar_data !== "") {
        for (var el of trocar_data) {
          el.addEventListener(
            "click",
            function FecharMenuResponsivo() {
              var mes_fullcalendar = document.getElementsByClassName(
                "fc-center"
              )[0].textContent;
              var mes_fullcalendar = mes_fullcalendar.split(" ");
              var mes = "";
              switch (mes_fullcalendar[0]) {
                case "janeiro":
                  mes = 1;
                  break;
                case "fevereiro":
                  mes = 2;
                  break;
                case "março":
                  mes = 3;
                  break;
                case "abril":
                  mes = 4;
                  break;
                case "maio":
                  mes = 5;
                  break;
                case "junho":
                  mes = 6;
                  break;
                case "julho":
                  mes = 7;
                  break;
                case "agosto":
                  mes = 8;
                  break;
                case "setembro":
                  mes = 9;
                  break;
                case "outubro":
                  mes = 10;
                  break;
                case "novembro":
                  mes = 11;
                  break;
                case "dezembro":
                  mes = 12;
                  break;
              }

              // console.log('o mes é '+mes);
              document.getElementById("data_fullcalendar").value = mes;

              // console.log(convert_Date_text(document.getElementsByClassName('fc-center')[0].textContent));
            },
            false
          );
        }
      }
    }
  };

  render() {
    const modal = this.state.modal;
    const array_nome = this.state.array_nome;
    const data_modal = this.state.data_modal;

    return (
      <div className="container-fluid ">
        <div className="row">
          {sessionStorage.getItem("supervisor") < 1 ? (
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="shadow p-3 mb-5 bg-white rounded">
                <div className="demo-app">
                  <div className="demo-app-top"></div>

                  <div className="demo-app-calendar">
                    <FullCalendar
                      defaultView="dayGridMonth"
                      locale="br"
                      buttonText={{
                        today: "Hoje",
                        /* listDay: 'Dia',
                       <button className='btn btn-dark' style={{ padding: '0' }} onClick={this.toggleWeekends}>Sábados e Domingos</button>&nbsp;

                       listWeek: 'Semana',
                      dayGridMonth: 'Mês'*/
                      }}
                      header={{
                        left: "",
                        center: "title",
                        /*  right: 'dayGridMonth,listWeek,listDay'*/
                        right: "prev,next, today",
                      }}
                      themeSystem="bootstrap"
                      nowIndicator={true}
                      eventLimit={true}
                      showMultiDayTimes
                      plugins={[dayGridPlugin, interactionPlugin]}
                      ref={this.calendarComponentRef}
                      weekends={this.state.calendarWeekends}
                      events={this.state.calendarEvents_plugin}
                      eventColor={"#378006"}
                      eventTextColor={"#fff"}
                      eventClick={this.handleEventClick}
                      height={750}
                      dateClick={this.handleDateClick}
                      // eventMouseEnter={this.handleEventMouseEnter}
                      // eventMouseLeave={this.handleEventMouseLeave}
                    />
                  </div>
                </div>
                <input type="hidden" id="data_fullcalendar" />
                <input type="hidden" id="data_atual_clicada" />
                <input
                  type="button"
                  style={{ display: "none" }}
                  id="btnmodal_tabela_diaria"
                  className="btn btn-primary"
                  data-toggle="modal"
                  data-target="#Tabela_diaria"
                />

                <div
                  className="modal fade bd-example-modal-lg"
                  id="Tabela_diaria"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="Tabela_diariaTitle"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                      <div
                        className="modal-header"
                        style={{ backgroundColor: "#000" }}
                      >
                        <h5
                          style={{ width: "100%" }}
                          className="modal-title text-center"
                          id="Tabela_diariaTitle"
                        >
                          {data_modal}
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span style={{ color: "#fff" }} aria-hidden="true">
                            &times;
                          </span>
                        </button>
                      </div>
                      <div id="modal_tabela" className="modal-body">
                        {modal.length > 0 && array_nome.length > 0 ? (
                          <div className="table-responsive">
                            <table
                              id="transferir"
                              className="table table-hover table-bordered text-center"
                            >
                              <thead className="thead-dark">
                                <tr>
                                  <th>Clientes</th>
                                  <th>Descrição</th>
                                </tr>
                              </thead>
                              <tbody>
                                {modal.length > 0 && array_nome.length > 0
                                  ? // map eh equivalente a um for ou foreach em react
                                    array_nome.map((cliente, i) => {
                                      return (
                                        <tr key={i}>
                                          <td
                                            style={{
                                              border: "2px solid #000000",
                                            }}
                                            className="align-middle"
                                          >
                                            {cliente.cliente}
                                          </td>
                                          <td
                                            style={{
                                              border: "2px solid #000000",
                                            }}
                                          >
                                            {
                                              // map eh equivalente a um for ou foreach em react

                                              modal[i].map((mod, j) => {
                                                return (
                                                  <li
                                                    style={{
                                                      border:
                                                        "2px solid #000000",
                                                    }}
                                                    className="list-group-item list-group-item-action"
                                                    style={{
                                                      padding: "0.20rem",
                                                    }}
                                                    key={j}
                                                  >
                                                    {mod.cliente}
                                                  </li>
                                                );
                                              })
                                            }
                                          </td>
                                        </tr>
                                      );
                                    })
                                  : ""}
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          <span className="text-center">
                            Nenhum evento neste dia
                          </span>
                        )}
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-dismiss="modal"
                        >
                          Fechar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}
export default Home;
