import React, { useState, useEffect } from "react";
import { api } from "../utils/WebApiConfig";
import { handleError, handleSuccess, handleInfo } from "../utils/Toastify";
import { useHistory } from "react-router-dom";
import { Trash, Save, Edit, Times } from "../utils/Fontawesome";
import img_usuario from "../imagens/svg/img_usuario.png";
import img_cadastrar from "../imagens/svg/img_cadastrar.png";
import img_excluir from "../imagens/svg/img_excluir.png";
import img_alterar from "../imagens/svg/img_alterar.png";
import img_excluir2 from "../imagens/svg/img_excluir2.png";
import pesquisar from "../imagens/svg/img_lupa.png";
import { TablePagination } from "@material-ui/core";
import { ilikeToRegExp, removeAcento } from "../utils/Mascara";
const Clientes = () => {
  let history = useHistory();
  var [url, setUrl] = useState("api/produto");

  const [prod_ativo, setProd_ativo] = useState(1);

  const [prod_nome, setProd_nome] = useState("");
  const [prod_id, setProd_id] = useState(0);

  const [produto, setProduto] = useState([]);
  const [nomeModal, setNomeModal] = useState("");
  const [idModal, setIdModal] = useState("");
  const [idStatusModal, setStatusModal] = useState("");

  // variaveis da paginaçãp
  const [arrayFiltrado, setArrayFiltrado] = useState("");
  const [busca, setBusca] = useState("");
  const [produtoPag, setProdutoPag] = useState([]);
  const [onloadStatus, setOnloadStatus] = useState(0);
  const [lastID, setLastID] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = async (event, newPage) => {
    await setPage(newPage);

    var inicio = newPage * rowsPerPage + 1;
    var fim = inicio + (rowsPerPage - 1);
    /*  console.log(
      "rowsPerPage:" +
        rowsPerPage +
        " newPage " +
        newPage +
        " " +
        inicio +
        " ---- " +
        fim
    );*/
    if (fim > arrayFiltrado.length) {
      fim = arrayFiltrado.length;
    }
    const usuariosPag = [];
    for (let i = inicio - 1; i < fim; i++) {
      usuariosPag.push(arrayFiltrado[i]);
    }
    setProdutoPag(usuariosPag);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

    var inicio = 0 * event.target.value + 1;
    var fim = inicio + (event.target.value - 1);
    if (fim > arrayFiltrado.length) {
      fim = arrayFiltrado.length;
    }
    const usuariosPag = [];
    for (let i = inicio - 1; i < fim; i++) {
      usuariosPag.push(arrayFiltrado[i]);
    }
    setProdutoPag(usuariosPag);
  };
  useEffect(() => {
    if(document.getElementsByClassName('modal-backdrop')[0]){
      var no = document.getElementsByClassName("modal-backdrop")[0];
      if (no.parentNode) {
        no.parentNode.removeChild(no);
      }
    }
    document.getElementById("prod_ativo").checked = true;
    getProdutos();
  }, []);

  const checkboxAtivo = () => {
    if (prod_ativo === 1) {
      setProd_ativo(0);
    } else {
      setProd_ativo(1);
    }
  };
  const catchResponse = (status) => {
    if (status) {
      if (status === 401) {
        if(document.getElementById("btn_limpar")){
          document.getElementById("btn_limpar").click();
        } 
        handleInfo("Sessão expirada.");
        history.push("/login");
      }
    }
    handleError("Ocorreu um erro inesperado na execução desta operação");
  };

  const getProdutos = () => {
    api
      .get("api/produtos", {
        headers: {
          Authorization: "bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((response) => {
        setProduto(response.data);
        setArrayFiltrado(response.data);
        setTotalPages(response.data.length);
        var inicio = 0;
        var fim = 10;
        if (fim > response.data.length) {
          fim = response.data.length;
        }

        const usuarioPag = [];
        for (let i = inicio; i < fim; i++) {
          usuarioPag.push(response.data[i]);
        }
        setProdutoPag(usuarioPag);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };

  const editar = (produto) => {
    //  console.log(cliente);
    document.getElementById("prod_nome").focus();

    setProd_nome(produto.prod_nome);
    setProd_id(produto.prod_id);
    setProd_ativo(produto.prod_ativo);
    if (produto.prod_ativo == 1) {
      document.getElementById("prod_ativo").checked = true;
    } else {
      document.getElementById("prod_ativo").checked = false;
    }
  };

  const dadosModal = (nome, id, status) => {
    setNomeModal(nome);
    setIdModal(id);
    setStatusModal(status);
  };
  const save = (e, metodo, status) => {
    e.preventDefault();
    e.stopPropagation();
    let data, method;
    if (metodo) {
      method = "delete";
    } else {
      if (!prod_nome) {
        handleInfo("Preencha o campo nome do produto.");
        return false;
      }

      if (!prod_id) {
        method = "post";
        let existeCidade = produto.findIndex(
          (val) => val.prod_nome == prod_nome
        );
        if (existeCidade !== -1) {
          handleInfo("Produto já existente");
          return false;
        }
      } else {
        method = "put";
      }
    }

    if (method === "put") {
      data = {
        prod_id: prod_id,
        prod_ativo: prod_ativo,
        prod_nome: prod_nome,
      };
      var url = "api/produtos/" + prod_id;
    } else if (method === "post") {
      data = {
        prod_nome: prod_nome,
        prod_ativo: prod_ativo,
      };
      var url = "api/produtos";
    } else if (method === "delete") {
      // url = url + "/" + idModal + "/" + status;
      var url = "api/produtos/" + idModal;
      data = {
        id: idModal,
      };
      setNomeModal("");
      setIdModal("");
    }

    api({
      method: method,
      url: url,
      data: data,
      headers: {
        Authorization: "bearer " + sessionStorage.getItem("token"),
      },
    })
      .then((response) => {
        handleSuccess(response.headers.statusmessage);   
        if(document.getElementById("btn_limpar")){
          document.getElementById("btn_limpar").click();
        } 
          getProdutos();
        
      })
      .catch((error) => {
        //  catchResponse();

        catchResponse(error.response.status);
        console.log(error);
      });
  };

  const limpar = () => {
    setProd_ativo(1);
    setProd_nome("");
    setProd_id("");

    setNomeModal("");
    setIdModal("");
  };
  const buscar = async () => {
    var filtrado;
    if (arrayFiltrado) {
      if (!busca) {
        filtrado = produto;
      } else {
        filtrado = arrayFiltrado.filter(function (obj) {
          return ilikeToRegExp("%" + busca + "%").test(obj.prod_nome);
        });
      }
      var inicio = 0 * rowsPerPage + 1;
      var fim = inicio + rowsPerPage - 1;
      if(!filtrado.length){
        filtrado = produto;
handleInfo('Nenhum registro encontrado.');
      }
      if (fim > filtrado.length) {
        fim = filtrado.length;
      }
      const cidadePag = [];
      for (let i = inicio - 1; i < fim; i++) {
        cidadePag.push(filtrado[i]);
      }

      await setProdutoPag(cidadePag);
      await setTotalPages(filtrado.length);
      await setArrayFiltrado(filtrado);
      await setPage(0);
    } else {
      handleInfo("Nenhum registro encontrado");
    }
  };
  return (
    <>
      <div className="container-fluid">
        <div
          class="modal fade bd-example-modal-md"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-md">
            <div class="modal-content">
              <div className="card ">
                <div className="card-header"></div>
                <span className="span-card-titulo">
                  <img src={img_usuario} className="img-card" alt="logo" />
                  Cadastro de Produto
                </span>
                <div id="triangulo-para-baixo"></div>
                <div className="card-body">
                  <form id="form_cliente" onSubmit={(e) => save(e)}>
                    <div className="form-row mb-3">
                      <div className="col-md-10 col-9 mb-3">
                        <label htmlFor="prod_nome">Nome do Produto:<strong className='fild-required'>*</strong></label>
                        <input
                          type="text"
                          className="form-control"
                          id="prod_nome"
                          placeholder="Digite o nome do produto"
                          onChange={(e) =>
                            setProd_nome(e.target.value.toUpperCase())
                          }
                          value={prod_nome}
                          required
                        />
                      </div>
                      <div className="col-md-2 col-2 mb-3">
                        <label htmlFor="prod_ativo">
                          {prod_ativo == 1 ? (
                            <b className="text-success">Status</b>
                          ) : (
                            <b className="text-secondary">Status</b>
                          )}
                        </label>
                        <div class="material-switch   pull-right">
                          <input
                            onClick={(e) => checkboxAtivo()}
                            id="prod_ativo"
                            name="prod_ativo"
                            type="checkbox"
                          />
                          <label
                            htmlFor="prod_ativo"
                            class="badge-success"
                          ></label>
                        </div>
                      </div>
                    </div>
                    <div className="text-left ">
                      {prod_id ? (
                        <button
                          className="btn btn-sm btn-51comvc "
                          type="submit"
                          id="btnAlterar"
                        >
                          Alterar
                          <img
                            src={img_cadastrar}
                            className="img-btn"
                            alt="logo"
                          />
                        </button>
                      ) : (
                        <button
                          className="btn btn-sm btn-51comvc "
                          type="submit"
                          id="btnCadastrar"
                        >
                          Cadastrar
                          <img
                            src={img_cadastrar}
                            className="img-btn"
                            alt="logo"
                          />
                        </button>
                      )}
                      &nbsp;&nbsp;{" "}
                      <button
                        className="btn btn-sm btn-51comvc "
                        onClick={(e) => limpar(e)}
                        type="button"
                        data-dismiss="modal"
                        aria-label="Close"
                        id="btn_limpar"
                      >
                        Fechar
                        <img src={img_excluir} className="img-btn" alt="logo" />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header"></div>
          <span className="span-card-titulo">
            <img src={img_usuario} className="img-card" alt="logo" />
            Lista de Produtos
          </span>
          <div id="triangulo-para-baixo"></div>
          <div className="card-body card-body-lista">
            <form>
              <div className="form-group">
                <div className="form-row">
                  <div className="col-md-5 col-12">
                    <div className="row">
                      <div
                        className="col-md-10  col-10 col-sm-10"
                        style={{ paddingRight: "0px" }}
                      >
                        <label htmlFor="filtro">Filtrar Tabela:</label>
                        <input
                          id="filtro"
                          className="form-control"
                          style={{ display: "inline" }}
                          type="text"
                          autoComplete="new-password"
                          placeholder="Termo..."
                          onChange={(e) =>
                            setBusca(removeAcento(e.target.value))
                          }
                          value={busca}
                        />
                      </div>
                      <div
                        className="col-md-1 col-1  col-sm-1"
                        style={{ paddingLeft: "0px" }}
                      >
                        {" "}
                        <label htmlFor="btn_visualizar">&nbsp;&nbsp;</label>
                        <button
                          style={{ display: "inline" }}
                          type="button"
                          className="btn  bg-51comvc bl-3"
                          id="btn_visualizar"
                          onClick={(e) => buscar(e)}
                        >
                          <img src={pesquisar} className="img-btn" alt="logo" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <label htmlFor="btn_Cadastrar">&nbsp;&nbsp;&nbsp;</label>
                    <br />
                    <button
                      type="button"
                      className="btn  bg-51comvc btn-block "
                      id="btn_Cadastrar"
                      onClick={(e) => limpar(e)}
                      data-toggle="modal"
                      data-target=".bd-example-modal-md"
                    >
                      Cadastrar
                      <img src={img_cadastrar} className="img-btn" alt="logo" />
                    </button>
                  </div>
                  <div className="col-md-5"></div>
                </div>
              </div>
            </form>

            <div className=" table-responsive">
              <table className="table  table-hover table-striped table-light ">
                <thead className="text-white  bg-dark ">
                  <tr>
                    <th style={{ width: "75%" }} scope="col">
                      Nome
                    </th>
                    <th style={{ width: "10%" }} scope="col">
                      Status
                    </th>
                    <th style={{ width: "15%" }} scope="col">
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {produtoPag.length > 0
                    ? produtoPag.map(function (produtos, i) {
                        return (
                          <>
                            <tr key={produtos.prod_id}>
                              <th>{produtos.prod_nome}</th>

                              <th>
                                {produtos.prod_ativo === 1
                                  ? "Ativo"
                                  : "Inativo"}
                              </th>
                              <td>
                                <button
                                  type="button"
                                  onClick={(e) => editar(produtos)}
                                  className="btn btn-sm "
                                  data-toggle="modal"
                                  data-target=".bd-example-modal-md"
                                >
                                  <img
                                    src={img_alterar}
                                    className="img-btn-acao"
                                    alt="logo"
                                  />
                                </button>
                                <button
                                  type="button"
                                  data-toggle="modal"
                                  data-target="#exampleModalCenter"
                                  onClick={(e) =>
                                    dadosModal(
                                      produtos.prod_nome,
                                      produtos.prod_id,
                                      produtos.prod_ativo
                                    )
                                  }
                                  className="btn btn-sm  btn-excluir"
                                >
                                  <img
                                    src={img_excluir2}
                                    className="img-btn-acao"
                                    alt="logo"
                                  />
                                </button>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    : ""}
                </tbody>
              </table>
              <TablePagination
                component="div"
                count={totalPages}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={"Registros por página"}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModalCenter"
        tabIndex="1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="card">
              <div className="card-header"></div>
              <span className="span-card-titulo">
                <img src={img_usuario} className="img-card" alt="logo" />{" "}
                Excluir Produto
              </span>
              <div id="triangulo-para-baixo"></div>
              <div className="card-body">
                Tem certeza que excluir o produto{" "}
                <i>
                  <b className="text-danger">"</b>
                  {nomeModal}
                  <b className="text-danger">"</b>
                </i>{" "}
              </div>
              <div className="modal-footer-alert">
                <button
                  className="btn btn-sm btn-51comvc "
                  type="button"
                  data-dismiss="modal"
                >
                  {Times}
                  &nbsp;Cancelar
                </button>
                &nbsp;&nbsp;
                <button
                  className="btn btn-sm btn-51comvc "
                  onClick={(e) => save(e, "delete", 0)}
                  type="button"
                  data-dismiss="modal"
                >
                  Excluir
                  <img src={img_excluir} className="img-btn" alt="logo" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Clientes;
