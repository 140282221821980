import React, { useState, useEffect } from 'react';
import { api } from '../utils/WebApiConfig';
import wood from '../imagens/woods.jpg';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { Edit, AngleDoubleLeft, Eye } from '../utils/Fontawesome';
import { handleError, handleSuccess, handleInfo } from '../utils/Toastify';

const RecuperarSenha = () => {
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [repetirSenha, setRepetirSenha] = useState('');
  const [codigo, setCodigo] = useState('');
  let history = useHistory();
  const location = useLocation();

  const save = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let data;
    if (senha !== repetirSenha) {
      handleInfo('Senha repetida esta diferente da nova senha');
      return false;
    }

    data = {
      uso_codigo: codigo,
      usu_senha: senha,
      usu_email: email,
    };

    api({
      method: 'put',
      url: 'api/usuarios/recuperarsenha/',
      data: data,
      headers: {
        Authorization: 'bearer ' + sessionStorage.getItem('token'),
      },
    })
      .then((response) => {
        handleSuccess(response.headers.statusmessage);
        limpar();
        history.push('/login');
      })
      .catch((error) => {
        //  catchResponse();

        handleError('Ocorreu um erro inesperado na execução desta operação');
        console.log(error);
      });
  };

  const limpar = () => {
    setRepetirSenha('');
    setSenha('');
  };
  useEffect(() => {
    if (document.getElementsByClassName('modal-backdrop')[0]) {
      var no = document.getElementsByClassName('modal-backdrop')[0];
      if (no.parentNode) {
        no.parentNode.removeChild(no);
      }
    }
    var uri = location.pathname.split('/');
    if (uri[2] && uri[3]) {
      setEmail(atob(uri[2]));
      setCodigo(uri[3]);
    }
  }, []);

  return (
    <>
      <div className=" main" style={{ backgroundImage: 'url(' + wood + ')' }}>
        <div class="container">
          <div className="row ">
            <div className="col-1 col-sm-4 col-md-3 col-lg-4"></div>
            <div className="col-10 col-sm-4 col-md-6 col-lg-4 mb-5 mt-5 ">
              <div
                class="card "
                style={{ background: '#f5f5f5' }}
                style={{ boxShadow: 'inset 0 0 1em gold, 0 0 1em black' }}
              >
                <div
                  class="card-header text-dark  text-center font-weight-bold"
                  style={{ borderTop: 'none' }}
                >
                  <h2> Alterar Senha</h2>
                </div>
                <div class="card-body">
                  <form id="form_recuperarSenha" onSubmit={(e) => save(e)}>
                    <div className="form-row mb-3">
                      <div
                        className="col-md-12  col-12 col-sm-12 mb-3"
                        style={{ paddingRight: '0px' }}
                      >
                        <label htmlFor="email">Email:</label>
                        <input
                          id="email"
                          className="form-control"
                          style={{ display: 'inline' }}
                          type="text"
                          autoComplete="new-password"
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                          readOnly
                          maxLength="8"
                          placeholder="Email"
                        />
                      </div>
                      <div
                        id="col-senha"
                        className=" col-md-12  col-12 col-sm-12 mb-3"
                      >
                        <label htmlFor="senha">
                          Repetir senha:
                          <strong className="fild-required">*</strong>
                        </label>{' '}
                        <div className="input-group">
                          <input
                            type="password"
                            className="form-control"
                            id="senha"
                            placeholder="Digite sua Senha"
                            style={{ width: '80%' }}
                            maxLength="12"
                            minLength="4"
                            autoComplete="new-password"
                            onChange={(e) => setSenha(e.target.value)}
                            value={senha}
                            required
                          />{' '}
                          <div className="input-group-prepend">
                            <div
                              onClick={(e) =>
                                (document.getElementById('senha').type =
                                  document.getElementById('senha').type ===
                                  'text'
                                    ? 'password'
                                    : 'text')
                              }
                              className="input-group-text "
                            >
                              {Eye}
                            </div>{' '}
                          </div>
                        </div>
                      </div>
                      <div
                        id="col-repetirSenha"
                        className=" col-md-12  col-12 col-sm-12 mb-3"
                      >
                        <label htmlFor="repetirSenha">
                          Repetir senha:
                          <strong className="fild-required">*</strong>
                        </label>{' '}
                        <div className="input-group">
                          <input
                            type="password"
                            className="form-control"
                            id="repetirSenha"
                            placeholder="Repetir Senha"
                            style={{ width: '80%' }}
                            maxLength="12"
                            minLength="4"
                            autoComplete="new-password"
                            onChange={(e) => setRepetirSenha(e.target.value)}
                            value={repetirSenha}
                            required
                          />{' '}
                          <div className="input-group-prepend">
                            <div
                              onClick={(e) =>
                                (document.getElementById('repetirSenha').type =
                                  document.getElementById('repetirSenha')
                                    .type === 'text'
                                    ? 'password'
                                    : 'text')
                              }
                              className="input-group-text "
                            >
                              {Eye}
                            </div>{' '}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                      <button
                        className="btn btn-sm btn-primary mr-3 mb-3"
                        type="submit"
                        id="btnCadastrar"
                        style={{ height: '35px', width: '96px' }}
                      >
                        {Edit} Alterar
                      </button>
                      <Link to="/login">
                        <button
                          className="btn btn-sm btn-secondary  mr-3 mb-3 "
                          type="button"
                          id="btn_limpar"
                          data-dismiss="modal"
                          aria-label="Close"
                          style={{ height: '35px', width: '96px' }}
                        >
                          {AngleDoubleLeft} Login
                        </button>{' '}
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className=" col-1 col-sm-4 col-md-3  col-lg-4"></div>
          </div>
        </div>
      </div>
    </>
  );
};
export default RecuperarSenha;
