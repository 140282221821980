import React from "react";

import { useHistory } from "react-router-dom";

const Logout = () => {
  let history = useHistory();
  sessionStorage.clear();
  history.push("/");
  return <></>;
};
export default Logout;
