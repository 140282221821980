import React, { useState, useEffect } from 'react';
import { TablePagination } from '@material-ui/core';
import Select from 'react-select';
import axios from 'axios';
import { api } from '../utils/WebApiConfig';
import { handleError, handleSuccess, handleInfo } from '../utils/Toastify';
import { useHistory } from 'react-router-dom';
import { Times, FileExcel } from '../utils/Fontawesome';
import {
  mask_Numeros,
  mask_CEP,
  removeAcento,
  ilikeToRegExp,
  mask_tel_celular_area,
} from '../utils/Mascara';
import img_usuario from '../imagens/svg/img_usuario.png';
import img_cadastrar from '../imagens/svg/img_cadastrar.png';
import img_excluir from '../imagens/svg/img_excluir.png';
import img_alterar from '../imagens/svg/img_alterar.png';
import img_excluir2 from '../imagens/svg/img_excluir2.png';
import ReactExport from 'react-export-excel';
import pesquisar from '../imagens/svg/img_lupa.png';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Clientes = () => {
  let history = useHistory();
  var [url, setUrl] = useState('api/distribuidora');
  const [cid_uf, setCid_uf] = useState([]);
  const [statusCid_uf, setStatusCid_uf] = useState([
    { value: 'TO', label: 'TO - Tocantins' },
    { value: 'SP', label: 'SP - São Paulo' },
    { value: 'SE', label: 'SE - Sergipe' },
    { value: 'SC', label: 'SC - Santa Catarina' },
    { value: 'RS', label: 'RS - Rio Grande do Sul' },
    { value: 'RR', label: 'RR - Roraima' },
    { value: 'RO', label: 'RO - Rondônia' },
    { value: 'RN', label: 'RN - Rio Grande do Norte' },
    { value: 'RJ', label: 'RJ - Rio de Janeiro' },
    { value: 'PR', label: 'PR - Paraná' },
    { value: 'PI', label: 'PI - Piauí' },
    { value: 'PE', label: 'PE - Pernambuco' },
    { value: 'PB', label: 'PB - Paraíba' },
    { value: 'PA', label: 'PA - Pará' },
    { value: 'MT', label: 'MT - Mato Grosso' },
    { value: 'MS', label: 'MS - Mato Grosso do Sul' },
    { value: 'MG', label: 'MG - Minas Gerais' },
    { value: 'MA', label: 'MA - Maranhão' },
    { value: 'GO', label: 'GO - Goiás' },
    { value: 'ES', label: 'ES - Espírito Santo' },
    { value: 'DF', label: 'DF - Distrito Federal' },
    { value: 'CE', label: 'CE - Ceará' },
    { value: 'BA', label: 'BA - Bahia' },
    { value: 'AP', label: 'AP - Amapá' },
    { value: 'AM', label: 'AM - Amazonas' },
    { value: 'AL', label: 'AL - Alagoas' },
    { value: 'AC', label: 'AC - Acre' },
  ]);
  const [di_id, setDi_id] = useState('');
  const [di_nome, setDi_nome] = useState('');
  const [di_codigo, setDi_codigo] = useState('');
  const [di_endereco, setDi_endereco] = useState('');
  const [di_numero, setDi_numero] = useState('');
  const [di_bairro, setDi_bairro] = useState('');
  const [di_complemento, setDi_complemento] = useState('');
  const [di_cep, setDi_cep] = useState('');
  const [di_nomefantasia, setDi_nomefantasia] = useState('');
  const [di_fone1, setDi_fone1] = useState('');
  const [di_fone2, setDi_fone2] = useState('');
  const [di_latitude, setDi_latitude] = useState('');
  const [di_longitude, setDi_longitude] = useState('');
  const [di_cidade, setDi_cidade] = useState([]);
  const [statusDi_cidade, setStatusDi_cidade] = useState([]);
  const [di_regional, setDi_regional] = useState([]);
  const [statusDi_regional, setStatusDi_regional] = useState([]);
  const [di_ativo, setDi_ativo] = useState(1);
  const [di_email, setDi_email] = useState('');
  const [arrayFiltrado, setArrayFiltrado] = useState('');
  const [busca, setBusca] = useState('');
  const [distribuidoras, setDistribuidoras] = useState([]);
  const [idModal, setIdModal] = useState('');
  const [nomeModal, setNomeModal] = useState('');
  const [idStatusModal, setStatusModal] = useState('');
  const [distribuidoraExcel, setDistribuidoraExcel] = useState('');

  const [tempo_status, setTempo_status] = useState(false);
  // variaveis da paginaçãp
  const [distribuidoraPag, setDistribuidoraPag] = useState([]);
  const [onloadStatus, setOnloadStatus] = useState(0);
  const [lastID, setLastID] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = async (event, newPage) => {
    await setPage(newPage);

    var inicio = newPage * rowsPerPage + 1;
    var fim = inicio + (rowsPerPage - 1);
    /* console.log(
      "rowsPerPage:" +
        rowsPerPage +
        " newPage " +
        newPage +
        " " +
        inicio +
        " ---- " +
        fim
    );*/
    if (fim > arrayFiltrado.length) {
      fim = arrayFiltrado.length;
    }
    const DistribuidoraPag = [];
    for (let i = inicio - 1; i < fim; i++) {
      DistribuidoraPag.push(arrayFiltrado[i]);
    }
    setDistribuidoraPag(DistribuidoraPag);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

    var inicio = 0 * event.target.value + 1;
    var fim = inicio + (event.target.value - 1);
    const DistribuidoraPag = [];
    for (let i = inicio - 1; i < fim; i++) {
      DistribuidoraPag.push(arrayFiltrado[i]);
    }
    setDistribuidoraPag(DistribuidoraPag);
  };

  useEffect(() => {
    if (document.getElementsByClassName('modal-backdrop')[0]) {
      var no = document.getElementsByClassName('modal-backdrop')[0];
      if (no.parentNode) {
        no.parentNode.removeChild(no);
      }
    }
    if (sessionStorage.getItem('perfil') !== 'G') {
      setDi_regional({
        value: sessionStorage.getItem('regional'),
        label: sessionStorage.getItem('regional_nome'),
      });
    } else {
      getRegional();
    }
    document.getElementById('ativo').checked = true;

    getDistribuidora();
  }, []);

  useEffect(() => {
    if (di_latitude && di_longitude && tempo_status == true) {
      setTempo_status(false);
      save();
    }
  }, [tempo_status]);
  const catchResponse = (status) => {
    if (status) {
      if (status === 401) {
        if (document.getElementById('btn_limpar')) {
          document.getElementById('btn_limpar').click();
        }
        handleInfo('Sessão expirada.');
        history.push('/login');
      }
    }
    handleError('Ocorreu um erro inesperado na execução desta operação');
  };

  const getDistribuidora = () => {
    api
      .get(
        'api/distribuidoras?regional=' + sessionStorage.getItem('regional'),
        {
          headers: {
            Authorization: 'bearer ' + sessionStorage.getItem('token'),
          },
        },
      )
      .then((response) => {
        setDistribuidoras(response.data);
        setArrayFiltrado(response.data);
        console.log('total de paginas ' + response.data.length);
        setTotalPages(response.data.length);
        const DistribuidoraPag = [];
        if (response.data.length > 0) {
          var inicio = 0;
          var fim = 10;

          if (fim > response.data.length) {
            fim = response.data.length;
          }

          for (let i = inicio; i < fim; i++) {
            DistribuidoraPag.push(response.data[i]);
          }
          setDistribuidoraPag(DistribuidoraPag);
          var distribuidoraExcel = [];
          for (let i = 0; i < response.data.length; i++) {
            response.data[i].di_fone1 = mask_tel_celular_area(
              response.data[i].di_fone1,
            );
            response.data[i].di_fone2 = mask_tel_celular_area(
              response.data[i].di_fone2,
            );

            distribuidoraExcel.push(response.data[i]);

            setDistribuidoraExcel(distribuidoraExcel);
          }
        } else {
          setDistribuidoras([]);
          setArrayFiltrado([]);
          setDistribuidoraPag([]);
        }
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };
  const buscar = async () => {
    //  console.log(busca);
    let filtrado, filtradoFantasia, filtradocidade, filtradoUF;

    if (arrayFiltrado) {
      if (!busca) {
        filtrado = distribuidoras;
      } else {
        //console.log(arrayFiltrado);
        filtrado = arrayFiltrado.filter(function (obj) {
          return ilikeToRegExp('%' + busca + '%').test(obj.di_nome);
        });

        filtradoFantasia = arrayFiltrado.filter(function (obj) {
          return ilikeToRegExp('%' + busca + '%').test(obj.di_nomefantasia);
        });
        filtrado = filtrado.concat(filtradoFantasia);
        filtradocidade = arrayFiltrado.filter(function (obj) {
          return ilikeToRegExp('%' + busca + '%').test(obj.cid_municipio);
        });
        filtrado = filtrado.concat(filtradocidade);
        filtradoUF = arrayFiltrado.filter(function (obj) {
          return ilikeToRegExp('%' + busca + '%').test(obj.cid_uf);
        });
        filtrado = filtrado.concat(filtradoUF);

        filtrado = filtrado.filter(function (a) {
          return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
        }, Object.create(null));
      }

      var inicio = 0 * rowsPerPage + 1;
      var fim = inicio + rowsPerPage - 1;
      if (!filtrado.length) {
        filtrado = distribuidoras;
        handleInfo('Nenhum registro encontrado.');
      }
      if (fim > filtrado.length) {
        fim = filtrado.length;
      }
      const cidadePag = [];
      for (let i = inicio - 1; i < fim; i++) {
        cidadePag.push(filtrado[i]);
      }

      await setDistribuidoraPag(cidadePag);
      await setTotalPages(filtrado.length);
      await setArrayFiltrado(filtrado);
      await setPage(0);
    } else {
      handleInfo('Nenhum registro encontrado');
    }
  };

  const getCidade = (uf) => {
    api
      .get('api/cidades?UF=' + uf, {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
        },
      })
      .then((response) => {
        const arrayCidade = [];

        for (let i = 0; i < response.data.length; i++) {
          arrayCidade.push({
            value: response.data[i].cid_id,
            label: response.data[i].cid_municipio,
          });
        }
        console.log(arrayCidade);
        setStatusDi_cidade(arrayCidade);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };
  const getRegional = () => {
    api
      .get('api/regionais', {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
        },
      })
      .then((response) => {
        const arrayRegional = [];

        for (let i = 0; i < response.data.length; i++) {
          arrayRegional.push({
            value: response.data[i].reg_id,
            label: response.data[i].reg_nome,
          });
        }

        setStatusDi_regional(arrayRegional);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };

  const checkboxAtivo = () => {
    if (di_ativo === 1) {
      setDi_ativo(0);
    } else {
      setDi_ativo(1);
    }
  };
  const handleChangeUF = (cid_uf) => {
    setCid_uf(cid_uf);
    var uf = '';
    switch (cid_uf.value) {
      case 'TO':
        uf = 1;
        break;
      case 'SP':
        uf = 2;
        break;
      case 'SE':
        uf = 3;
        break;
      case 'SC':
        uf = 4;
        break;
      case 'RS':
        uf = 5;
        break;
      case 'RR':
        uf = 6;
        break;
      case 'RO':
        uf = 7;
        break;
      case 'RN':
        uf = 8;
        break;
      case 'RJ':
        uf = 9;
        break;
      case 'PR':
        uf = 10;
        break;
      case 'PI':
        uf = 11;
        break;
      case 'PE':
        uf = 12;
        break;
      case 'PB':
        uf = 13;
        break;
      case 'PA':
        uf = 14;
        break;
      case 'MT':
        uf = 15;
        break;
      case 'MS':
        uf = 16;
        break;
      case 'MG':
        uf = 17;
        break;
      case 'MA':
        uf = 18;
        break;
      case 'GO':
        uf = 19;
        break;
      case 'ES':
        uf = 20;
        break;
      case 'DF':
        uf = 21;
        break;
      case 'CE':
        uf = 22;
        break;
      case 'BA':
        uf = 23;
        break;
      case 'AP':
        uf = 24;
        break;
      case 'AM':
        uf = 25;
        break;
      case 'AL':
        uf = 26;
        break;
      case 'AC':
        uf = 27;
        break;
    }
    getCidade(uf);
  };
  const handleChange = (di_cidade) => {
    setDi_cidade(di_cidade);
  };
  const handleChangeRegional = (di_regional) => {
    setDi_regional(di_regional);
  };

  const editar = (distribuidora) => {
    //  console.log(cliente);
    document.getElementById('di_nome').focus();
    setCid_uf({ value: distribuidora.cid_uf, label: distribuidora.cid_uf });
    if (distribuidora.cid_uf) {
      getCidade(distribuidora.cid_uf);
    }
    setDi_email(distribuidora.di_email);
    if (sessionStorage.getItem('regional') > 0) {
      setStatusDi_regional([
        {
          value: sessionStorage.getItem('regional'),
          label: sessionStorage.getItem('regional_nome'),
        },
        {
          value: 0,
          label: 'NAO DEFINIDO',
        },
      ]);
    }

    setDi_regional({
      value: distribuidora.di_regional,
      label: distribuidora.reg_nome,
    });

    setDi_cidade({
      value: distribuidora.di_cidade,
      label: distribuidora.cid_municipio,
    });

    setDi_fone2(distribuidora.di_fone2);
    setDi_fone1(distribuidora.di_fone1);
    setDi_nomefantasia(distribuidora.di_nomefantasia);
    setDi_id(distribuidora.di_id);
    setDi_nome(distribuidora.di_nome);
    setDi_codigo(distribuidora.di_codigo);
    setDi_endereco(distribuidora.di_endereco);
    setDi_numero(distribuidora.di_numero);

    if (distribuidora.di_ativo === 1) {
      if (document.getElementById('ativo')) {
        document.getElementById('ativo').checked = true;
      } else {
        document.getElementById('ativo').checked = false;
      }
    }
    setDi_ativo(distribuidora.di_ativo);
    setDi_bairro(distribuidora.di_bairro);
    setDi_complemento(distribuidora.di_complemento);
    setDi_cep(distribuidora.di_cep);
  };

  const dadosModal = (nome, id, status) => {
    setNomeModal(nome);
    setIdModal(id);
    setStatusModal(status);
  };
  const save = (metodo, status) => {
    let data, method;
    if (metodo) {
      method = 'delete';
    } else {
      if (!di_nome) {
        handleInfo('Preencha o campo Nome.');
        return false;
      }
      if (!di_endereco) {
        handleInfo('Preencha o campo Endereço.');
        return false;
      }
      if (!di_codigo) {
        handleInfo('Preencha o campo Código.');
        return false;
      }
      if (!di_numero) {
        handleInfo('Preencha o campo Número.');
        return false;
      }
      if (!di_cidade.value) {
        handleInfo('Selecione uma opção de Cidade.');
        return false;
      }
      if (!di_regional.value) {
        handleInfo('Selecione uma opção Regional.');
        return false;
      }

      if (!di_id) {
        method = 'post';
      } else {
        method = 'put';
      }
    }

    if (method === 'put') {
      data = {
        di_id: di_id,
        di_codigo: di_codigo,
        di_nome: di_nome,
        di_endereco: di_endereco,
        di_numero: di_numero,
        di_bairro: di_bairro,
        di_complemento: di_complemento,
        di_cep: di_cep,
        di_cidade: di_cidade.value,
        cid_municipio: removeAcento(di_cidade.label),
        cid_uf: cid_uf.value,
        di_regional: di_regional.value,
        di_ativo: di_ativo,
        di_email: di_email,
        di_nomefantasia: di_nomefantasia,
        di_fone1: String(di_fone1),
        di_fone2: String(di_fone2),
        di_latitude: di_latitude,
        di_longitude: di_longitude,
      };
      var url = 'api/distribuidoras/' + di_id;
    } else if (method === 'post') {
      data = {
        di_codigo: di_codigo,
        di_nome: di_nome,
        di_endereco: di_endereco,
        di_numero: di_numero,
        di_bairro: di_bairro,
        di_complemento: di_complemento,
        di_cep: di_cep,
        di_cidade: di_cidade.value,
        cid_municipio: removeAcento(di_cidade.label),
        cid_uf: cid_uf.value,
        di_regional: di_regional.value,
        di_ativo: di_ativo,
        di_email: di_email,
        di_nomefantasia: di_nomefantasia,
        di_fone1: String(di_fone1),
        di_fone2: String(di_fone2),
        di_latitude: di_latitude,
        di_longitude: di_longitude,
      };
      var url = 'api/distribuidoras';
    } else if (method === 'delete') {
      //   url = url + "/" + idModal + "/" + status;

      var url = 'api/distribuidoras/' + idModal;
      data = {
        id: idModal,
      };
      setNomeModal('');
      setIdModal('');
    }
    //  console.log(JSON.stringify(data));
    // return false;
    api({
      method: method,
      url: url,
      data: data,
      headers: {
        Authorization: 'bearer ' + sessionStorage.getItem('token'),
      },
    })
      .then((response) => {
        handleSuccess(response.headers.statusmessage);
        if (document.getElementById('btn_limpar')) {
          document.getElementById('btn_limpar').click();
        }

        getDistribuidora();
      })
      .catch((error) => {
        catchResponse(error.response.status);

        console.log(error);
      });
  };
  const GetGeoLocation = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    //  var municipio = String(pm_cidade.label);
    //  municipio = municipio.replace(" / ", ",");

    axios({
      method: 'get',
      url:
        'https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyBI7LOX1L6tbGwOeFcixa4rjWx2zvaiuqE&sensor=false&address=' +
        di_endereco +
        ',' +
        di_numero +
        ',' +
        di_bairro +
        ',' +
        di_cidade.label +
        ',' +
        cid_uf +
        '&components=country:BR',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        setDi_longitude(response.data.results[0].geometry.location.lng);
        setDi_latitude(response.data.results[0].geometry.location.lat);
        setTempo_status(true);
      })
      .catch((error) => {
        console.log(error);
        if (error) {
          catchResponse(error);
        }
      });
  };
  const limpar = () => {
    if (sessionStorage.getItem('perfil') !== 'G') {
      setDi_regional({
        value: sessionStorage.getItem('regional'),
        label: sessionStorage.getItem('regional_nome'),
      });
    }
    setCid_uf([]);
    setDi_cidade([]);
    setDi_id('');
    setDi_nome('');
    setDi_email('');
    setDi_codigo('');
    setDi_endereco('');
    setDi_numero('');
    setDi_ativo(1);
    setDi_bairro('');
    setDi_complemento('');
    setDi_cep('');
    setDi_nomefantasia('');
    setDi_fone1('');
    setDi_fone2('');

    setNomeModal('');
    setIdModal('');
  };
  const viacep = (value) => {
    if (value) {
      if (value.length > 9) {
        axios
          .get('https://viacep.com.br/ws/' + mask_Numeros(value) + '/json/')
          .then((response) => {
            if (response.data.ibge) {
              // setStatusBar_cidade(arrayCidade);
              setDi_bairro(removeAcento(response.data.bairro));
              setDi_endereco(removeAcento(response.data.logradouro));
              setCid_uf({
                value: response.data.uf,
                label: response.data.uf,
              });
              if (response.data.uf) {
                getCidade(response.data.uf);
              }
              setDi_cidade({
                value: response.data.ibge,
                label: removeAcento(response.data.localidade),
              });
            }
          })
          .catch((error) => {
            if (error.response) {
              catchResponse(error.response.status);
            }
          });
      }
    }
    return value;
  };

  return (
    <>
      <div className="container-fluid">
        <div
          className="modal fade bd-example-modal-xl"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="card">
                <div className="card-header"></div>
                <span className="span-card-titulo">
                  <img src={img_usuario} className="img-card" alt="logo" />
                  Cadastro de Distribuidora
                </span>
                <div id="triangulo-para-baixo"></div>
                <div className="card-body">
                  <form id="form_cliente" onSubmit={(e) => GetGeoLocation(e)}>
                    <div className="form-row mb-3">
                      <div className="col-md-4 mb-3">
                        <label htmlFor="di_codigo">Codigo PFJ:</label>
                        <input
                          type="text"
                          className="form-control"
                          id="di_codigo"
                          placeholder="Digite seu Codigo PFJ"
                          onChange={(e) =>
                            setDi_codigo(mask_Numeros(e.target.value))
                          }
                          value={di_codigo}
                          maxLength="30"
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label htmlFor="di_nome">
                          Razão social:
                          <strong className="fild-required">*</strong>
                        </label>
                        <input
                          type="text"
                          maxLength="100"
                          className="form-control"
                          id="di_nome"
                          placeholder="Digite o Nome"
                          onChange={(e) =>
                            setDi_nome(e.target.value.toUpperCase())
                          }
                          value={di_nome}
                          required
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label htmlFor="di_nomefantasia">
                          Nome Fantasia:
                          <strong className="fild-required">*</strong>
                        </label>
                        <input
                          type="text"
                          maxLength="100"
                          className="form-control"
                          id="di_nomefantasia"
                          placeholder="Digite o Nome Fantasia"
                          onChange={(e) =>
                            setDi_nomefantasia(removeAcento(e.target.value))
                          }
                          value={di_nomefantasia}
                          required
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label htmlFor="di_cep">
                          CEP:<strong className="fild-required">*</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="di_cep"
                          maxLength="14"
                          placeholder="Digite o CEP"
                          autoComplete="new-password"
                          onChange={(e) =>
                            setDi_cep(viacep(mask_CEP(e.target.value)))
                          }
                          value={di_cep}
                          required
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label htmlFor="di_endereco">
                          Endereço:<strong className="fild-required">*</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="di_endereco"
                          placeholder="Digite seu Endereço"
                          maxLength="150"
                          autoComplete="new-password"
                          onChange={(e) =>
                            setDi_endereco(
                              removeAcento(e.target.value.toUpperCase()),
                            )
                          }
                          value={di_endereco}
                          required
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label htmlFor="di_numero">
                          Número:<strong className="fild-required">*</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="Número"
                          placeholder="Número"
                          maxLength="20"
                          autoComplete="new-password"
                          onChange={(e) => setDi_numero(e.target.value)}
                          value={di_numero}
                          required
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label htmlFor="di_complemento">Complemento:</label>
                        <input
                          type="text"
                          className="form-control"
                          id="di_complemento"
                          placeholder="Digite o Complemento"
                          autoComplete="new-password"
                          maxLength="80"
                          onChange={(e) =>
                            setDi_complemento(
                              removeAcento(e.target.value.toUpperCase()),
                            )
                          }
                          value={di_complemento}
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label htmlFor="di_bairro">
                          Bairro:<strong className="fild-required">*</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="di_bairro"
                          placeholder="Digite o seu Bairro"
                          maxLength="80"
                          autoComplete="new-password"
                          onChange={(e) =>
                            setDi_bairro(
                              removeAcento(e.target.value.toUpperCase()),
                            )
                          }
                          value={di_bairro}
                          required
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label htmlFor="di_fone1">
                          Telefone/Celular:
                          <strong className="fild-required">*</strong>
                        </label>
                        <input
                          type="text"
                          maxLength="20"
                          className="form-control"
                          id="di_fone1"
                          placeholder="Digite o seu Telefone ou Celular com DDD"
                          onChange={(e) =>
                            setDi_fone1(mask_tel_celular_area(e.target.value))
                          }
                          value={di_fone1}
                          required
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label htmlFor="di_fone2">Telefone/Celular:</label>
                        <input
                          type="text"
                          maxLength="20"
                          className="form-control"
                          id="di_fone2"
                          placeholder="Digite o seu Telefone ou Celular com DDD"
                          onChange={(e) =>
                            setDi_fone2(mask_tel_celular_area(e.target.value))
                          }
                          value={di_fone2}
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label htmlFor="cid_uf">
                          Estado / UF:
                          <strong className="fild-required">*</strong>
                        </label>
                        <Select
                          id="cid_uf"
                          value={cid_uf}
                          onChange={handleChangeUF}
                          options={statusCid_uf}
                        />
                        <input
                          style={{
                            top: '43px',
                            position: 'absolute',
                            zIndex: '-1',
                          }}
                          type="password"
                          id="cid_uf_html5_validate"
                          autoComplete="new-password"
                          value={cid_uf}
                          required
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label htmlFor="di_cidade">
                          Cidade:<strong className="fild-required">*</strong>
                        </label>
                        <Select
                          id="di_cidade"
                          value={di_cidade}
                          onChange={handleChange}
                          options={statusDi_cidade}
                          placeholder={<div>Selecione o estado antes</div>}
                        />
                        <input
                          style={{
                            top: '43px',
                            position: 'absolute',
                            zIndex: '-1',
                          }}
                          type="password"
                          id="di_cidade_html5_validate"
                          autoComplete="new-password"
                          value={di_cidade}
                          required
                        />
                      </div>

                      <div className="col-md-4 mb-3">
                        <label htmlFor="di_regional">
                          Regional:<strong className="fild-required">*</strong>
                        </label>
                        <Select
                          id="di_regional"
                          value={di_regional}
                          onChange={handleChangeRegional}
                          options={statusDi_regional}
                        />
                        <input
                          style={{
                            top: '43px',
                            position: 'absolute',
                            zIndex: '-1',
                            width: '5px',
                          }}
                          type="password"
                          id="di_regional_html5_validate"
                          autoComplete="new-password"
                          value={di_regional}
                          required
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label htmlFor="di_email">Email:</label>
                        <input
                          type="email"
                          className="form-control"
                          id="di_email"
                          maxLength="100"
                          placeholder="Digite o seu Email"
                          autoComplete="new-password"
                          onChange={(e) => setDi_email(e.target.value)}
                          value={di_email}
                        />
                      </div>
                      <div className="col-md-2 mb-3 mt-3 ml-2">
                        <label htmlFor="ativo">
                          {di_ativo == 1 ? (
                            <b className="text-success">Status: Ativo</b>
                          ) : (
                            <b className="text-secondary">Status: Inativo</b>
                          )}
                        </label>
                        <div className="material-switch   pull-right">
                          <input
                            onClick={(e) => checkboxAtivo()}
                            id="ativo"
                            name="ativo"
                            type="checkbox"
                          />
                          <label
                            htmlFor="ativo"
                            className="badge-success"
                          ></label>
                        </div>
                      </div>
                    </div>

                    <div className="text-left ">
                      {di_id ? (
                        <button
                          className="btn btn-sm btn-51comvc "
                          type="submit"
                          id="btnAlterar"
                        >
                          Alterar
                          <img
                            src={img_cadastrar}
                            className="img-btn"
                            alt="logo"
                          />
                        </button>
                      ) : (
                        <button
                          className="btn btn-sm btn-51comvc "
                          type="submit"
                          id="btnCadastrar"
                        >
                          Cadastar
                          <img
                            src={img_cadastrar}
                            className="img-btn"
                            alt="logo"
                          />
                        </button>
                      )}{' '}
                      &nbsp;&nbsp;
                      <button
                        className="btn btn-sm btn-51comvc "
                        onClick={(e) => limpar(e)}
                        type="button"
                        id="btn_limpar"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        Fechar
                        <img src={img_excluir} className="img-btn" alt="logo" />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header"></div>
          <span className="span-card-titulo">
            <img src={img_usuario} className="img-card" alt="logo" />
            Lista de Distribuidora
          </span>
          <div id="triangulo-para-baixo"></div>
          <div className="card-body card-body-lista">
            <form>
              <div className="form-group">
                <div className="form-row">
                  <div className="col-md-5 col-12">
                    <div className="row">
                      <div
                        className="col-md-10  col-10 col-sm-10"
                        style={{ paddingRight: '0px' }}
                      >
                        <label htmlFor="filtro">Filtrar Tabela:</label>
                        <input
                          id="filtro"
                          className="form-control"
                          style={{ display: 'inline' }}
                          type="text"
                          autoComplete="new-password"
                          placeholder="Termo..."
                          onChange={(e) =>
                            setBusca(removeAcento(e.target.value))
                          }
                          value={busca}
                        />
                      </div>
                      <div
                        className="col-md-1 col-1  col-sm-1"
                        style={{ paddingLeft: '0px' }}
                      >
                        {' '}
                        <label htmlFor="btn_visualizar">&nbsp;&nbsp;</label>
                        <button
                          style={{ display: 'inline' }}
                          type="button"
                          className="btn  bg-51comvc bl-3"
                          id="btn_visualizar"
                          onClick={(e) => buscar(e)}
                        >
                          <img src={pesquisar} className="img-btn" alt="logo" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2">
                    <label htmlFor="btn_cadastar">&nbsp;&nbsp;&nbsp;</label>
                    <button
                      type="button"
                      className="btn  bg-51comvc btn-block "
                      id="btn_cadastar"
                      onClick={(e) => limpar(e)}
                      data-toggle="modal"
                      data-target=".bd-example-modal-xl"
                    >
                      Cadastrar
                      <img src={img_cadastrar} className="img-btn" alt="logo" />
                    </button>
                  </div>
                  <div className="col-md-2">
                    <label htmlFor="export-excel">&nbsp;&nbsp;&nbsp;</label>
                    <ExcelFile
                      element={
                        <button
                          type="button"
                          className="btn  bg-51comvc btn-block "
                          id="export-excel"
                        >
                          Export Excel&nbsp;
                          {FileExcel}
                        </button>
                      }
                    >
                      <ExcelSheet data={distribuidoraExcel} name="Employees">
                        <ExcelColumn label="Codigo PFJ" value="di_codigo" />
                        <ExcelColumn label="Razão social" value="di_nome" />
                        <ExcelColumn
                          label="Nome Fantasia"
                          value="di_nomefantasia"
                        />
                        <ExcelColumn label="CEP" value="di_cep" />
                        <ExcelColumn label="Endereço" value="di_endereco" />
                        <ExcelColumn label="Número" value="di_numero" />
                        <ExcelColumn
                          label="Complemento"
                          value="di_complemento"
                        />
                        <ExcelColumn label="Bairro" value="di_bairro" />
                        <ExcelColumn
                          label="Telefone/Celular"
                          value="di_fone1"
                        />
                        <ExcelColumn
                          label="Telefone/Celular"
                          value="di_fone2"
                        />
                        <ExcelColumn label="UF" value="cid_uf" />
                        <ExcelColumn label="Cidade" value="cid_municipio" />
                        <ExcelColumn label="Regional" value="reg_nome" />
                        <ExcelColumn label="Email" value="di_email" />
                        <ExcelColumn
                          label="Status"
                          value={(col) =>
                            col.di_ativo == 1 ? 'ATIVO' : 'INATIVO'
                          }
                        />
                      </ExcelSheet>
                    </ExcelFile>
                  </div>
                  <div className="col-md-4"></div>
                </div>
              </div>
            </form>

            <div className=" table-responsive">
              <table
                id="table-to-xls"
                className="table  table-hover table-striped table-light "
              >
                <thead className="text-white  bg-dark ">
                  <tr>
                    <th style={{ width: '85%' }} scope="col">
                      Nome
                    </th>
                    {/*<th style={{ width: "10%" }} scope="col">
                      Status
                      </th>*/}
                    <th style={{ width: '15%' }} scope="col">
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {distribuidoraPag.length > 0
                    ? distribuidoraPag.map(function (distribuidora, i) {
                        if (distribuidora.di_regional == 0) {
                          var mystyle = {
                            color: '#ff0000',
                          };
                        }

                        return (
                          <>
                            <tr style={mystyle} key={distribuidora.di_id}>
                              <th>
                                {distribuidora.di_nome} <br />
                                <i
                                  style={{
                                    fontSize: '12px',
                                  }}
                                >
                                  {distribuidora.di_nomefantasia}
                                  <br />
                                  {distribuidora.cid_municipio +
                                    ' - ' +
                                    distribuidora.cid_uf}
                                </i>
                              </th>
                              {/*<th >
                                {distribuidora.di_ativo === 1 ? (
                                  <button
                                    type="button"
                                    className="btn btn-status btn-sm bg-51comvc  font-weight-bold"
                                  >
                                    Ativo
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-status  bg-red font-weight-bold"
                                  >
                                    Inativo
                                  </button>
                                )}
                              </th>*/}
                              <td>
                                <button
                                  type="button"
                                  onClick={(e) => editar(distribuidora)}
                                  className="btn btn-sm "
                                  data-toggle="modal"
                                  data-target=".bd-example-modal-xl"
                                >
                                  <img
                                    src={img_alterar}
                                    className="img-btn-acao"
                                    alt="logo"
                                  />
                                </button>{' '}
                                <button
                                  type="button"
                                  data-toggle="modal"
                                  data-target="#exampleModalCenter"
                                  onClick={(e) =>
                                    dadosModal(
                                      distribuidora.di_nome,
                                      distribuidora.di_id,
                                      distribuidora.di_ativo,
                                    )
                                  }
                                  className="btn btn-sm btn-excluir"
                                >
                                  <img
                                    src={img_excluir2}
                                    className="img-btn-acao"
                                    alt="logo"
                                  />
                                </button>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    : ''}
                </tbody>
              </table>
              <TablePagination
                component="div"
                count={totalPages}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={'Registros por página'}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModalCenter"
        tabIndex="1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="card">
              <div className="card-header"></div>
              <span className="span-card-titulo">
                <img src={img_usuario} className="img-card" alt="logo" />{' '}
                Excluir distribuidora
              </span>
              <div id="triangulo-para-baixo"></div>
              <div className="card-body">
                Tem certeza que deseja excluir a distribuidora{' '}
                <i>
                  <b className="text-danger">"</b>
                  {nomeModal}
                  <b className="text-danger">"</b>
                </i>{' '}
              </div>
              <div className="modal-footer-alert">
                <button
                  className="btn btn-sm btn-51comvc "
                  type="button"
                  data-dismiss="modal"
                >
                  {Times}
                  &nbsp;Cancelar
                </button>
                &nbsp;&nbsp;
                <button
                  className="btn btn-sm btn-51comvc "
                  onClick={(e) => save(e, 'delete', 0)}
                  type="button"
                  data-dismiss="modal"
                >
                  Excluir
                  <img src={img_excluir} className="img-btn" alt="logo" />
                </button>
              </div>
            </div>
            {/*
            <div className="modal-header ">
              <h5
                className="modal-title text-white font-weight-bold"
                id="deletarLongTitle"
              >
                Deletar distribuidora
              </h5>
              <button
                type="button"
                className="close text-white"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              Tem certeza que deseja excluir a distribuidora{" "}
              <i>
                <b className="text-danger">"</b>
                {nomeModal}
                <b className="text-danger">"</b>
              </i>{" "}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-sm btn-secondary font-weight-bold"
                data-dismiss="modal"
              >
                {Times}
                &nbsp;Cancelar
              </button>

              <button
                type="button"
                data-dismiss="modal"
                onClick={(e) => save(e, "delete", 0)}
                className="btn btn-sm btn-danger font-weight-bold"
              >
                {Trash}
                &nbsp;Excluir
              </button>
         </div>*/}
          </div>
        </div>
      </div>
    </>
  );
};
export default Clientes;
