import React, { useState, useEffect } from "react";
import { TablePagination } from "@material-ui/core";
import Select from "react-select";
import { api } from "../utils/WebApiConfig";
import axios from "axios";
import { handleError, handleSuccess, handleInfo } from "../utils/Toastify";
import { useHistory } from "react-router-dom";
import {  Times } from "../utils/Fontawesome";
import {
  mask_Numeros,
  mask_CEP,
  ilikeToRegExp,
  removeAcento,
} from "../utils/Mascara";
import img_usuario from "../imagens/svg/img_usuario.png";
import img_cadastrar from "../imagens/svg/img_cadastrar.png";
import img_excluir from "../imagens/svg/img_excluir.png";
import img_alterar from "../imagens/svg/img_alterar.png";
import img_excluir2 from "../imagens/svg/img_excluir2.png";
import pesquisar from "../imagens/svg/img_lupa.png";
const Clientes = () => {
  let history = useHistory();

  const [cid_uf, setCid_uf] = useState([]);
  const [statusCid_uf, setStatusCid_uf] = useState([
    { value: "TO", label: "TO - Tocantins" },
    { value: "SP", label: "SP - São Paulo" },
    { value: "SE", label: "SE - Sergipe" },
    { value: "SC", label: "SC - Santa Catarina" },
    { value: "RS", label: "RS - Rio Grande do Sul" },
    { value: "RR", label: "RR - Roraima" },
    { value: "RO", label: "RO - Rondônia" },
    { value: "RN", label: "RN - Rio Grande do Norte" },
    { value: "RJ", label: "RJ - Rio de Janeiro" },
    { value: "PR", label: "PR - Paraná" },
    { value: "PI", label: "PI - Piauí" },
    { value: "PE", label: "PE - Pernambuco" },
    { value: "PB", label: "PB - Paraíba" },
    { value: "PA", label: "PA - Pará" },
    { value: "MT", label: "MT - Mato Grosso" },
    { value: "MS", label: "MS - Mato Grosso do Sul" },
    { value: "MG", label: "MG - Minas Gerais" },
    { value: "MA", label: "MA - Maranhão" },
    { value: "GO", label: "GO - Goiás" },
    { value: "ES", label: "ES - Espírito Santo" },
    { value: "DF", label: "DF - Distrito Federal" },
    { value: "CE", label: "CE - Ceará" },
    { value: "BA", label: "BA - Bahia" },
    { value: "AP", label: "AP - Amapá" },
    { value: "AM", label: "AM - Amazonas" },
    { value: "AL", label: "AL - Alagoas" },
    { value: "AC", label: "AC - Acre" },
  ]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [pm_tipo, setPm_tipo] = useState([]);
  const [statusPm_tipo, setStatusPm_tipo] = useState([]);
  const [pm_id, setPm_id] = useState("");
  const [pm_descricao, setPm_descricao] = useState("");
  const [pm_endereco, setPm_endereco] = useState("");
  const [pm_numero, setPm_numero] = useState("");
  const [pm_bairro, setPm_bairro] = useState("");
  const [pm_cep, setPm_cep] = useState("");
  const [pm_ativo, setPm_ativo] = useState(1);
  const [pm_cidade, setPm_cidade] = useState("");
  const [statusPm_cidade, setStatusPm_cidade] = useState([]);
  const [pm_latitude, setPm_latitude] = useState("");
  const [pm_longitude, setPm_longitude] = useState("");
  const [pontoDeMidia, setPontoDeMidia] = useState("");

  const [pm_regional, setPm_regional] = useState([]);
  const [statusPm_regional, setStatusPm_regional] = useState([]);
  const [tempo_status, setTempo_status] = useState(false);
  
  const [idModal, setIdModal] = useState("");
  const [nomeModal, setNomeModal] = useState("");
  const [idStatusModal, setStatusModal] = useState("");

  // variaveis da paginaçãp
  const [arrayFiltrado, setArrayFiltrado] = useState("");
  const [busca, setBusca] = useState("");
  const [pontoDeMidiaPag, setPontoDeMidiaPag] = useState([]);
  const [onloadStatus, setOnloadStatus] = useState(0);
  const [lastID, setLastID] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [cont_random, setCont_random] = useState();
  const handleChangePage = async (event, newPage) => {
    await setPage(newPage);

    var inicio = newPage * rowsPerPage + 1;
    var fim = inicio + (rowsPerPage - 1);
    /*  console.log(
       "rowsPerPage:" +
         rowsPerPage +
         " newPage " +
         newPage +
         " " +
         inicio +
         " ---- " +
         fim
     );*/
    if (fim > arrayFiltrado.length) {
      fim = arrayFiltrado.length;
    }
    const usuariosPag = [];
    for (let i = inicio - 1; i < fim; i++) {
      usuariosPag.push(arrayFiltrado[i]);
    }
    setPontoDeMidiaPag(usuariosPag);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

    var inicio = 0 * event.target.value + 1;
    var fim = inicio + (event.target.value - 1);
    if (fim > arrayFiltrado.length) {
      fim = arrayFiltrado.length;
    }
    const usuariosPag = [];
    for (let i = inicio - 1; i < fim; i++) {
      usuariosPag.push(arrayFiltrado[i]);
    }
    setPontoDeMidiaPag(usuariosPag);
  };

  useEffect(() => {
    if(document.getElementsByClassName('modal-backdrop')[0]){
      var no = document.getElementsByClassName("modal-backdrop")[0];
      if (no.parentNode) {
        no.parentNode.removeChild(no);
      }
    }
    if (sessionStorage.getItem("perfil") !== "G") {
      setPm_regional({
        value: sessionStorage.getItem("regional"),
        label: sessionStorage.getItem("regional_nome"),
      });
    } else {
      getRegional();
    }
    document.getElementById("pm_ativo").checked = true;

    getPontoDeMidia();
    getTipoPontoDeMidia();
  }, []);

  useEffect(() => {
    if (pm_latitude && pm_longitude && tempo_status == true) {
      setTempo_status(false);
      save();
    }
  }, [tempo_status]);


  const catchResponse = (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        if(document.getElementById("btn_limpar")){
          document.getElementById("btn_limpar").click();
        } 
        handleInfo("Sessão expirada.");
        history.push("/login");
      }
    }
    handleError("Ocorreu um erro inesperado na execução desta operação");
  };

  const getPontoDeMidia = () => {
    api
      .get("api/pontodemidias", {
        headers: {
          Authorization: "bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((response) => {
        setPontoDeMidia(response.data);
        setArrayFiltrado(response.data);
        setTotalPages(response.data.length);
        var inicio = 0;
        var fim = 10;
        if (fim > response.data.length) {
          fim = response.data.length;
        }

        const usuarioPag = [];
        for (let i = inicio; i < fim; i++) {
          usuarioPag.push(response.data[i]);
        }
        setPontoDeMidiaPag(usuarioPag);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };
  const getRegional = () => {
    api
      .get("api/regionais?regional=" + sessionStorage.getItem("regional"), {
        headers: {
          Authorization: "bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((response) => {
        const arrayRegional = [];
        console.log(response);
        for (let i = 0; i < response.data.length; i++) {
          arrayRegional.push({
            value: response.data[i].reg_id,
            label: response.data[i].reg_nome,
          });
        }

        setStatusPm_regional(arrayRegional);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };
  const getTipoPontoDeMidia = () => {
    api
      .get("api/tipopontodemidias", {
        headers: {
          Authorization: "bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((response) => {
        const arrayTipoPontoDeMidia = [];
        console.log(response);
        for (let i = 0; i < response.data.length; i++) {
          arrayTipoPontoDeMidia.push({
            value: response.data[i].pmt_id,
            label: response.data[i].pmt_nome,
            endereco: response.data[i].pmt_preencheendereco,
          });
        }

        setStatusPm_tipo(arrayTipoPontoDeMidia);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };
  const getCidade = (regional) => {
    api
      .get("api/cidades?regional=" + regional, {
        headers: {
          Authorization: "bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((response) => {
        const arrayCidade = [];

        for (let i = 0; i < response.data.length; i++) {
          arrayCidade.push({
            value: response.data[i].cid_id,
            label:
              response.data[i].cid_municipio + " / " + response.data[i].cid_uf,
          });
        }

        setStatusPm_cidade(arrayCidade);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };

  const checkboxAtivo = () => {
    if (pm_ativo === 1) {
      setPm_ativo(0);
    } else {
      setPm_ativo(1);
    }
  };
  const handleChangeUF = (cid_uf) => {
    setCid_uf(cid_uf);
    getCidade(cid_uf.value);
  };
  const handleChangeRegional = (pm_regional) => {
    setPm_regional(pm_regional);
  };

  const handleChange = (pm_cidade) => {
    setPm_cidade(pm_cidade);
  };
  const handleChangePm_tipo = (pm_tipo) => {
   
    setPm_tipo(pm_tipo);
    if (pm_tipo.endereco === 0) {
      setPm_bairro("");
      document.getElementById("pm_bairro").readOnly = true;
      setPm_numero("");
      document.getElementById("pm_numero").readOnly = true;
      setPm_endereco("");
      document.getElementById("pm_endereco").readOnly = true;
      setPm_cep("");
      document.getElementById("pm_cep").readOnly = true;
    } else {
      document.getElementById("pm_bairro").readOnly = false;

      document.getElementById("pm_numero").readOnly = false;

      document.getElementById("pm_endereco").readOnly = false;

      document.getElementById("pm_cep").readOnly = false;
    }
  };
  const editar = (midias) => {
    console.log(midias);
    //  console.log(cliente);
    document.getElementById("pm_descricao").focus();

    setPm_id(midias.pm_id);
    setCid_uf({ value: midias.cid_uf, label: midias.cid_uf });

    setPm_cidade({
      value: midias.pm_cidade,
      label: midias.cid_municipio + " / " + midias.cid_uf,
    });
    setPm_tipo({
      value: midias.pmt_id,
      label: midias.pmt_nome,
      endereco: midias.pmt_preencheendereco,
    });

    if (midias.pmt_preencheendereco === 0) {
      setPm_bairro("");
      document.getElementById("pm_bairro").readOnly = true;
      setPm_numero("");
      document.getElementById("pm_numero").readOnly = true;
      setPm_endereco("");
      document.getElementById("pm_endereco").readOnly = true;
      setPm_cep("");
      document.getElementById("pm_cep").readOnly = true;
    } else {
      document.getElementById("pm_bairro").readOnly = false;

      document.getElementById("pm_numero").readOnly = false;

      document.getElementById("pm_endereco").readOnly = false;

      document.getElementById("pm_cep").readOnly = false;
    }
    setPm_regional({
      value: midias.pm_regional,
      label: midias.reg_nome,
    });
    setPm_descricao(midias.pm_descricao);
    setPm_endereco(midias.pm_endereco);
    setPm_numero(midias.pm_numero);
    setPm_bairro(midias.pm_bairro);
    setPm_cep(midias.pm_cep);

    if (midias.pm_ativo === 1) {
      document.getElementById("pm_ativo").checked = true;
    } else {
      document.getElementById("pm_ativo").checked = false;
    }
    setPm_ativo(midias.pm_ativo);
    // setPm_latitude(midias.pm_latitude);
    // setPm_longitude(midias.pm_longitude);
  };

  const dadosModal = (nome, id, status) => {
    setNomeModal(nome);
    setIdModal(id);
    setStatusModal(status);
  };

  const GetGeoLocation = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    var municipio = String(pm_cidade.label);
    municipio = municipio.replace(" / ", ",");

    axios({
      method: "get",
      url:
        "https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyBI7LOX1L6tbGwOeFcixa4rjWx2zvaiuqE&sensor=false&address=" +
        pm_endereco+"," +pm_numero+"," +pm_bairro+"," + municipio +"," +cid_uf+"&components=country:BR",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        setPm_longitude(response.data.results[0].geometry.location.lng);
        setPm_latitude(response.data.results[0].geometry.location.lat);
        setTempo_status(true);
      })
      .catch((error) => {
        console.log(error);
        if (error) {
          catchResponse(error);
        }
      });
  };

  const save = async (e, metodo, status) => {
    let data, method;

    if (metodo) {
      method = "delete";
    } else {
      if (!pm_id) {
        method = "post";
      } else {
        method = "put";
      }
    }

    if (method === "put") {
      data = {
        pm_id: pm_id,
        pm_descricao: pm_descricao,
        pm_endereco: pm_endereco,
        pm_numero: pm_numero,
        pm_bairro: pm_bairro,
        pm_cep: pm_cep,
        pm_ativo: pm_ativo,
        pm_cidade: pm_cidade.value,
        pm_tipo: pm_tipo.value,
        pm_regional: pm_regional.value,
        pm_latitude: pm_latitude,
        pm_longitude: pm_longitude,
        cid_municipio: removeAcento(pm_cidade.label),
        cid_uf: cid_uf.value,
      };
      var url = "api/pontodemidias/" + pm_id;
    } else if (method === "post") {
      data = {
        pm_descricao: pm_descricao,
        pm_endereco: pm_endereco,
        pm_numero: pm_numero,
        pm_bairro: pm_bairro,
        pm_cep: pm_cep,
        pm_ativo: pm_ativo,
        pm_cidade: pm_cidade.value,
        pm_tipo: pm_tipo.value,
        pm_regional: pm_regional.value,
        pm_latitude: pm_latitude,
        pm_longitude: pm_longitude,
        cid_municipio: removeAcento(pm_cidade.label),
        cid_uf: cid_uf.value,
      };
      var url = "api/pontodemidias";
    } else if (method === "delete") {
      var url = "api/pontodemidias/" + idModal;
      data = {
        id: idModal,
      };
      setNomeModal("");
      setIdModal("");
    }
    // console.log(data);
    //  return false;
    api({
      method: method,
      url: url,
      data: data,
      headers: {
        Authorization: "bearer " + sessionStorage.getItem("token"),
      },
    })
      .then((response) => {
        handleSuccess(response.headers.statusmessage);   
        if(document.getElementById("btn_limpar")){
          document.getElementById("btn_limpar").click();
        } 
          getPontoDeMidia();
        
      })
      .catch((error) => {
        //  catchResponse();

        catchResponse(error.response.status);
        console.log(error);
      });
  };

  const limpar = () => {
    setPm_cidade([]);
    setCid_uf([]);
    setPm_tipo([]);

    setPm_id("");
    setPm_descricao("");
    setPm_endereco("");
    setPm_numero("");
    setPm_bairro("");
    setPm_cep("");
    setPm_ativo(1);
    setPm_latitude("");
    setPm_longitude("");

    setNomeModal("");
    setIdModal("");
  };
  const viacep = (value) => {
    if (value) {
      if (value.length == 10) {
        axios
          .get("https://viacep.com.br/ws/" + mask_Numeros(value) + "/json/")
          .then((response) => {
            if (response.data.ibge) {
              // setStatusBar_cidade(arrayCidade);
              setPm_bairro(removeAcento(response.data.bairro));
              setPm_endereco(removeAcento(response.data.logradouro));
              setCid_uf({
                value: response.data.uf,
                label: removeAcento(response.data.uf),
              });
              setPm_cidade({
                value: response.data.ibge,
                label: removeAcento(response.data.localidade),
              });
            }
          })
          .catch((error) => {
            if (error.response) {
              catchResponse(error.response.status);
            }
          });
      }
    }
    return value;
  };
  const buscar = async () => {
    var filtrado;
    if (arrayFiltrado) {
      if (!busca) {
        filtrado = pontoDeMidia;
      } else {
        filtrado = arrayFiltrado.filter(function (obj) {
          return ilikeToRegExp("%" + busca + "%").test(obj.pm_descricao);
        });
      }
      var inicio = 0 * rowsPerPage + 1;
      var fim = inicio + rowsPerPage - 1;
      if(!filtrado.length){
        filtrado = pontoDeMidia;
handleInfo('Nenhum registro encontrado.');
      }
      if (fim > filtrado.length) {
        fim = filtrado.length;
      }
      const cidadePag = [];
      for (let i = inicio - 1; i < fim; i++) {
        cidadePag.push(filtrado[i]);
      }

      await setPontoDeMidiaPag(cidadePag);
      await setTotalPages(filtrado.length);
      await setArrayFiltrado(filtrado);
      await setPage(0);
    } else {
      handleInfo("Nenhum registro encontrado");
    }
  };
  return (
    <>
      <div className="container-fluid">
        <div
          class="modal fade bd-example-modal-xl"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-xl">
            <div class="modal-content">
              <div className="card">
                <div className="card-header"></div>
                <span className="span-card-titulo">
                  <img src={img_usuario} className="img-card" alt="logo" />
                  Cadastro de Ponto de Mídia
                </span>
                <div id="triangulo-para-baixo"></div>
                <div className="card-body">
                  <form id="form_cliente" onSubmit={(e) => GetGeoLocation(e)}>
                    <div className="form-row mb-3">
                      <div className="col-md-4 mb-3">
                        <label htmlFor="pm_descricao">Nome do Ponto:<strong className='fild-required'>*</strong></label>
                        <input
                          type="text"
                          maxLength="100"
                          className="form-control"
                          id="pm_descricao"
                          placeholder="Digite o Nome"
                          onChange={(e) =>
                            setPm_descricao(e.target.value.toUpperCase())
                          }
                          value={pm_descricao}
                          required
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label htmlFor="pm_tipo">Tipo:<strong className='fild-required'>*</strong></label>
                        <Select
                          id="pm_tipo"
                          value={pm_tipo}
                          onChange={handleChangePm_tipo}
                          options={statusPm_tipo}
                        />
                        <input
                          style={{
                            top: "43px",
                            position: "absolute",
                            zIndex: "-1",
                          }}
                          type="password"
                          id="pm_tipo_html5_validate"
                          autoComplete="new-password"
                          value={pm_tipo}
                          required
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label htmlFor="pm_cep">CEP:<strong className='fild-required'>*</strong></label>
                        <input
                          type="text"
                          className="form-control"
                          id="pm_cep"
                          maxLength="14"
                          placeholder="Digite o CEP"
                          autoComplete="new-password"
                          onChange={(e) =>
                            setPm_cep(viacep(mask_CEP(e.target.value)))
                          }
                          value={pm_cep}
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label htmlFor="pm_endereco">Endereço:<strong className='fild-required'>*</strong></label>
                        <input
                          type="text"
                          className="form-control"
                          id="pm_endereco"
                          placeholder="Digite seu Endereço"
                          maxLength="150"
                          autoComplete="new-password"
                          onChange={(e) =>
                            setPm_endereco(e.target.value.toUpperCase())
                          }
                          value={pm_endereco}
                          required
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label htmlFor="pm_numero">Número:</label>
                        <input
                          type="text"
                          className="form-control"
                          id="pm_numero"
                          placeholder="Número"
                          maxLength="20"
                          autoComplete="new-password"
                          onChange={(e) => setPm_numero(e.target.value)}
                          value={pm_numero}
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label htmlFor="pm_bairro">Bairro:</label>
                        <input
                          type="text"
                          className="form-control"
                          id="pm_bairro"
                          placeholder="Digite o seu Bairro"
                          maxLength="80"
                          autoComplete="new-password"
                          onChange={(e) =>
                            setPm_bairro(e.target.value.toUpperCase())
                          }
                          value={pm_bairro}
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label htmlFor="cid_uf">Estado / UF:<strong className='fild-required'>*</strong></label>
                        <Select
                          id="cid_uf"
                          value={cid_uf}
                          onChange={handleChangeUF}
                          options={statusCid_uf}
                        />
                        <input
                          style={{
                            top: "43px",
                            position: "absolute",
                            zIndex: "-1",
                          }}
                          type="password"
                          id="cid_uf_html5_validate"
                          autoComplete="new-password"
                          value={cid_uf}
                          required
                        />
                      </div>

                      <div className="col-md-4 mb-3">
                        <label htmlFor="pm_cidade">Cidade:<strong className='fild-required'>*</strong></label>
                        <Select
                          id="pm_cidade"
                          value={pm_cidade}
                          onChange={handleChange}
                          options={statusPm_cidade}
                        />
                        <input
                          style={{
                            top: "43px",
                            position: "absolute",
                            zIndex: "-1",
                            width: "5px",
                          }}
                          type="password"
                          id="pm_cidade_html5_validate"
                          autoComplete="new-password"
                          value={pm_cidade}
                          required
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label htmlFor="pm_regional">Regional:<strong className='fild-required'>*</strong></label>
                        <Select
                          id="pm_regional"
                          value={pm_regional}
                          onChange={handleChangeRegional}
                          options={statusPm_regional}
                        />
                        <input
                          style={{
                            top: "43px",
                            position: "absolute",
                            zIndex: "-1",
                            width: "5px",
                          }}
                          type="password"
                          id="pm_regional_html5_validate"
                          autoComplete="new-password"
                          value={pm_regional}
                          required
                        />
                      </div>
                      <div className="col-md-2 mb-3 mt-3 ml-2">
                        <label htmlFor="pm_ativo">
                          {pm_ativo == 1 ? (
                            <b className="text-success">Status: Ativo</b>
                          ) : (
                            <b className="text-secondary">Status: Inativo</b>
                          )}
                        </label>
                        <div class="material-switch   pull-right">
                          <input
                            onClick={(e) => checkboxAtivo()}
                            id="pm_ativo"
                            name="pm_ativo"
                            type="checkbox"
                          />
                          <label
                            htmlFor="pm_ativo"
                            class="badge-success"
                          ></label>
                        </div>
                      </div>
                    </div>

                    <div className="text-left ">
                      {pm_id ? (
                        <button
                          className="btn btn-sm btn-51comvc "
                          type="submit"
                          id="btnAlterar"
                        >
                          Alterar
                          <img
                            src={img_cadastrar}
                            className="img-btn"
                            alt="logo"
                          />
                        </button>
                      ) : (
                        <button
                          className="btn btn-sm btn-51comvc "
                          type="submit"
                          id="btnCadastrar"
                        >
                          Cadastrar
                          <img
                            src={img_cadastrar}
                            className="img-btn"
                            alt="logo"
                          />
                        </button>
                      )}{" "}
                      &nbsp;&nbsp;{" "}
                      <button
                        className="btn btn-sm btn-51comvc "
                        onClick={(e) => limpar(e)}
                        type="button"
                        id="btn_limpar"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        Fechar
                        <img src={img_excluir} className="img-btn" alt="logo" />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-header"></div>
          <span className="span-card-titulo">
            <img src={img_usuario} className="img-card" alt="logo" />
            Lista de Ponto de Mídia
          </span>
          <div id="triangulo-para-baixo"></div>
          <div className="card-body card-body-lista">
            <form>
              <div className="form-group">
                <div className="form-row">
                  <div className="col-md-5 col-12">
                    <div className="row">
                      <div
                        className="col-md-10  col-10 col-sm-10"
                        style={{ paddingRight: "0px" }}
                      >
                        <label htmlFor="filtro">Filtrar Tabela:</label>
                        <input
                          id="filtro"
                          className="form-control"
                          style={{ display: "inline" }}
                          type="text"
                          autoComplete="new-password"
                          placeholder="Termo..."
                          onChange={(e) =>
                            setBusca(removeAcento(e.target.value))
                          }
                          value={busca}
                        />
                      </div>
                      <div
                        className="col-md-1 col-1  col-sm-1"
                        style={{ paddingLeft: "0px" }}
                      >
                        {" "}
                        <label htmlFor="btn_visualizar">&nbsp;&nbsp;</label>
                        <button
                          style={{ display: "inline" }}
                          type="button"
                          className="btn  bg-51comvc bl-3"
                          id="btn_visualizar"
                          onClick={(e) => buscar(e)}
                        >
                          <img src={pesquisar} className="img-btn" alt="logo" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <label htmlFor="btn_Cadastrar">&nbsp;&nbsp;&nbsp;</label>
                    <br />
                    <button
                      type="button"
                      className="btn  bg-51comvc btn-block "
                      id="btn_Cadastrar"
                      onClick={(e) => limpar(e)}
                      data-toggle="modal"
                      data-target=".bd-example-modal-xl"
                    >
                      Cadastrar
                      <img src={img_cadastrar} className="img-btn" alt="logo" />
                    </button>
                  </div>
                  <div className="col-md-5"></div>
                </div>
              </div>
            </form>

            <div className=" table-responsive">
              <table className="table  table-hover table-striped table-light ">
                <thead className="text-white  bg-dark ">
                  <tr>
                    <th style={{ width: "85%" }} scope="col">
                      Nome
                    </th>

                    <th scope="col">Status</th>
                    <th style={{ width: "15%" }} scope="col">
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {pontoDeMidiaPag.length > 0
                    ? pontoDeMidiaPag.map(function (midias, i) {
                        return (
                          <>
                            <tr key={midias.pm_id}>
                              <th>
                                {midias.pm_descricao}
                                <br />
                                <i style={{ fontSize: "12px" }}>
                                  {"(" + midias.pmt_nome + ")"}
                                </i>
                              </th>

                              <th>
                                {midias.pm_ativo === 1 ? "Ativo" : "Inativo"}
                              </th>
                              <td>
                                <button
                                  type="button"
                                  onClick={(e) => editar(midias)}
                                  className="btn btn-sm "
                                  data-toggle="modal"
                                  data-target=".bd-example-modal-xl"
                                >
                                  <img
                                    src={img_alterar}
                                    className="img-btn-acao"
                                    alt="logo"
                                  />
                                </button>
                                <button
                                  type="button"
                                  data-toggle="modal"
                                  data-target="#exampleModalCenter"
                                  onClick={(e) =>
                                    dadosModal(
                                      midias.pm_descricao,
                                      midias.pm_id,
                                      midias.pm_ativo
                                    )
                                  }
                                  className="btn btn-sm  btn-excluir"
                                >
                                  <img
                                    src={img_excluir2}
                                    className="img-btn-acao"
                                    alt="logo"
                                  />
                                </button>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    : ""}
                </tbody>
              </table>
              <TablePagination
                component="div"
                count={totalPages}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={"Registros por página"}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModalCenter"
        tabIndex="1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="card">
              <div className="card-header"></div>
              <span className="span-card-titulo">
                <img src={img_usuario} className="img-card" alt="logo" />{" "}
                Excluir ponto de mídia
              </span>
              <div id="triangulo-para-baixo"></div>
              <div className="card-body">
                Tem certeza que deseja excluir o ponto de mídia
                <i>
                  <b className="text-danger">"</b>
                  {nomeModal}
                  <b className="text-danger">"</b>
                </i>{" "}
              </div>
              <div className="modal-footer-alert">
                <button
                  className="btn btn-sm btn-51comvc "
                  type="button"
                  data-dismiss="modal"
                >
                  {Times}
                  &nbsp;Cancelar
                </button>
                &nbsp;&nbsp;
                <button
                  className="btn btn-sm btn-51comvc "
                  onClick={(e) => save(e, "delete", 0)}
                  type="button"
                  data-dismiss="modal"
                >
                  Excluir
                  <img src={img_excluir} className="img-btn" alt="logo" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Clientes;
