import React, { useState, useEffect } from 'react';
import { Bar, Doughnut, Radar, Line, Pie } from 'react-chartjs-2';
import { TablePagination } from '@material-ui/core';
import Select from 'react-select';
import { api } from '../utils/WebApiConfig';
import { handleError, handleSuccess, handleInfo } from '../utils/Toastify';
import { useHistory } from 'react-router-dom';
import { Times, Eye } from '../utils/Fontawesome';
import { removeAcento, mask_data } from '../utils/Mascara';
import img_usuario from '../imagens/svg/img_usuario.png';
import img_cadastrar from '../imagens/svg/img_cadastrar.png';
import img_excluir from '../imagens/svg/img_excluir.png';
import img_alterar from '../imagens/svg/img_alterar.png';
import img_excluir2 from '../imagens/svg/img_excluir2.png';
import pesquisar from '../imagens/svg/img_lupa.png';
import { map } from 'jquery';
const DashBoard = () => {
  let history = useHistory();

  const [chart_Doughnut, setChart_Doughnut] = useState({
    labels: [],
    datasets: [
      {
        label: 'Rainfall',
        backgroundColor: [
          '#bdbdbd',
          '#f008ff',
          '#ff0018',
          '#0984e3',
          '#ffd32a',
          '#2FDE00',
          '#b04ff7',
          '#00A6B4',
        ],
        hoverBackgroundColor: [
          '#989898',
          '#c703d4',
          '#b90011',
          '#0764ad',
          '#dcaf00',
          '#175000',
          '#35014F',
          '#018792',
        ],
        borderWidth: 1,
        data: [65, 59, 80, 81, 56, 55, 1],
      },
    ],
  });
  const [chart_Bar, setChart_Bar] = useState({
    labels: [],
    datasets: [
      {
        borderWidth: 1,
        borderColor: '#000',
        label: 'Visitas',
        data: [],
        backgroundColor: [
          '#0984e3',
          '#0984e3',
          '#0984e3',
          '#0984e3',
          '#0984e3',
        ],
      },
      {
        label: 'Positivo',
        data: [10, 5, 18, 20, 3, 9],
        backgroundColor: '#2FDE00',
        borderColor: '#000',
      },
      {
        label: 'Negativo',
        data: [6, 2, 12, 6, 7, 8],
        backgroundColor: '#ff0000',
        borderColor: '#000',
      },
    ],
  });

  const [chart_Radar, setChart_Radar] = useState({
    labels: [
      'Eating',
      'Drinking',
      'Sleeping',
      'Designing',
      'Coding',
      'Cycling',
      'Running',
      'teste',
    ],
    datasets: [
      {
        label: 'My First dataset',
        backgroundColor: 'rgba(179,181,198,0.2)',
        borderColor: 'rgba(179,181,198,1)',
        pointBackgroundColor: 'rgba(179,181,198,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(179,181,198,1)',
        data: [65, 59, 90, 81, 56, 55, 40, 50],
      },
      {
        label: 'My Second dataset',
        backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: 'rgba(255,99,132,1)',
        pointBackgroundColor: 'rgba(255,99,132,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(255,99,132,1)',
        data: [28, 48, 40, 19, 96, 27, 100, 87],
      },
    ],
  });
  const [totalBars, setTotalBars] = useState([]);
  const [totalDistribuidoras, setTotalDistribuidoras] = useState([]);
  const [totalReginaisBars, setTotalReginaisBars] = useState(0);
  const [totalReginaisDistribuidoras, setTotalReginaisDistribuidoras] =
    useState(0);
  const [barChart_Line, setBarChart_Line] = useState([]);
  const [distribuidoraChart_Line, setDistribuidoraChart_Line] = useState([]);

  const [barSelect_Line, setBarSelect_Line] = useState({
    value: 7,
    label: '7 DIAS',
  });
  const [barRegional_Line, setBarRegional_Line] = useState({
    value: sessionStorage.getItem('regional'),
    label:
      sessionStorage.getItem('perfil') != 'G'
        ? sessionStorage.getItem('regional_nome')
        : 'TODOS',
  });

  const [distribuidoraSelect_Line, setDistribuidoraSelect_Line] = useState({
    value: 7,
    label: '7 DIAS',
  });
  const [distribuidoraRegional_Line, setDistribuidoraRegional_Line] = useState({
    value: sessionStorage.getItem('regional'),
    label:
      sessionStorage.getItem('perfil') != 'G'
        ? sessionStorage.getItem('regional_nome')
        : 'TODOS',
  });

  useEffect(() => {
    if (document.getElementsByClassName('modal-backdrop')[0]) {
      var no = document.getElementsByClassName('modal-backdrop')[0];
      if (no.parentNode) {
        no.parentNode.removeChild(no);
      }
    }
    if (!sessionStorage.getItem('refresh')) {
      sessionStorage.setItem('refresh', 1);
      window.location.reload(false);
    }
    getTotalDistribuidora();
    // getTotalBar();
  }, []);

  useEffect(() => {
    getBarChartLine(barSelect_Line.value, barRegional_Line.value);
  }, [barSelect_Line, barRegional_Line]);

  useEffect(() => {
    getDistribuidoraChartLine(
      distribuidoraSelect_Line.value,
      distribuidoraRegional_Line.value,
    );
  }, [distribuidoraSelect_Line, distribuidoraRegional_Line]);
  const Get_chart_Doughnut = (dias, regional) => {
    dias = dias ? dias : 7;

    api
      .get(
        'api/dashboards?intervalo=' +
          dias +
          '&regional=' +
          regional +
          '&tipo=bar',
        {
          headers: {
            Authorization: 'bearer ' + sessionStorage.getItem('token'),
          },
        },
      )
      .then((response) => {
        var labels = [];
        var data_pie = [];
        // construindo o corpo das options do select com as variaves vindas da api atraves do axios
        for (var i = 0; i < response.data.length; i++) {
          labels.push(response.data[i].di_data_cadastro);
          data_pie.push(response.data[i].total);
        }

        var chart_Doughnuts = chart_Doughnut;
        chart_Doughnuts.labels = labels;
        chart_Doughnuts.datasets[0].data = data_pie;
        console.log(data_pie);

        setChart_Doughnut({
          labels: labels,
          datasets: [
            {
              label: 'Rainfall',
              backgroundColor: [
                '#bdbdbd',
                '#f008ff',
                '#ff0018',
                '#0984e3',
                '#ffd32a',
                '#2FDE00',
                '#b04ff7',
                '#00A6B4',
              ],
              hoverBackgroundColor: [
                '#989898',
                '#c703d4',
                '#b90011',
                '#0764ad',
                '#dcaf00',
                '#175000',
                '#35014F',
                '#018792',
              ],
              borderWidth: 0,
              data: data_pie,
            },
          ],
        }); // salvando o novo state
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getBarChartLine = (dias, regional) => {
    //  dias = dias ? dias : 7;
    if (sessionStorage.getItem('perfil') === 'G') {
      regional = regional ? regional : 0;
    } else {
      regional = regional ? regional : sessionStorage.getItem('regional');
    }

    api
      .get(
        'api/dashboards?intervalo=' +
          dias +
          '&regional=' +
          regional +
          '&tipo=bar',
        {
          headers: {
            Authorization: 'bearer ' + sessionStorage.getItem('token'),
          },
        },
      )
      .then((response) => {
        let labels = [];
        let data_pie = [];
        let datasets = [];
        let regional = [];
        let backgroundColor = [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(0, 128, 0, 0.8)',
          'rgba(235, 54, 213, 0.8)',
          'rgba(201,186, 136, 1.2)',
        ];
        let borderColor = [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(0, 128, 0, 0.5)',
          'rgba(235, 54, 213, 0.5)',
          'rgba(201,186, 136, 0.8)',
        ];
        // construindo o corpo das options do select com as variaves vindas da api atraves do axios

        // Agrupe
        const groups = response.data.reduce((acc, current) => {
          if (!acc[current.di_regional]) {
            acc[current.di_regional] = { ...current };
            return acc;
          }
          acc[current.di_regional].total =
            acc[current.di_regional].total + current.total;
          return acc;
        }, {});

        // Extraia a lista
        const newList = Object.values(groups);
        //   console.log(newList);
        setTotalBars(newList);
        var totalBares = 0;
        for (let i = 0; i < newList.length; i++) {
          totalBares += newList[i].total;
          console.log(newList[i].total);
          console.log(totalBares);
        }

        setTotalReginaisBars(totalBares);

        for (let i = 0; i < response.data.length; i++) {
          if (regional.indexOf(response.data[i].di_regional) === -1) {
            regional.push(response.data[i].di_regional);
          }
          if (
            labels.indexOf(mask_data(response.data[i].bar_data_cadastro)) === -1
          ) {
            labels.push(mask_data(response.data[i].bar_data_cadastro));
          }
        }

        let groupResult = window._.groupBy(response.data, function (value) {
          return value.di_regional;
        });

        regional.map((num, i) => {
          data_pie = Array.from({ length: labels.length }, (v) => 0);
          labels.map((num, j) => {
            if (j < groupResult[regional[i]].length) {
              let posicao = labels.indexOf(
                mask_data(groupResult[regional[i]][j].bar_data_cadastro),
              );
              if (posicao > -1) {
                data_pie[posicao] = groupResult[regional[i]][j].total;
              }
            }
            return num * 2;
          });
          return datasets.push({
            label: groupResult[regional[i]][0].reg_nome,
            fill: false,
            lineTension: 0.1,
            backgroundColor: backgroundColor[i],
            borderColor: borderColor[i],
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: borderColor[i],
            pointBackgroundColor: backgroundColor[i],
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: borderColor[i],
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 4,
            pointHitRadius: 10,
            data: data_pie,
          });
        });
        setBarChart_Line({
          labels: labels,
          datasets: datasets,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getDistribuidoraChartLine = (dias, regional) => {
    //  dias = dias ? dias : 7;

    if (sessionStorage.getItem('perfil') === 'G') {
      regional = regional ? regional : 0;
    } else {
      regional = regional ? regional : sessionStorage.getItem('regional');
    }

    api
      .get(
        'api/dashboards?intervalo=' +
          dias +
          '&regional=' +
          regional +
          '&tipo=distribuidora',
        {
          headers: {
            Authorization: 'bearer ' + sessionStorage.getItem('token'),
          },
        },
      )
      .then((response) => {
        let labels = [];
        let data_pie = [];
        let datasets = [];
        let regional = [];
        let backgroundColor = [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(0, 128, 0, 0.8)',
          'rgba(235, 54, 213, 0.8)',
          'rgba(201,186, 136, 1.2)',
        ];
        let borderColor = [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(0, 128, 0, 0.5)',
          'rgba(235, 54, 213, 0.5)',
          'rgba(201,186, 136, 0.8)',
        ];
        // construindo o corpo das options do select com as variaves vindas da api atraves do axios
        // Agrupe
        const groups = response.data.reduce((acc, current) => {
          if (!acc[current.di_regional]) {
            acc[current.di_regional] = { ...current };
            return acc;
          }
          acc[current.di_regional].total =
            acc[current.di_regional].total + current.total;
          return acc;
        }, {});

        // Extraia a lista
        const newList = Object.values(groups);
        //   console.log(newList);
        setTotalDistribuidoras(newList);

        let totalDistribuidoras = 0;
        for (let i = 0; i < newList.length; i++) {
          totalDistribuidoras += newList[i].total;
        }
        setTotalReginaisDistribuidoras(totalDistribuidoras);

        for (let i = 0; i < response.data.length; i++) {
          if (regional.indexOf(response.data[i].di_regional) === -1) {
            regional.push(response.data[i].di_regional);
          }
          if (
            labels.indexOf(mask_data(response.data[i].di_data_cadastro)) === -1
          ) {
            labels.push(mask_data(response.data[i].di_data_cadastro));
          }
        }

        let groupResult = window._.groupBy(response.data, function (value) {
          return value.di_regional;
        });

        regional.map((num, i) => {
          data_pie = Array.from({ length: labels.length }, (v) => 0);
          labels.map((num, j) => {
            if (j < groupResult[regional[i]].length) {
              let posicao = labels.indexOf(
                mask_data(groupResult[regional[i]][j].di_data_cadastro),
              );
              if (posicao > -1) {
                data_pie[posicao] = groupResult[regional[i]][j].total;
              }
            }
            return num * 2;
          });
          return datasets.push({
            label: groupResult[regional[i]][0].reg_nome,
            fill: false,
            lineTension: 0.1,
            backgroundColor: backgroundColor[i],
            borderColor: borderColor[i],
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: borderColor[i],
            pointBackgroundColor: backgroundColor[i],
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: borderColor[i],
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 4,
            pointHitRadius: 10,
            data: data_pie,
          });
        });

        setDistribuidoraChart_Line({
          labels: labels,
          datasets: datasets,
        }); // salvando o novo state
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getTotalBar = () => {
    var url = 'api/dashboards?tipo=bar&select=1';

    api
      .get(url, {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
        },
      })
      .then((response) => {
        setTotalBars(response.data);
        let totalBares = 0;
        for (let i = 0; i < response.data.length; i++) {
          totalBares += response.data[i].total;
        }
        setTotalReginaisBars(totalBares);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getTotalDistribuidora = () => {
    var url = 'api/dashboards?tipo=distribuidora&select=1';

    api
      .get(url, {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
        },
      })
      .then((response) => {
        setTotalDistribuidoras(response.data);
        let totalDistribuidoras = 0;
        for (let i = 0; i < response.data.length; i++) {
          totalDistribuidoras += response.data[i].total;
        }
        setTotalReginaisDistribuidoras(totalDistribuidoras);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const Get_chart_Bar = () => {
    if (localStorage.getItem('supervisor') > 0) {
      var url = 'sicoob/cv/soma/' + localStorage.getItem('agencia') + '/30';
    } else {
      var url = 'sicoob/cv/somager/' + localStorage.getItem('id') + '/30';
    }

    api
      .get(url, {
        auth: {
          username: localStorage.getItem('username'),
          password: localStorage.getItem('password'),
        },
      })
      .then((response) => {
        const labels = [];
        const data_bar = [];
        // construindo o corpo das options do select com as variaves vindas da api atraves do axios
        for (var i = 0; i < response.data.length; i++) {
          labels.push(response.data[i].nome);
          data_bar.push(response.data[i].visitas);
        }

        const chart_Bars = chart_Bar;
        chart_Bars.labels = labels;
        chart_Bars.datasets[0].data = data_bar;
        setChart_Bar(chart_Bars);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //console.log(chart_Doughnut);
  return (
    <>
      <div className="container-fluid ">
        {/* <div className="row">
          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
            <div className="card">
              <div className="card-header"></div>
              <span className="span-card-titulo">
                <img src={img_usuario} className="img-card" alt="logo" />
                Ultimas Vsitas
              </span>
              <div id="triangulo-para-baixo"></div>
              <div className="card-body ">
                <ul
                  className="nav nav-pills mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link bg-51comvc active"
                      id="pills-home-tab"
                      data-toggle="pill"
                      href="#pills-home"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Rosca
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link bg-51comvc"
                      id="pills-profile-tab"
                      data-toggle="pill"
                      href="#pills-profile"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Pizza
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <article className="canvas-container">
                      <Doughnut
                        data={chart_Doughnut}
                        options={{
                          title: {
                            display: true,
                            text: "Resultado das ultimas visitas",
                            fontSize: 20,
                          },
                          legend: {
                            display: true,
                            position: "bottom",
                          },
                          responsive: true,
                          maintainAspectRatio: false,
                        }}
                      />{" "}
                    </article>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >{chart_Doughnut ?
                    <article className="canvas-container">
                      <Pie
                        data={chart_Doughnut}
                        options={{
                          title: {
                            display: true,
                            text: "Resultado das ultimas visitas",
                            fontSize: 20,
                          },
                          legend: {
                            display: true,
                            position: "bottom",
                          },
                          responsive: true,
                          maintainAspectRatio: false,
                        }}
                      />{" "}
                    </article>
:""}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
            <div className="card">
              <div className="card-header"></div>
              <span className="span-card-titulo">
                <img src={img_usuario} className="img-card" alt="logo" />
                Total de visitas
              </span>
              <div id="triangulo-para-baixo"></div>
              <div className="card-body ">
                <article className="canvas-container">
                  <Bar
                    data={chart_Bar}
                    options={{
                      title: {
                        display: true,
                        text: "Total de visitas nos ultimos 30 dias",
                        fontSize: 20,
                      },
                      ticks: {
                        min: 0,
                      },
                      legend: {
                        display: true,
                        position: "bottom",
                      },
                      responsive: true,
                      maintainAspectRatio: false,
                    }}
                  />
                </article>
              </div>
            </div>
          </div>
                  </div>*/}
        <div className="row">
          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
            <div className="card">
              <div className="card-header"></div>
              <span className="span-card-titulo">
                <img src={img_usuario} className="img-card" alt="logo" />
                Distribuidoras
              </span>
              <div id="triangulo-para-baixo"></div>
              <div className="card-body ">
                <article className="canvas-container">
                  {/* <Radar
                    data={chart_Radar}
                    options={{
                      title: {
                        display: true,
                        text: "Resultado das ultimas visitas",
                        fontSize: 20,
                      },
                      legend: {
                        display: true,
                        position: "bottom",
                      },
                      responsive: true,
                      maintainAspectRatio: false,
                    }}
                  />*/}
                  <Line
                    data={distribuidoraChart_Line}
                    options={{
                      title: {
                        display: true,
                        text:
                          'Distribuidoras cadastradas durante ' +
                          distribuidoraSelect_Line.label,
                        fontSize: 20,
                      },
                      ticks: {
                        min: 0,
                      },
                      legend: {
                        display: true,
                        position: 'bottom',
                      },
                      responsive: true,
                      maintainAspectRatio: false,
                    }}
                  />
                </article>
                <ul class="nav nav-pills">
                  <li class="nav-item dropdown">
                    <a
                      class="nav-link dropdown-toggle"
                      data-toggle="dropdown"
                      href="#"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {distribuidoraSelect_Line.label}
                    </a>
                    <div class="dropdown-menu">
                      <span
                        class="dropdown-item"
                        onClick={(e) =>
                          setDistribuidoraSelect_Line({
                            value: 7,
                            label: '7 DIAS',
                          })
                        }
                      >
                        7 DIAS
                      </span>
                      <span
                        class="dropdown-item"
                        onClick={(e) =>
                          setDistribuidoraSelect_Line({
                            value: 14,
                            label: '14 DIAS',
                          })
                        }
                      >
                        14 DIAS
                      </span>
                      <span
                        class="dropdown-item"
                        onClick={(e) =>
                          setDistribuidoraSelect_Line({
                            value: 28,
                            label: '28 DIAS',
                          })
                        }
                      >
                        28 DIAS
                      </span>
                      <span
                        class="dropdown-item"
                        onClick={(e) =>
                          setDistribuidoraSelect_Line({
                            value: 90,
                            label: '90 DIAS',
                          })
                        }
                      >
                        90 DIAS
                      </span>
                      <span
                        class="dropdown-item"
                        onClick={(e) =>
                          setDistribuidoraSelect_Line({
                            value: 180,
                            label: '180 DIAS',
                          })
                        }
                      >
                        180 DIAS
                      </span>
                      <span
                        class="dropdown-item"
                        onClick={(e) =>
                          setDistribuidoraSelect_Line({
                            value: 0,
                            label: 'TODOS TEMPOS',
                          })
                        }
                      >
                        TODOS TEMPOS
                      </span>
                    </div>
                  </li>
                  <li class="nav-item dropdown">
                    <a
                      class="nav-link dropdown-toggle"
                      data-toggle="dropdown"
                      href="#"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {distribuidoraRegional_Line.label}
                    </a>
                    {sessionStorage.getItem('perfil') == 'G' ? (
                      <div class="dropdown-menu">
                        <span
                          class="dropdown-item"
                          onClick={(e) =>
                            setDistribuidoraRegional_Line({
                              value: 0,
                              label: 'TODOS',
                            })
                          }
                        >
                          TODOS
                        </span>
                        <span
                          class="dropdown-item"
                          onClick={(e) =>
                            setDistribuidoraRegional_Line({
                              value: 1,
                              label: 'SPC',
                            })
                          }
                        >
                          SPC
                        </span>
                        <span
                          class="dropdown-item"
                          onClick={(e) =>
                            setDistribuidoraRegional_Line({
                              value: 2,
                              label: 'SPI',
                            })
                          }
                        >
                          SPI
                        </span>
                        <span
                          class="dropdown-item"
                          onClick={(e) =>
                            setDistribuidoraRegional_Line({
                              value: 3,
                              label: 'SUL',
                            })
                          }
                        >
                          SUL
                        </span>
                        <span
                          class="dropdown-item"
                          onClick={(e) =>
                            setDistribuidoraRegional_Line({
                              value: 4,
                              label: 'LESTE',
                            })
                          }
                        >
                          LESTE
                        </span>
                        <span
                          class="dropdown-item"
                          onClick={(e) =>
                            setDistribuidoraRegional_Line({
                              value: 5,
                              label: 'CENTRO OESTE',
                            })
                          }
                        >
                          CENTRO OESTE
                        </span>
                        <span
                          class="dropdown-item"
                          onClick={(e) =>
                            setDistribuidoraRegional_Line({
                              value: 6,
                              label: 'NORDESTE',
                            })
                          }
                        >
                          NORDESTE
                        </span>
                        <span
                          class="dropdown-item"
                          onClick={(e) =>
                            setDistribuidoraRegional_Line({
                              value: 7,
                              label: 'NORTE',
                            })
                          }
                        >
                          NORTE
                        </span>
                      </div>
                    ) : (
                      <div class="dropdown-menu">
                        <span
                          class="dropdown-item"
                          onClick={(e) =>
                            setDistribuidoraRegional_Line({
                              value: sessionStorage.getItem('regional'),
                              label: sessionStorage.getItem('regional_nome'),
                            })
                          }
                        >
                          {sessionStorage.getItem('regional_nome')}
                        </span>
                      </div>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
            <div className="card">
              <div className="card-header"></div>
              <span className="span-card-titulo">
                <img src={img_usuario} className="img-card" alt="logo" />
                Bares
              </span>
              <div id="triangulo-para-baixo"></div>
              <div className="card-body ">
                <article className="canvas-container">
                  <Line
                    data={barChart_Line}
                    options={{
                      title: {
                        display: true,
                        text:
                          'Bares cadastrados durante ' + barSelect_Line.label,
                        fontSize: 20,
                      },
                      ticks: {
                        min: 0,
                      },
                      legend: {
                        display: true,
                        position: 'bottom',
                      },
                      responsive: true,
                      maintainAspectRatio: false,
                    }}
                  />{' '}
                </article>
                <ul class="nav nav-pills">
                  <li class="nav-item dropdown">
                    <a
                      class="nav-link dropdown-toggle"
                      data-toggle="dropdown"
                      href="#"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {barSelect_Line.label}
                    </a>
                    <div class="dropdown-menu">
                      <span
                        class="dropdown-item"
                        onClick={(e) =>
                          setBarSelect_Line({ value: 7, label: '7 DIAS' })
                        }
                      >
                        7 DIAS
                      </span>
                      <span
                        class="dropdown-item"
                        onClick={(e) =>
                          setBarSelect_Line({ value: 14, label: '14 DIAS' })
                        }
                      >
                        14 DIAS
                      </span>
                      <span
                        class="dropdown-item"
                        onClick={(e) =>
                          setBarSelect_Line({ value: 28, label: '28 DIAS' })
                        }
                      >
                        28 DIAS
                      </span>
                      <span
                        class="dropdown-item"
                        onClick={(e) =>
                          setBarSelect_Line({ value: 90, label: '90 DIAS' })
                        }
                      >
                        90 DIAS
                      </span>
                      <span
                        class="dropdown-item"
                        onClick={(e) =>
                          setBarSelect_Line({ value: 180, label: '180 DIAS' })
                        }
                      >
                        180 DIAS
                      </span>
                      <span
                        class="dropdown-item"
                        onClick={(e) =>
                          setBarSelect_Line({ value: 0, label: 'TODOS TEMPOS' })
                        }
                      >
                        TODOS TEMPOS
                      </span>
                    </div>
                  </li>
                  <li class="nav-item dropdown">
                    <a
                      class="nav-link dropdown-toggle"
                      data-toggle="dropdown"
                      href="#"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {barRegional_Line.label}
                    </a>
                    {sessionStorage.getItem('perfil') == 'G' ? (
                      <div class="dropdown-menu">
                        <span
                          class="dropdown-item"
                          onClick={(e) =>
                            setBarRegional_Line({ value: 0, label: 'TODOS' })
                          }
                        >
                          TODOS
                        </span>
                        <span
                          class="dropdown-item"
                          onClick={(e) =>
                            setBarRegional_Line({ value: 1, label: 'SPC' })
                          }
                        >
                          SPC
                        </span>
                        <span
                          class="dropdown-item"
                          onClick={(e) =>
                            setBarRegional_Line({ value: 2, label: 'SPI' })
                          }
                        >
                          SPI
                        </span>
                        <span
                          class="dropdown-item"
                          onClick={(e) =>
                            setBarRegional_Line({ value: 3, label: 'SUL' })
                          }
                        >
                          SUL
                        </span>
                        <span
                          class="dropdown-item"
                          onClick={(e) =>
                            setBarRegional_Line({ value: 4, label: 'LESTE' })
                          }
                        >
                          LESTE
                        </span>
                        <span
                          class="dropdown-item"
                          onClick={(e) =>
                            setBarRegional_Line({
                              value: 5,
                              label: 'CENTRO OESTE',
                            })
                          }
                        >
                          CENTRO OESTE
                        </span>
                        <span
                          class="dropdown-item"
                          onClick={(e) =>
                            setBarRegional_Line({ value: 6, label: 'NORDESTE' })
                          }
                        >
                          NORDESTE
                        </span>
                        <span
                          class="dropdown-item"
                          onClick={(e) =>
                            setBarRegional_Line({ value: 7, label: 'NORTE' })
                          }
                        >
                          NORTE
                        </span>
                      </div>
                    ) : (
                      <div class="dropdown-menu">
                        <span
                          class="dropdown-item"
                          onClick={(e) =>
                            setBarRegional_Line({
                              value: sessionStorage.getItem('regional'),
                              label: sessionStorage.getItem('regional_nome'),
                            })
                          }
                        >
                          {sessionStorage.getItem('regional_nome')}
                        </span>
                      </div>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
            <div className="card">
              <div className="card-header"></div>
              <span className="span-card-titulo">
                <img src={img_usuario} className="img-card" alt="logo" />
                Distribuidoras
              </span>
              <div id="triangulo-para-baixo"></div>
              <div className="card-body ">
                <div className="mb-3  text-center  font-weight-bold ">
                  {' '}
                  <span style={{ color: '#666666', fontSize: '20px' }}>
                    Distribuidoras cadastradas
                  </span>
                </div>
                <div className=" table-responsive">
                  <table className="table  table-hover table-striped table-light ">
                    <thead className="text-white  bg-dark ">
                      <tr>
                        <th style={{ width: '85%' }} scope="col">
                          Regional
                        </th>
                        <th style={{ width: '15%' }} scope="col">
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {totalDistribuidoras.length > 0
                        ? totalDistribuidoras.map(function (total, i) {
                            return (
                              <>
                                <tr key={total.di_regional}>
                                  <th>{total.reg_nome}</th>
                                  <th>{total.total}</th>
                                </tr>
                              </>
                            );
                          })
                        : ''}
                      <tr>
                        <th>Total de todas regiões</th>
                        <th>{totalReginaisDistribuidoras}</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
            <div className="card">
              <div className="card-header"></div>
              <span className="span-card-titulo">
                <img src={img_usuario} className="img-card" alt="logo" />
                Bares
              </span>
              <div id="triangulo-para-baixo"></div>
              <div className="card-body ">
                <div className="mb-3  text-center  font-weight-bold ">
                  {' '}
                  <span style={{ color: '#666666', fontSize: '20px' }}>
                    Bares cadastrados
                  </span>
                </div>
                <div className=" table-responsive">
                  <table className="table  table-hover table-striped table-light ">
                    <thead className="text-white  bg-dark ">
                      <tr>
                        <th style={{ width: '85%' }} scope="col">
                          Regional
                        </th>
                        <th style={{ width: '15%' }} scope="col">
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {totalBars.length > 0
                        ? totalBars.map(function (total, i) {
                            return (
                              <>
                                <tr key={total.di_regional}>
                                  <th>{total.reg_nome}</th>
                                  <th>{total.total}</th>
                                </tr>
                              </>
                            );
                          })
                        : ''}
                      <tr>
                        <th>Total de todas regiões</th>
                        <th>{totalReginaisBars}</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DashBoard;
