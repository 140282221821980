import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserEdit,
  faShoppingCart,
  faUser,
  faBars,
  faEye,
  faSearch,
  faSave,
  faSignOutAlt,
  faArrowAltCircleRight,
  faArrowAltCircleLeft,
  faShareAlt,
  faHandPointLeft,
  faTrash,
  faEnvelope,
  faEdit,
  faTimes,
  faExchangeAlt,
  faCarSide,
  faLock,
  faAngleDoubleLeft,
  faPlusCircle,
  faPlus,
  faFileExcel,
  faFileExport,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookSquare,
  faInstagram,
  faYoutube,
  faWhatsapp,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';

//free-solid-svg-icons -----------------------------------------------------------------------

export const FileExcel = <FontAwesomeIcon icon={faFileExcel} />;
export const FileExport = <FontAwesomeIcon icon={faFileExport} />;
export const PlusCircle = <FontAwesomeIcon icon={faPlusCircle} />;
export const Plus = <FontAwesomeIcon icon={faPlus} />;
export const AngleDoubleLeft = <FontAwesomeIcon icon={faAngleDoubleLeft} />;
export const User = <FontAwesomeIcon icon={faUser} />;
export const Lock = <FontAwesomeIcon icon={faLock} />;
export const Envelope = <FontAwesomeIcon icon={faEnvelope} />;
export const CarSide = <FontAwesomeIcon icon={faCarSide} />;
export const ExchangeAlt = <FontAwesomeIcon icon={faExchangeAlt} />;
export const Times = <FontAwesomeIcon icon={faTimes} />;
export const Edit = <FontAwesomeIcon icon={faEdit} />;
export const Trash = <FontAwesomeIcon icon={faTrash} />;
export const HandPointLeft = <FontAwesomeIcon icon={faHandPointLeft} />;
export const ShareAlt = <FontAwesomeIcon icon={faShareAlt} />;
export const ShoppingCart = <FontAwesomeIcon icon={faShoppingCart} />;
export const UserEdit = <FontAwesomeIcon icon={faUserEdit} />;
export const SignOutAlt = <FontAwesomeIcon icon={faSignOutAlt} />;
export const Save = <FontAwesomeIcon icon={faSave} />;
export const Search = <FontAwesomeIcon icon={faSearch} />;
export const Bars = <FontAwesomeIcon icon={faBars} />;
export const Eye = <FontAwesomeIcon icon={faEye} />;
export const ArrowAltCircleRight = (
  <FontAwesomeIcon icon={faArrowAltCircleRight} />
);
export const ArrowAltCircleLeft = (
  <FontAwesomeIcon icon={faArrowAltCircleLeft} />
);

// fim dos free-solid-svg-icons ----------------------------------------------------------------

//free-brands-svg-icons -----------------------------------------------------------------------
export const FacebookSquare = <FontAwesomeIcon icon={faFacebookSquare} />;
export const Instagram = <FontAwesomeIcon icon={faInstagram} />;
export const Youtube = <FontAwesomeIcon icon={faYoutube} />;
export const Whatsapp = <FontAwesomeIcon icon={faWhatsapp} />;
export const Twitter = <FontAwesomeIcon icon={faTwitter} />;

// fim dos free-brands-svg-icons ----------------------------------------------------------------
