import React, { useState, useEffect } from 'react';
import { api } from '../utils/WebApiConfig';
import { handleError, handleSuccess } from '../utils/Toastify';
import logo from '../imagens/logo51.png';
import wood from '../imagens/woods.jpg';
import { useHistory, useLocation } from 'react-router-dom';
import { User, Lock } from '../utils/Fontawesome';
import { version } from '../../../package.json';
import { faUssunnah } from '@fortawesome/free-brands-svg-icons';
const Login = () => {
  const [id, setId] = useState('');
  const [senha, setSenha] = useState('');
  const [usu_email, setUsu_email] = useState('');
  let history = useHistory();
  const location = useLocation();
  const esqueciSenha = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    let data = {
      usu_email: usu_email,
    };

    api({
      method: 'put',
      url: '/api/usuarios/alterarsenha/',
      data: data,
    })
      .then((response) => {
        handleSuccess('Link de alteração foi encaminhado para o seu email');
        document.getElementById('fecharmodal').click();
      })
      .catch((error) => {
        handleError(error);
      });
  };
  const logar = async (e) => {
    console.log(version);
    var forms = document.getElementsByClassName('needs-validation');
    var validation = Array.prototype.filter.call(forms, function (form) {
      if (e) {
        if (form.checkValidity() === false) {
        }
        form.classList.add('was-validated');
      }
    });
    e.preventDefault();
    e.stopPropagation();

    if (!id && !senha) {
      handleError('Preencha todos os campos');
    }

    let data = {
      username: id,
      password: senha,
    };

    api({
      method: 'post',
      url: 'api/tokens',
      data: data,
    })
      .then((response) => {
        sessionStorage.clear();

        if (response.data.ativo == 1) {
          sessionStorage.setItem('id', response.data.id);
          sessionStorage.setItem('nome', response.data.nome);
          sessionStorage.setItem('perfil', response.data.perfil);
          switch (response.data.perfil) {
            case 'P':
              sessionStorage.setItem('perfil_nome', 'Promotor');
              break;
            case 'S':
              sessionStorage.setItem('perfil_nome', 'Supervisor');
              break;
            case 'G':
              sessionStorage.setItem('perfil_nome', 'Administrador');
              break;
          }
          sessionStorage.setItem('regional_nome', response.data.reg_nome);
          sessionStorage.setItem('regional', response.data.regional);
          // sessionStorage.setItem("regional", 0);
          sessionStorage.setItem('token', response.data.access_token);

          // if (sessionStorage.getItem("token")) {
          if (response.data.perfil === 'P') {
            history.push('/bar');
          } else if (
            response.data.perfil === 'G' ||
            response.data.perfil === 'S'
          ) {
            history.push('/dashboard');
          }

          //  }
        } else {
          handleError('Email ou senha inválidos.');
        }
      })
      .catch((error) => {
        handleError('Email ou senha inválidos.');
      });
  };

  useEffect(() => {
    if (document.getElementsByClassName('modal-backdrop')[0]) {
      var no = document.getElementsByClassName('modal-backdrop')[0];
      if (no.parentNode) {
        no.parentNode.removeChild(no);
      }
    }
    var uri = location.pathname.split('/');
    if (uri[1] && uri[2]) {
      console.log(uri[1]); // email
      console.log(uri[2]); // codigo
      console.log(uri[3]); // alterarsenha
    }
  }, []);

  const spanLogo = {
    width: '50%',
    textalign: 'center',
    marginBottom: '30px',
    display: 'inline-block',
  };
  const spanBtnSubimit = {
    width: '50%',
    textalign: 'right',
    display: 'inline-block',
  };
  const imgLogo = {
    width: '150px',
    marginLeft: '-15px',
    border: '2px solid #fff',
    borderRadius: '100px',
  };

  return (
    <>
      <div className="main" style={{ backgroundImage: 'url(' + wood + ')' }}>
        <div className="container_login">
          <center>
            <div className="middle">
              <div id="login">
                <form onSubmit={(e) => logar(e)}>
                  <fieldset className="clearfix_login">
                    <span style={spanLogo}>
                      <img src={logo} alt="logo" style={imgLogo} />
                    </span>
                    <br />
                    <span
                      style={{
                        color: '#000',
                        fontWeight: 'bold',
                        marginBottom: '3px',
                      }}
                    >
                      {'Versão: ' + version}
                    </span>
                    <br /> <br />
                    <p>
                      <span className="icons">{User}</span>
                      <label htmlFor="email"></label>
                      <input
                        type="text"
                        id="email"
                        placeholder="Id"
                        required
                        onChange={(e) => setId(e.target.value.toLowerCase())}
                        value={id}
                      />
                    </p>
                    <p>
                      <span className="icons">{Lock}</span>
                      <label htmlFor="senha"></label>
                      <input
                        type="password"
                        id="senha"
                        placeholder="Senha"
                        required
                        onChange={(e) => setSenha(e.target.value)}
                        value={senha}
                      />{' '}
                    </p>
                    <div>
                      <span style={spanBtnSubimit}>
                        <input type="submit" value="Login" />
                      </span>
                    </div>
                  </fieldset>
                  <div className="clearfix_login"></div>
                </form>
                <p className="mt-3 text-dark">
                  <b
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                    style={{
                      color: '#000',
                      fontWeight: 'bold',
                      fontSize: '12px',
                    }}
                  >
                    Esqueceu a senha?
                  </b>
                </p>
              </div>
            </div>
          </center>
        </div>
      </div>
      <div
        class="modal fade"
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header bg-primary">
              <h5
                class="modal-title"
                style={{ color: '#fff' }}
                id="exampleModalLongTitle"
              >
                Esqueci minha senha
              </h5>
              <button
                type="button"
                class="close"
                id="fecharmodal"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span className="text-white" aria-hidden="true">
                  &times;
                </span>
              </button>
            </div>
            <div class="modal-body">
              {' '}
              <legend style={{ fontSize: '14px' }}>
                Insira o email atual de sua conta para a equipe do suporte possa
                lhe enviar o link de alteração de senha.
              </legend>
              <form>
                <div class="form-group row">
                  <label
                    for="usu_email"
                    style={{ color: '#7c7f82' }}
                    class="col-sm-2 col-form-label"
                  >
                    <b>Email:</b>
                  </label>
                  <div class="col-sm-9">
                    <input
                      type="email"
                      class="form-control"
                      id="usu_email"
                      value={usu_email}
                      placeholder="Email"
                      onChange={(e) => setUsu_email(e.target.value)}
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-2"> </div>
                  <div class="col-sm-8">
                    <button
                      type="button"
                      onClick={(e) => esqueciSenha(e)}
                      class="btn btn-block btn-primary"
                    >
                      Enviar
                    </button>
                  </div>
                  <div class="col-sm-2"> </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
