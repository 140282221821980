import React, { useState, useEffect } from "react";
import { TablePagination } from "@material-ui/core";
import { api } from "../../utils/WebApiConfig";
import { handleError, handleSuccess, handleInfo } from "../../utils/Toastify";


import { useHistory,Link } from "react-router-dom";
import {
  mask_Qtd,
  mask_Numeros,
  ilikeToRegExp,
} from "../../utils/Mascara";
import {  Times,AngleDoubleLeft,Plus } from "../../utils/Fontawesome";
import img_usuario from "../../imagens/svg/img_usuario.png";
import img_cadastrar from "../../imagens/svg/img_cadastrar.png";
import pesquisar from "../../imagens/svg/img_lupa.png";

const Clientes = () => {
  let history = useHistory();

  const [bares, setBares] = useState([]);
  const [modal, setModal] = useState("");

  // const [av_ano, setAv_ano] = useState([]);
  //const [statusAv_ano, setStatusAv_ano] = useState([]);

  const [av_ano, setAv_ano] = useState(new Date().getFullYear());

  const [bar_id, setBar_id] = useState("");
  const [av_id, setAv_id] = useState("");
  const [bar_nomebar, setBar_nomebar] = useState("");

  const [av_produto, setAv_produto] = useState([]);
  const [statusAv_produto, setStatusAv_produto] = useState([]);
  const [av_bar, setAv_bar] = useState([]);
  const [statusAv_bar, setStatusAv_bar] = useState([]);




  const [av_qtdecompra, setAv_qtdecompra] = useState("");
  const [av_precocompra, setAv_precocompra] = useState("");
  const [av_qtdevenda, setAv_qtdevenda] = useState("");
  const [av_precovenda, setAv_precovenda] = useState("");
  const [av_mes, setAv_mes] = useState();
  const [av_precodose, setAv_precodose] = useState();

  const [acompanhamentovenda, setAcompanhamentovenda] = useState("");
  const [nomeModal, setNomeModal] = useState("");
  const [idModal, setIdModal] = useState("");

  // variaveis da paginaçãp
  const [arrayFiltrado, setArrayFiltrado] = useState("");
  const [busca, setBusca] = useState("");
  const [acompanhamentovendaPag, setAcompanhamentovendaPag] = useState([]);
  const [onloadStatus, setOnloadStatus] = useState(0);
  const [lastID, setLastID] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = async (event, newPage) => {
    await setPage(newPage);

    var inicio = newPage * rowsPerPage + 1;
    var fim = inicio + (rowsPerPage - 1);
    /*  console.log(
      "rowsPerPage:" +
        rowsPerPage +
        " newPage " +
        newPage +
        " " +
        inicio +
        " ---- " +
        fim
    );*/
    if (fim > arrayFiltrado.length) {
      fim = arrayFiltrado.length;
    }
    const usuariosPag = [];
    for (let i = inicio - 1; i < fim; i++) {
      usuariosPag.push(arrayFiltrado[i]);
    }
    setAcompanhamentovendaPag(usuariosPag);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

    var inicio = 0 * event.target.value + 1;
    var fim = inicio + (event.target.value - 1);
    if (fim > arrayFiltrado.length) {
      fim = arrayFiltrado.length;
    }
    const usuariosPag = [];
    for (let i = inicio - 1; i < fim; i++) {
      usuariosPag.push(arrayFiltrado[i]);
    }
    setAcompanhamentovendaPag(usuariosPag);
  };
  const dadosModal = (nome) => {
    setModal(nome);
  };
  useEffect(() => {
    if(document.getElementsByClassName('modal-backdrop')[0]){
      var no = document.getElementsByClassName("modal-backdrop")[0];
      if (no.parentNode) {
        no.parentNode.removeChild(no);
      }
    }
    var url_atual = window.location.href;
    url_atual = url_atual.split("/");
    setBar_nomebar(url_atual[url_atual.length - 1].replace(/-/gi, " "));
    setBar_id(url_atual[url_atual.length - 2]);

  
  }, []);

  const catchResponse = (status) => {
    if (status) {
      if (status === 401) {
        if(document.getElementById("btn_limpar")){
          document.getElementById("btn_limpar").click();
        } 
        handleInfo("Sessão expirada.");
        history.push("/login");
      }
    }
    handleError("Ocorreu um erro inesperado na execução desta operação");
  };
 
    const getAcompanhamentovenda = async (bar_id,av_ano,av_mes) => {
      
     
    await api
      .get("api/acompanhamentovendas?bar=" + bar_id+"&ano="+av_ano+"&mes="+av_mes+"&orderby=prod_nome", {
          headers: {
            Authorization: "bearer " + sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          limpar();
          setAcompanhamentovenda(response.data);
          setArrayFiltrado(response.data);
          setTotalPages(response.data.length);
          var inicio = 0;
          var fim = 10;
          if (fim > response.data.length) {
            fim = response.data.length;
          }
  
          const usuarioPag = [];
          for (let i = inicio; i < fim; i++) {
            usuarioPag.push(response.data[i]);
         
          }
          setAcompanhamentovendaPag(usuarioPag);
        
        })
        .catch((error) => {
          if (error.response) {
            catchResponse(error.response.status);
          }
        });
    };

  

    const save = (e, metodo,prod_id,av_id) => {
      e.preventDefault();
      e.stopPropagation();
      let data, method;
      if (metodo) {
        method = "delete";
      } else {
        if (!av_id) {
          method = "post";
        } else {
          method = "put";
        }
      }

 
 let av_qtdecompra = document.getElementById('av_qtdecompra'+prod_id).value;
 let av_precocompra = document.getElementById('av_precocompra'+prod_id).value;
 let av_qtdevenda = document.getElementById('av_qtdevenda'+prod_id).value;
 let av_precovenda = document.getElementById('av_precovenda'+prod_id).value;
 let av_precodose = document.getElementById('av_precodose'+prod_id).value;
 console.log('original '+av_precodose);
 console.log('limpar_Moeda '+limpar_Moeda(av_precodose));
 var url = '';
      if (method === "put") {
        data = {
          av_id: av_id,
          av_produto: prod_id,
          av_bar: bar_id,
          av_qtdecompra:  mask_Numeros(av_qtdecompra),
          av_precocompra: limpar_Moeda(av_precocompra),
          av_qtdevenda: mask_Numeros(av_qtdevenda),
          av_precovenda: limpar_Moeda(av_precovenda),
          av_precodose: limpar_Moeda(av_precodose),
          av_mes: av_mes,
          av_ano: av_ano,
        };
         url = "api/acompanhamentovendas/" + av_id;
      } else if (method === "post") {
        data = {
          av_produto: prod_id,
          av_bar: bar_id,
          av_qtdecompra:  mask_Numeros(av_qtdecompra),
          av_precocompra: limpar_Moeda(av_precocompra),
          av_qtdevenda: mask_Numeros(av_qtdevenda),
          av_precovenda: limpar_Moeda(av_precovenda),
          av_precodose: limpar_Moeda(av_precodose),
          av_mes: av_mes,
          av_ano: av_ano,
        };
         url = "api/acompanhamentovendas" ;
      } else if (method === "delete") {
         url = "api/acompanhamentovendas/" + idModal;
        data = {
          id: idModal,
        };
        setNomeModal("");
        setIdModal("");
      }
    // console.log(data);  return false
      api({
        method: method,
        url: url,
        data: data,
        headers: {
          Authorization: "bearer " + sessionStorage.getItem("token"),
        },
      })
        .then((response) => {
       
          handleSuccess(response.headers.statusmessage);   
          getAcompanhamentovenda(bar_id,av_ano,av_mes);
       
        })
        .catch((error) => {       
  
          catchResponse(error.response.status);
          console.log(error);
        });
    };
  const btnAdicionar = (av_ano) => {
    setAv_ano(av_ano + 1);
  };
  const btnRemover = (av_ano) => {
    setAv_ano(av_ano - 1);
  };
  const changeMeses = (av_mes) => {
    setAv_mes(av_mes);
    limpar();
  };
  const limpar = (campos) => {
if(!campos){
  setAcompanhamentovendaPag([]);
}
 
    setAv_qtdecompra('');
    setAv_precocompra('');
    setAv_qtdevenda('');
    setAv_precovenda('');
    setAv_precodose('');
  };
 
  const buscar = async () => {
    let filtrado;
    if (arrayFiltrado) {
      if (!busca) {
        filtrado = acompanhamentovenda;
      } else {
        filtrado = arrayFiltrado.filter(function (obj) {
          return ilikeToRegExp("%" + busca + "%").test(obj.prod_nome);
        });
      }
      var inicio = 0 * rowsPerPage + 1;
      var fim = inicio + rowsPerPage - 1;
      if(!filtrado.length){
        filtrado = acompanhamentovenda;
handleInfo('Nenhum registro encontrado.');
      }
      if (fim > filtrado.length) {
        fim = filtrado.length;
      }
      const usuariosPag = [];
      for (let i = inicio - 1; i < fim; i++) {
        usuariosPag.push(filtrado[i]);
      }

      await setAcompanhamentovendaPag(usuariosPag);
      await setTotalPages(filtrado.length);
      await setArrayFiltrado(filtrado);
      await setPage(0);
    } else {
      handleInfo("Nenhum registro encontrado");
    }
  };

 const mask_Moeda = (z) => {
    let v = String(z);
    v = v.replace(/\D/g, ""); //permite digitar apenas números
    v = v.replace(/[0-9]{12}/, "inválido"); //limita pra máximo 999.999.999,99
    v = v.replace(/(\d{1})(\d{8})$/, "$1.$2"); //coloca ponto antes dos últimos 8 digitos
    v = v.replace(/(\d{1})(\d{5})$/, "$1.$2"); //coloca ponto antes dos últimos 5 digitos
    v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2"); //coloca virgula antes dos últimos 2 digitos
    console.log(v);
    return v;
   
  };
  const limpar_Moeda = (v) => {
    if (v) {      
      v = v.split(".").join("");
      v = v.replace(",", ".");
      console.log('coloca ponto '+v);
      if (v.indexOf(".") === -1) {
        return v+".00";
      } else {
       var parte = v.split(".");
        let tamanho = parte[1].length;
if(tamanho === 2){
return  v
}else if (tamanho === 1){
  return  parte[0]+'.'+parte[1]+'0';
}
      
      }
    }
  };
  const meses = [
    { text: "JAN", id: 1 },
    { text: "FEV", id: 2 },
    { text: "MAR", id: 3 },
    { text: "ABR", id: 4 },
    { text: "MAI", id: 5 },
    { text: "JUN", id: 6 },
    { text: "JUL", id: 7 },
    { text: "AGO", id: 8 },
    { text: "SET", id: 9 },   
    { text: "OUT", id: 10 },
    { text: "NOV", id: 11 },
    { text: "DEZ", id: 12 },
  ];

  const styleCardHeader = {
    padding: " 0px",
    marginBottom: "0",
    borderBottom: "0px solid rgba(0, 0, 0, 0.125)",
    color: "#fff",
    textAlign: "left",
    borderTop: "4px solid #c9ba88",
  };

  return (

    <>
      <div className="container-fluid">
        <div className="card">
          <div className="card-header"></div>
          <span className="span-card-titulo">
            <img src={img_usuario} className="img-card" alt="logo" />
            {bar_nomebar}
          </span>

          <div id="triangulo-para-baixo"></div>

          <div className="card-body card-body-lista">
            <div className="form-group">
              <div className="form-row">
                <div
                  className="col-md-2 mb-3 mt-3"
                  style={{ paddingRight: "0px" }}
                >
                  <div className="row">
                    <div
                      className="col-md-3 col-2 "
                      style={{ paddingRight: "0px", textAlign: "right" }}
                    >
                      <button
                        type="button"
                        style={{
                          height: "38px",
                          width: "38px",
                          textAlign: "center",
                          borderRadius: ".25rem",
                        }}
                        onClick={(e) => btnRemover(av_ano)}
                        className="btn  btn-sm bg-51comvc  font-weight-bold"
                        data-dismiss="modal"
                      >
                        -
                      </button>
                    </div>
                    <div
                      className="col-md-6 col-8"
                      style={{ paddingLeft: "0px", paddingRight: "0px" }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        id="av_ano"
                        style={{ textAlign: "center" }}
                        maxLength="4"
                        autoComplete="new-password"
                        onChange={(e) =>
                          setAv_ano(mask_Numeros(e.target.value))
                        }
                        value={av_ano}
                        required
                      />
                    </div>{" "}
                    <div
                      className="col-md-3 col-2 "
                      style={{ paddingLeft: "0px" }}
                    >
                      <button
                        type="button"
                        style={{
                          height: "38px",
                          width: "38px",
                          textAlign: "center",
                          borderRadius: ".25rem",
                        }}
                        onClick={(e) => btnAdicionar(av_ano)}
                        className="btn btn-sm bg-51comvc  font-weight-bold"
                        data-dismiss="modal"
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-10 mb-3">
                  <ul
                    class="nav nav-pills mb-3 mt-3"
                    id="pills-tab"
                    role="tablist"
                  >
                    {meses.map((item, i) => {
                      return (
                        <>
                          {" "}
                          <li class="nav-item"  key={item.id}  >
                            <a
                              class={
                                item.text === "JAN"
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              id={'pills-profile-tab'+item.id}
                              onClick={(e) => changeMeses(item.id)}
                              data-toggle="pill"
                              href="#pills-profile"
                              role="tab"
                              aria-controls="pills-profile"
                              aria-selected="false"
                            >
                              {" "}
                              {item.text}
                            </a>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </div>
                  </div>  <div id="pesquisar-voltar" className="d-flex justify-content-between">
                 
                 <button
                  
                   type="button"
                   className="btn  bg-51comvc "
                   id="btn_visualizar"
                   onClick={(e) => getAcompanhamentovenda(bar_id,av_ano,av_mes)}
                 >
                   Pesquisar
                   <img src={pesquisar} className="img-btn" alt="logo" />
                 </button>
                <Link to={`/acompanhamentos`}>
                <button
                
                type="button"
                className="btn  bg-51comvc "
                id="btn_visualizar"
              
              >
              {AngleDoubleLeft}  Voltar
              
              </button>
                            </Link>
               
               
               </div>
         
            </div>
            <div>
              <div class="accordion" id="accordionExample">
             {acompanhamentovendaPag.length > 0 ?
             
     
             acompanhamentovendaPag.map((prod, i) => {
           
                        
            return(
<>
         
                <div class="card" style={{border: '3px solid #fff'}} key={prod.prod_id}>
                  <div
                    class="card-header"
                    style={styleCardHeader}
                    id={'heading'+prod.prod_id}
                  >
                    <h5 class="mb-0 "  style={{background:'#c9ba87'}}>
                      <button
                        class="btn btn-link collapsed text-white d-flex justify-content-between"
                        type="button"
                        style={{width:'100%'}}
                        data-toggle="collapse"
                        data-target={'#collapse'+prod.prod_id}
                        aria-expanded="false"
                        aria-controls={'#collapse'+prod.prod_id}
                        onClick={(e) =>  limpar('campos')  }
                      >
                      <span>{prod.prod_nome}</span> <span>{Plus}</span>
                      </button>
                    </h5>
                  </div>
                  <div
                    id={'collapse'+prod.prod_id}
                    class="collapse"
                    aria-labelledby={'heading'+prod.prod_id}
                    data-parent="#accordionExample"
                  >
                    <div class="card-body">
                    <form  onSubmit={(e) => save(e,'',prod.prod_id,prod.av_id)}>
                        <div className="form-row mb-3">
                          <div className="col-md-2 mb-3">
                            <label htmlFor={'av_qtdecompra'+prod.prod_id}>
                             Qtde compra
                              <b style={{ fontSize: "11px" }}> (Unit)</b><strong className='fild-required'>*</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id={'av_qtdecompra'+prod.prod_id}
                              placeholder="Qtd. de compra"
                              style={{ width: "100%" }}
                              maxLength="12"
                              autoComplete="new-password"
                              onChange={(e) =>
                                setAv_qtdecompra(mask_Qtd(e.target.value))
                              }
                              value={ av_qtdecompra  ? av_qtdecompra :mask_Qtd(prod.av_qtdecompra)}
                              required
                            />
                          </div>
                          <div className="col-md-2 mb-3">
                            <label htmlFor={'av_precocompra'+prod.prod_id}>
                              Preço de compra:
                              <b style={{ fontSize: "11px" }}> (Unit)</b><strong className='fild-required'>*</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id={'av_precocompra'+prod.prod_id}
                              placeholder="Preço de compra"
                              maxLength="12"
                              autoComplete="new-password"
                              onChange={(e) =>
                                setAv_precocompra(mask_Moeda(e.target.value))
                              }
                           
                              value={av_precocompra ? av_precocompra : mask_Moeda(prod.av_precocompra)}
                              required
                            />
                          </div>

                          <div className="col-md-2 mb-3">
                            <label htmlFor={'av_qtdevenda'+prod.prod_id}>
                            Qtde de venda:
                              <b style={{ fontSize: "11px" }}> (Unit)</b><strong className='fild-required'>*</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id={'av_qtdevenda'+prod.prod_id}
                              placeholder="Qtd. de venda"
                              maxLength="12"
                              autoComplete="new-password"
                              onChange={(e) =>
                                setAv_qtdevenda(mask_Qtd(e.target.value))
                              }
                              value={av_qtdevenda ? av_qtdevenda : mask_Qtd(prod.av_qtdevenda) }
                              required
                            />
                          </div>
                          <div className="col-md-2 mb-3">
                            <label htmlFor={'av_precovenda'+prod.prod_id}>
                              Preço de venda:
                              <b style={{ fontSize: "11px" }}> (Unit)</b><strong className='fild-required'>*</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id={'av_precovenda'+prod.prod_id}
                              placeholder="Preço de venda"
                              maxLength="12"
                              autoComplete="new-password"
                              onChange={(e) =>
                                setAv_precovenda(mask_Moeda(e.target.value))
                              }
                              value={av_precovenda ? av_precovenda : mask_Moeda(prod.av_precovenda)  }
                              required
                            />
                          </div>
                          <div className="col-md-2 mb-3">
                            <label htmlFor={'av_precodose'+prod.prod_id}>
                              Preço de dose:
                              <b style={{ fontSize: "11px" }}> (Dose)</b><strong className='fild-required'>*</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id={'av_precodose'+prod.prod_id}
                              placeholder="Preço da dose"
                              maxLength="12"
                              autoComplete="new-password"
                              onChange={(e) =>
                                setAv_precodose(mask_Moeda(e.target.value))
                              }
                              value={av_precodose ? av_precodose : mask_Moeda(prod.av_precodose) }
                              required
                            />
                          </div>
                        </div>
                        <div className="text-left ">
               
                       
                          {prod.av_id ? (
                            <button
                              className="btn btn-sm btn-51comvc "
                              type="submit"                           
                            >
                              Alterar
                              <img
                                src={img_cadastrar}
                                className="img-btn"
                                alt="logo"
                              />
                            </button>
                          ) : (
                            <button
                              className="btn btn-sm btn-51comvc "
                              type="submit"
                            
                            >
                              Cadastrar
                              <img
                                src={img_cadastrar}
                                className="img-btn"
                                alt="logo"
                              />
                            </button>
                          )}
                                
                        </div>
                      </form>
              
                    </div>
                  </div>
                </div>
            </>  ) })
              :""    }
             
              </div>
           { /*  <TablePagination
                component="div"
                count={totalPages}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={"Registros por página"}
              />*/}
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModalCenter"
        tabIndex="1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="card">
              <div className="card-header"></div>
              <span className="span-card-titulo">
                <img src={img_usuario} className="img-card" alt="logo" />{" "}
                {modal.bar_acompanhamentovendas !== 1
                  ? "Ativar acompanhamento "
                  : "Inativar acompanhamento "}
              </span>
              <div id="triangulo-para-baixo"></div>
              <div className="card-body">
                {modal.bar_acompanhamentovendas !== 1
                  ? "Tem certeza que deseja ativar o acompanhamento "
                  : "Tem certeza que deseja inativar o acompanhamento "}
                <i>
                  <b className="text-danger">"</b>
                  {modal.bar_nomebar}
                  <b className="text-danger">"</b>
                </i>{" "}
              </div>
              <div className="modal-footer-alert">
                <button
                  className="btn btn-sm btn-51comvc "
                  type="button"
                  data-dismiss="modal"
                >
                  {Times}
                  &nbsp;Cancelar
                </button>
                &nbsp;&nbsp;
                {modal.bar_acompanhamentovendas !== 1 ? (
                  <button
                    type="button"
                    onClick={(e) => save(modal)}
                    style={{ width: "102px", height: "35px" }}
                    className="btn btn-status btn-sm bg-51comvc  font-weight-bold"
                    data-dismiss="modal"
                  >
                    Ativar
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={(e) => save(modal)}
                    className="btn btn-sm btn-51comvc"
                    data-dismiss="modal"
                    style={{
                      background: "#c98888",
                      width: "102px",
                      height: "35px",
                    }}
                  >
                    Inativar
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Clientes;
