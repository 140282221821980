import React, { useState, useEffect } from "react";
import { TablePagination } from "@material-ui/core";
import { api } from "../../utils/WebApiConfig";
import { handleError, handleSuccess, handleInfo } from "../../utils/Toastify";
import { useHistory } from "react-router-dom";
import { ilikeToRegExp, removeAcento } from "../../utils/Mascara";
import { Trash, Save, Edit, Times } from "../../utils/Fontawesome";
import img_usuario from "../../imagens/svg/img_usuario.png";
import img_cadastrar from "../../imagens/svg/img_cadastrar.png";
import img_excluir from "../../imagens/svg/img_excluir.png";
import img_alterar from "../../imagens/svg/img_alterar.png";
import img_excluir2 from "../../imagens/svg/img_excluir2.png";
import pesquisar from "../../imagens/svg/img_lupa.png";
const Clientes = () => {
  let history = useHistory();


  const [bares, setBares] = useState([]);
  const [modal, setModal] = useState("");

  // variaveis da paginaçãp
  const [arrayFiltrado, setArrayFiltrado] = useState("");
  const [busca, setBusca] = useState("");
  const [baresPag, setBaresPag] = useState([]);
  const [onloadStatus, setOnloadStatus] = useState(0);
  const [lastID, setLastID] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = async (event, newPage) => {
    await setPage(newPage);

    var inicio = newPage * rowsPerPage + 1;
    var fim = inicio + (rowsPerPage - 1);
    /*  console.log(
       "rowsPerPage:" +
         rowsPerPage +
         " newPage " +
         newPage +
         " " +
         inicio +
         " ---- " +
         fim
     );*/
    if (fim > arrayFiltrado.length) {
      fim = arrayFiltrado.length;
    }
    const usuariosPag = [];
    for (let i = inicio - 1; i < fim; i++) {
      usuariosPag.push(arrayFiltrado[i]);
    }
    setBaresPag(usuariosPag);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

    var inicio = 0 * event.target.value + 1;
    var fim = inicio + (event.target.value - 1);
    if (fim > arrayFiltrado.length) {
      fim = arrayFiltrado.length;
    }
    const usuariosPag = [];
    for (let i = inicio - 1; i < fim; i++) {
      usuariosPag.push(arrayFiltrado[i]);
    }
    setBaresPag(usuariosPag);
  };
  const dadosModal = (nome) => {
    setModal(nome);
  };
  useEffect(() => {
    if(document.getElementsByClassName('modal-backdrop')[0]){
      var no = document.getElementsByClassName("modal-backdrop")[0];
      if (no.parentNode) {
        no.parentNode.removeChild(no);
      }
    }
    getBar();
  }, []);

  const catchResponse = (status) => {
    if (status) {
      if (status === 401) {
        if(document.getElementById("btn_limpar")){
          document.getElementById("btn_limpar").click();
        } 
        handleInfo("Sessão expirada.");
        history.push("/login");
      }
    }
    handleError("Ocorreu um erro inesperado na execução desta operação");
  };

  const getBar = () => {
   

    api
      .get(
        "api/bares?regional=" +
          sessionStorage.getItem("regional") +
          "&status=1",
        {
          headers: {
            Authorization: "bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        setBares(response.data);
        setArrayFiltrado(response.data);
        setTotalPages(response.data.length);
        var inicio = 0;
        var fim = 10;
        if (fim > response.data.length) {
          fim = response.data.length;
        }

        const usuarioPag = [];
        for (let i = inicio; i < fim; i++) {
          usuarioPag.push(response.data[i]);
        }
        setBaresPag(usuarioPag);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };

  const save = (bar) => {
    let data, acompanhamento,url;
    if (bar.bar_acompanhamentovendas === 1) {
      acompanhamento = 0;
    } else {
      acompanhamento = 1;
    }

    data = {
      bar_id: bar.bar_id,
      bar_acompanhamentovendas: acompanhamento,
    };
    url  = 'api/bares/'+bar.bar_id;
    api({
      method: "put",
      url: url,
      data: data,
      headers: {
        Authorization: "bearer " + sessionStorage.getItem("token"),
      },
    })
      .then((response) => {
       
        handleSuccess(response.headers.statusmessage);  
          getBar();
         
      
      
      })
      .catch((error) => {
        //  catchResponse();

        catchResponse(error.response.status);
        console.log(error);
      });
  };
  const buscar = async () => {
    let filtrado;
    if (arrayFiltrado) {
      if (!busca) {
        filtrado = bares;
      } else {
        filtrado = arrayFiltrado.filter(function (obj) {
          return ilikeToRegExp("%" + busca + "%").test(obj.bar_nomebar);
        });
      }
      var inicio = 0 * rowsPerPage + 1;
      var fim = inicio + rowsPerPage - 1;
      if(!filtrado.length){
        filtrado = bares;
handleInfo('Nenhum registro encontrado.');
      }
      if (fim > filtrado.length) {
        fim = filtrado.length;
      }
      const usuariosPag = [];
      for (let i = inicio - 1; i < fim; i++) {
        usuariosPag.push(filtrado[i]);
      }

      await setBaresPag(usuariosPag);
      await setTotalPages(filtrado.length);
      await setArrayFiltrado(filtrado);
      await setPage(0);
    } else {
      handleInfo("Nenhum registro encontrado");
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="card">
          <div className="card-header"></div>
          <span className="span-card-titulo">
            <img src={img_usuario} className="img-card" alt="logo" />
            Indicação de bares
          </span>
          <div id="triangulo-para-baixo"></div>
          <div className="card-body card-body-lista">
            <form>
              <div className="form-group">
                <div className="form-row">
                  <div className="col-md-5 col-12">
                    <div className="row">
                      <div
                        className="col-md-10  col-10 col-sm-10"
                        style={{ paddingRight: "0px" }}
                      >
                        <label htmlFor="filtro">Filtrar Tabela:</label>
                        <input
                          id="filtro"
                          className="form-control"
                          type="text"
                          autoComplete="new-password"
                          placeholder="Termo..."
                          onChange={(e) =>
                            setBusca(removeAcento(e.target.value))
                          }
                          value={busca}
                        />
                      </div>
                      <div
                        className="col-md-1 col-1  col-sm-1"
                        style={{ paddingLeft: "0px" }}
                      >
                        {" "}
                        <label htmlFor="btn_visualizar">&nbsp;&nbsp;</label>
                        <button
                          style={{ display: "inline" }}
                          type="button"
                          className="btn  bg-51comvc bl-3"
                          id="btn_visualizar"
                          onClick={(e) => buscar(e)}
                        >
                          <img src={pesquisar} className="img-btn" alt="logo" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3"></div>
                  <div className="col-md-5"></div>
                </div>
              </div>
            </form>

            <div className=" table-responsive">
              <table
                id="table-to-xls"
                className="table  table-hover table-striped table-light "
              >
                <thead className="text-white  bg-dark ">
                  <tr>
                    <th style={{ width: "95%" }} scope="col">
                      Bar
                    </th>
                    {/* <th scope="col">Proprietario</th>
                    <th scope="col">Endereço</th>
                    <th scope="col">Cidade</th>*/}
                    <th
                      style={{ width: "5%", textAlign: "center" }}
                      scope="col"
                    >
                      Acompanhamento
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {baresPag.length > 0
                    ? baresPag.map(function (bar, i) {
                        return (
                          <>
                            <tr key={bar.bar_id}>
                              <th>
                                {bar.bar_nomebar}
                                <br />
                                <i style={{ fontSize: "12px" }}>
                                  {"Proprietário: " +bar.bar_dono_bar }
                                </i>
                                <br/>
                                <i style={{ fontSize: "12px" }}>
                                {"Promotor: " + bar.usu_nome }
                                </i>
                              </th>

                              {/* <th>{bar.bar_endereco}</th>
                              <th>{bar.cid_municipio + " / " + bar.cid_uf}</th>*/}
                              <th style={{ textAlign: "center" }}>
                                {bar.bar_acompanhamentovendas === 1 ? (
                                  <button
                                    type="button"
                                    onClick={(e) => dadosModal(bar)}
                                    className="btn btn-status btn-sm bg-51comvc  font-weight-bold"
                                    data-toggle="modal"
                                    data-target="#exampleModalCenter"
                                  >
                                    Ativo
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    onClick={(e) => dadosModal(bar)}
                                    className="btn btn-status btn-sm bg-red  font-weight-bold"
                                    data-toggle="modal"
                                    data-target="#exampleModalCenter"
                                  >
                                    Inativo
                                  </button>
                                )}
                              </th>
                            </tr>
                          </>
                        );
                      })
                    : ""}
                </tbody>
              </table>
              <TablePagination
                component="div"
                count={totalPages}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={"Registros por página"}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModalCenter"
        tabIndex="1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="card">
              <div className="card-header"></div>
              <span className="span-card-titulo">
                <img src={img_usuario} className="img-card" alt="logo" />{" "}
                {modal.bar_acompanhamentovendas !== 1
                  ? "Ativar acompanhamento "
                  : "Inativar acompanhamento "}
              </span>
              <div id="triangulo-para-baixo"></div>
              <div className="card-body">
                {modal.bar_acompanhamentovendas !== 1
                  ? "Tem certeza que deseja ativar o acompanhamento "
                  : "Tem certeza que deseja inativar o acompanhamento "}
                <i>
                  <b className="text-danger">"</b>
                  {modal.bar_nomebar}
                  <b className="text-danger">"</b>
                </i>{" "}
              </div>
              <div className="modal-footer-alert">
                <button
                  className="btn btn-sm btn-51comvc "
                  type="button"
                  data-dismiss="modal"
                >
                  {Times}
                  &nbsp;Cancelar
                </button>
                &nbsp;&nbsp;
                {modal.bar_acompanhamentovendas !== 1 ? (
                  <button
                    type="button"
                    onClick={(e) => save(modal)}
                    style={{ width: "102px", height: "35px" }}
                    className="btn btn-status btn-sm bg-51comvc  font-weight-bold"
                    data-dismiss="modal"
                  >
                    Ativar
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={(e) => save(modal)}
                    className="btn btn-sm btn-51comvc"
                    data-dismiss="modal"
                    style={{
                      background: "#c98888",
                      width: "102px",
                      height: "35px",
                    }}
                  >
                    Inativar
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Clientes;
