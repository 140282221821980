import React, { useState, useEffect } from 'react';
import { TablePagination } from '@material-ui/core';
import Select from 'react-select';
import { api } from '../utils/WebApiConfig';
import { handleError, handleSuccess, handleInfo } from '../utils/Toastify';
import { useHistory } from 'react-router-dom';
import { Times, Eye } from '../utils/Fontawesome';
import { ilikeToRegExp, removeAcento } from '../utils/Mascara';
import img_usuario from '../imagens/svg/img_usuario.png';
import img_cadastrar from '../imagens/svg/img_cadastrar.png';
import img_excluir from '../imagens/svg/img_excluir.png';
import img_alterar from '../imagens/svg/img_alterar.png';
import img_excluir2 from '../imagens/svg/img_excluir2.png';
import pesquisar from '../imagens/svg/img_lupa.png';
const Clientes = () => {
  let history = useHistory();

  const [perfil, setPerfil] = useState([]);
  const [statusPerfil, setStatusPerfil] = useState([]);

  const [ativo, setAtivo] = useState(1);
  const [email, setEmail] = useState('');
  const [usu_senha, setUsu_senha] = useState('');
  const [nome, setNome] = useState('');
  const [id, setId] = useState(0);
  const [regional, setRegional] = useState([]);
  const [statusRegional, setStatusRegional] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [usuario, setUsuario] = useState([]);
  const [nomeModal, setNomeModal] = useState('');
  const [idModal, setIdModal] = useState('');
  const [perfilModal, setPerfilModal] = useState('');
  const [idStatusModal, setStatusModal] = useState('');

  // variaveis da paginaçãp
  const [arrayFiltrado, setArrayFiltrado] = useState('');
  const [busca, setBusca] = useState('');
  const [usuariosPag, setUsuariosPag] = useState([]);
  const [onloadStatus, setOnloadStatus] = useState(0);
  const [lastID, setLastID] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = async (event, newPage) => {
    await setPage(newPage);

    var inicio = newPage * rowsPerPage + 1;
    var fim = inicio + (rowsPerPage - 1);
    /*  console.log(
     "rowsPerPage:" +
       rowsPerPage +
       " newPage " +
       newPage +
       " " +
       inicio +
       " ---- " +
       fim
   );*/
    if (fim > arrayFiltrado.length) {
      fim = arrayFiltrado.length;
    }
    const usuariosPag = [];
    for (let i = inicio - 1; i < fim; i++) {
      usuariosPag.push(arrayFiltrado[i]);
    }
    setUsuariosPag(usuariosPag);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

    var inicio = 0 * event.target.value + 1;
    var fim = inicio + (event.target.value - 1);
    /*   console.log(
      "rowsPerPage:" +
        rowsPerPage +
       
        " " +
        inicio +
        " ---- " +
        fim
    );*/

    if (fim > arrayFiltrado.length) {
      fim = arrayFiltrado.length;
    }
    const usuariosPag = [];
    for (let i = inicio - 1; i < fim; i++) {
      usuariosPag.push(arrayFiltrado[i]);
    }
    setUsuariosPag(usuariosPag);
  };
  useEffect(() => {
    if (document.getElementsByClassName('modal-backdrop')[0]) {
      var no = document.getElementsByClassName('modal-backdrop')[0];
      if (no.parentNode) {
        no.parentNode.removeChild(no);
      }
    }
    if (sessionStorage.getItem('perfil') !== 'G') {
      setStatusPerfil([
        {
          value: 'P',
          label: 'Promotor',
        },
        {
          value: 'S',
          label: 'Supervisor',
        },
      ]);

      setStatusRegional([
        {
          value: sessionStorage.getItem('regional'),
          label: sessionStorage.getItem('regional_nome'),
        },
      ]);
    } else {
      setStatusPerfil([
        {
          value: 'P',
          label: 'Promotor',
        },
        {
          value: 'S',
          label: 'Supervisor',
        },
        {
          value: 'G',
          label: 'Administrador',
        },
      ]);
    }
    document.getElementById('ativo').checked = true;

    getUsuarios();
    getRegional();
  }, []);

  const catchResponse = (status) => {
    if (status) {
      if (status === 401) {
        if (document.getElementById('btn_limpar')) {
          document.getElementById('btn_limpar').click();
        }
        handleInfo('Sessão expirada.');
        history.push('/login');
      }
    }
    handleError('Ocorreu um erro inesperado na execução desta operação');
  };
  const checkboxAtivo = () => {
    if (ativo === 1) {
      setAtivo(0);
    } else {
      setAtivo(1);
    }
  };
  const getUsuarios = () => {
    api
      .get('api/usuarios?regional=' + sessionStorage.getItem('regional'), {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
        },
      })
      .then((response) => {
        setUsuario(response.data);
        setArrayFiltrado(response.data);
        setTotalPages(response.data.length);
        var inicio = 0;
        var fim = 10;
        if (fim > response.data.length) {
          fim = response.data.length;
        }

        const usuarioPag = [];
        for (let i = inicio; i < fim; i++) {
          usuarioPag.push(response.data[i]);
        }
        setUsuariosPag(usuarioPag);
        console.log(response.data);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };

  const getRegional = () => {
    api
      .get('api/regionais', {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
        },
      })
      .then((response) => {
        const arrayRegional = [];

        for (let i = 0; i < response.data.length; i++) {
          arrayRegional.push({
            value: response.data[i].reg_id,
            label: response.data[i].reg_nome,
          });
        }

        arrayRegional.push({
          value: 0,
          label: 'NAO DEFINIDO',
        });
        setStatusRegional(arrayRegional);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };

  const handleChange = (regional) => {
    if (perfil.value !== 'G' && regional.value == 0) {
      handleInfo('Apenas administradores podem ter regiões não definida');
    } else {
      setRegional(regional);
    }
  };
  const handleChangePerfil = (perfil) => {
    if (sessionStorage.getItem('regional') > 0) {
      setIsDisabled(true);
    } else {
      if (perfil.value === 'G') {
        setRegional({
          value: 0,
          label: 'NAO DEFINIDO',
        });
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
      if (perfil.value !== 'G') {
        if (regional.value == 0) {
          setRegional([]);
        } else if (sessionStorage.getItem('regional') == 0) {
          setRegional([]);
        } else {
          setRegional({
            value: sessionStorage.getItem('regional'),
            label: sessionStorage.getItem('regional_nome'),
          });
        }
      }
    }

    setPerfil(perfil);
  };

  const editar = (usuario) => {
    //  console.log(cliente);
    if (document.getElementById('btnAlterar')) {
      document.getElementById('btnAlterar').disabled = false;
      if (
        sessionStorage.getItem('perfil') !== 'G' &&
        usuario.usu_perfil === 'G'
      ) {
        document.getElementById('btnAlterar').disabled = true;
      }
    }
    document.getElementById('nome').focus();
    document.getElementById('usu_senha').readOnly = true;
    document.getElementById('col-usu_senha').style.display = 'none';
    setRegional({
      value: usuario.usu_regional,
      label: usuario.reg_nome,
    });

    setPerfil({
      value: usuario.usu_perfil,
      label: usuario.usu_perfil_nome,
    });
    setNome(usuario.usu_nome);
    setUsu_senha(usuario.usu_senha);
    setAtivo(usuario.usu_ativo);
    setEmail(usuario.usu_email);
    setId(usuario.usu_id);
    if (usuario.usu_ativo === 1) {
      document.getElementById('ativo').checked = true;
    } else {
      document.getElementById('ativo').checked = false;
    }
  };

  const dadosModal = (nome, id, status, perfil) => {
    setNomeModal(nome);
    setIdModal(id);
    setStatusModal(status);
    setPerfilModal(perfil);
  };
  const save = (e, metodo, status) => {
    e.preventDefault();
    e.stopPropagation();
    let data, method;
    if (metodo) {
      method = 'delete';
      if (sessionStorage.getItem('perfil') !== 'G' && perfilModal === 'G') {
        handleInfo(
          'Apenas administradores podem excluir outros administradores',
        );
        return false;
      }
    } else {
      if (!nome) {
        handleInfo('Preencha o campo Nome.');
        return false;
      }
      if (!email) {
        handleInfo('Preencha o campo email.');
        return false;
      }

      if (!perfil.value) {
        handleInfo('Selecione uma opção de Perfil.');
        return false;
      }

      if (!id) {
        method = 'post';
        if (!usu_senha) {
          handleInfo('Preencha o campo senha.');
          return false;
        }
      } else {
        method = 'put';
      }
    }

    if (method === 'put') {
      data = {
        usu_id: id,
        usu_ativo: ativo,
        usu_perfil: perfil.value,
        usu_nome: nome,
        usu_email: email,
        usu_regional:
          sessionStorage.getItem('perfil') == 'G'
            ? regional.value
            : sessionStorage.getItem('regional'),
      };

      var url = 'api/usuarios/' + id;
    } else if (method === 'post') {
      data = {
        usu_perfil: perfil.value,
        usu_nome: nome,
        usu_email: email,
        usu_senha: usu_senha,
        usu_ativo: ativo,
        usu_regional:
          sessionStorage.getItem('perfil') == 'G'
            ? regional.value
            : sessionStorage.getItem('regional'),
      };
      var url = 'api/usuarios';
    } else if (method === 'delete') {
      // url = url + "/" + idModal + "/" + status;
      var url = 'api/usuarios/' + idModal;
      data = {
        id: idModal,
      };
      setNomeModal('');
      setIdModal('');
    }

    api({
      method: method,
      url: url,
      data: data,
      headers: {
        Authorization: 'bearer ' + sessionStorage.getItem('token'),
      },
    })
      .then((response) => {
        handleSuccess(response.headers.statusmessage);
        if (document.getElementById('btn_limpar')) {
          document.getElementById('btn_limpar').click();
        }
        getUsuarios();
      })
      .catch((error) => {
        //  catchResponse();
        console.log(error);
        catchResponse(error.response.status);
        //  handleSuccess(error.message);
      });
  };

  const limpar = () => {
    if (
      sessionStorage.getItem('perfil') !== 'G' &&
      usuario.usu_perfil === 'G'
    ) {
      setIsDisabled(true);
    }

    document.getElementById('usu_senha').readOnly = false;
    document.getElementById('col-usu_senha').style.display = '';

    setUsu_senha('');
    setPerfil('');
    setAtivo(1);
    setEmail('');
    setNome('');
    setId('');
    setIsDisabled(true);
    setRegional([
      {
        value: sessionStorage.getItem('regional'),
        label: sessionStorage.getItem('regional_nome'),
      },
    ]);

    setNomeModal('');
    setIdModal('');
    setPerfilModal('');
  };
  const imgLogo = {
    width: '28px',
  };
  const buscar = async () => {
    let filtrado;
    if (arrayFiltrado) {
      if (!busca) {
        filtrado = usuario;
      } else {
        filtrado = arrayFiltrado.filter(function (obj) {
          return ilikeToRegExp('%' + busca + '%').test(obj.usu_nome);
        });
      }
      var inicio = 0 * rowsPerPage + 1;
      var fim = inicio + rowsPerPage - 1;
      /*  console.log(
        "inicio: " + inicio + "  fim: " + fim + " rowperpage " + rowsPerPage
      );*/
      if (!filtrado.length) {
        filtrado = usuario;
        handleInfo('Nenhum registro encontrado.');
      }
      if (fim > filtrado.length) {
        fim = filtrado.length;
      }
      const usuariosPag = [];
      for (let i = inicio - 1; i < fim; i++) {
        usuariosPag.push(filtrado[i]);
      }

      await setUsuariosPag(usuariosPag);
      await setTotalPages(filtrado.length);
      await setArrayFiltrado(filtrado);
      await setPage(0);
    } else {
      handleInfo('Nenhum registro encontrado');
    }
  };
  return (
    <>
      <div className="container-fluid">
        <div
          class="modal fade bd-example-modal-lg"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div className="card">
                <div className="card-header"></div>
                <span className="span-card-titulo">
                  <img src={img_usuario} className="img-card" alt="logo" />{' '}
                  Cadastro de Usuário
                </span>
                <div id="triangulo-para-baixo"></div>
                <div className="card-body">
                  <form id="form_cliente" onSubmit={(e) => save(e)}>
                    <div className="form-row mb-3">
                      {!id ? (
                        <>
                          <div className="col-md-4 mb-3">
                            <label htmlFor="nome">
                              Nome de usuário:
                              <strong className="fild-required">*</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="nome"
                              placeholder="Digite seu Nome"
                              onChange={(e) => setNome(e.target.value)}
                              value={nome}
                              required
                            />
                          </div>
                          <div className="col-md-4 mb-3">
                            <label htmlFor="email">
                              Email:<strong className="fild-required">*</strong>
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              placeholder="Digite seu Email"
                              onChange={(e) =>
                                setEmail(e.target.value.toLowerCase())
                              }
                              value={email}
                              required
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          {' '}
                          <div className="col-md-6 mb-3">
                            <label htmlFor="nome">
                              Nome de usuário:
                              <strong className="fild-required">*</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="nome"
                              placeholder="Digite seu Nome"
                              onChange={(e) => setNome(e.target.value)}
                              value={nome}
                              required
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                            <label htmlFor="email">
                              Email:<strong className="fild-required">*</strong>
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              placeholder="Digite seu Email"
                              onChange={(e) =>
                                setEmail(e.target.value.toLowerCase())
                              }
                              value={email}
                              required
                            />
                          </div>
                        </>
                      )}
                      <div
                        id="col-usu_senha"
                        className=" col-md-4  col-sm-9 mb-3"
                      >
                        <label htmlFor="usu_senha">
                          Senha:<strong className="fild-required">*</strong>
                        </label>{' '}
                        <div className="input-group">
                          <input
                            type="password"
                            className="form-control"
                            id="usu_senha"
                            placeholder="Digite sua Senha"
                            style={{ width: '80%' }}
                            maxLength="10"
                            autoComplete="new-password"
                            onChange={(e) => setUsu_senha(e.target.value)}
                            value={usu_senha}
                            required
                          />{' '}
                          <div className="input-group-prepend">
                            <div
                              onClick={(e) =>
                                (document.getElementById('usu_senha').type =
                                  document.getElementById('usu_senha').type ===
                                  'text'
                                    ? 'password'
                                    : 'text')
                              }
                              className="input-group-text "
                            >
                              {Eye}
                            </div>{' '}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-5 mb-3">
                        <label htmlFor="perfil">
                          Perfil:<strong className="fild-required">*</strong>
                        </label>
                        <Select
                          id="perfil"
                          value={perfil}
                          onChange={handleChangePerfil}
                          options={statusPerfil}
                        />
                        <input
                          style={{
                            top: '43px',
                            position: 'absolute',
                            zIndex: '-1',
                          }}
                          type="password"
                          id="perfil_html5_validate"
                          autoComplete="new-password"
                          value={perfil}
                          required
                        />
                      </div>
                      <div className="col-md-5 mb-3">
                        <label htmlFor="regional">
                          Regional:<strong className="fild-required">*</strong>
                        </label>
                        <Select
                          id="regional"
                          value={regional}
                          isDisabled={isDisabled}
                          onChange={handleChange}
                          options={statusRegional}
                        />{' '}
                        <input
                          style={{
                            top: '43px',
                            position: 'absolute',
                            zIndex: '-1',
                          }}
                          type="password"
                          id="regional_html5_validate"
                          autoComplete="new-password"
                          value={regional}
                          required
                        />
                      </div>
                      <div className="col-md-2 mb-3 mt-3 ">
                        <label htmlFor="ativo">
                          {ativo == 1 ? (
                            <b className="text-success">Status</b>
                          ) : (
                            <b className="text-secondary">Status</b>
                          )}
                        </label>
                        <div class="material-switch   pull-right">
                          <input
                            onClick={(e) => checkboxAtivo()}
                            id="ativo"
                            name="ativo"
                            type="checkbox"
                          />
                          <label htmlFor="ativo" class="badge-success"></label>
                        </div>
                      </div>
                    </div>
                    <div className="text-left ">
                      {id ? (
                        <button
                          className="btn btn-sm btn-51comvc "
                          type="submit"
                          id="btnAlterar"
                        >
                          Alterar
                          <img
                            src={img_cadastrar}
                            className="img-btn"
                            alt="logo"
                          />
                        </button>
                      ) : (
                        <button
                          className="btn btn-sm btn-51comvc "
                          type="submit"
                          id="btnCadastrar"
                        >
                          Cadastrar
                          <img
                            src={img_cadastrar}
                            className="img-btn"
                            alt="logo"
                          />
                        </button>
                      )}{' '}
                      &nbsp;&nbsp;
                      <button
                        className="btn btn-sm btn-51comvc "
                        onClick={(e) => limpar(e)}
                        type="button"
                        id="btn_limpar"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        Fechar
                        <img src={img_excluir} className="img-btn" alt="logo" />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header"></div>
          <span className="span-card-titulo">
            <img src={img_usuario} className="img-card" alt="logo" /> Lista de
            Usuários
          </span>
          <div id="triangulo-para-baixo"></div>
          <div className="card-body card-body-lista">
            <form>
              <div className="form-group">
                <div className="form-row">
                  <div className="col-md-5 col-12">
                    <div className="row">
                      <div
                        className="col-md-10  col-10 col-sm-10"
                        style={{ paddingRight: '0px' }}
                      >
                        <label htmlFor="filtro">Filtrar Tabela:</label>
                        <input
                          id="filtro"
                          className="form-control"
                          style={{ display: 'inline' }}
                          type="text"
                          autoComplete="new-password"
                          placeholder="Termo..."
                          onChange={(e) =>
                            setBusca(removeAcento(e.target.value))
                          }
                          value={busca}
                        />
                      </div>
                      <div
                        className="col-md-1 col-1  col-sm-1"
                        style={{ paddingLeft: '0px' }}
                      >
                        {' '}
                        <label htmlFor="btn_visualizar">&nbsp;&nbsp;</label>
                        <button
                          style={{ display: 'inline' }}
                          type="button"
                          className="btn  bg-51comvc bl-3"
                          id="btn_visualizar"
                          onClick={(e) => buscar(e)}
                        >
                          <img src={pesquisar} className="img-btn" alt="logo" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <label htmlFor="btn_Cadastrar">&nbsp;&nbsp;&nbsp;</label>
                    <br />
                    <button
                      type="button"
                      className="btn  bg-51comvc btn-block "
                      id="btn_Cadastrar"
                      onClick={(e) => limpar(e)}
                      data-toggle="modal"
                      data-target=".bd-example-modal-lg"
                    >
                      Cadastrar
                      <img src={img_cadastrar} className="img-btn" alt="logo" />
                    </button>
                  </div>
                  <div className="col-md-5"></div>
                </div>
              </div>
            </form>
            <div className=" table-responsive">
              <table className="table  table-hover table-striped table-light ">
                <thead className="text-white  bg-dark ">
                  <tr>
                    <th style={{ width: '85%' }} scope="col">
                      Nome
                    </th>

                    {/*<th scope="col">Email</th>
                    <th scope="col">Regional</th>
                    <th scope="col">Perfil</th>*/}
                    <th scope="col">Status</th>
                    <th style={{ width: '15%' }} scope="col">
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {usuariosPag.length > 0
                    ? usuariosPag.map(function (usuarios, i) {
                        return (
                          <>
                            <tr key={usuarios.usu_id}>
                              <th>
                                {usuarios.usu_nome}
                                <br />
                                <i
                                  style={{
                                    fontSize: '12px',
                                  }}
                                >
                                  {usuarios.usu_perfil_nome +
                                    ' - ' +
                                    usuarios.reg_nome}
                                </i>
                              </th>

                              {/*  <th>{usuarios.usu_email}</th>
                              <th>{usuarios.reg_nome}</th>
                              <th>{usuarios.usu_perfil_nome}</th>*/}
                              <th>
                                {usuarios.usu_ativo === 1 ? 'Ativo' : 'Inativo'}
                              </th>
                              <td>
                                <button
                                  type="button"
                                  onClick={(e) => editar(usuarios)}
                                  className="btn btn-sm "
                                  data-toggle="modal"
                                  data-target=".bd-example-modal-lg"
                                >
                                  <img
                                    src={img_alterar}
                                    className="img-btn-acao"
                                    alt="logo"
                                  />
                                </button>
                                <button
                                  type="button"
                                  data-toggle="modal"
                                  data-target="#exampleModalCenter"
                                  onClick={(e) =>
                                    dadosModal(
                                      usuarios.usu_nome,
                                      usuarios.usu_id,
                                      usuarios.usu_ativo,
                                      usuarios.usu_perfil,
                                    )
                                  }
                                  className="btn btn-sm  btn-excluir"
                                >
                                  <img
                                    src={img_excluir2}
                                    className="img-btn-acao"
                                    alt="logo"
                                  />
                                </button>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    : ''}
                </tbody>
              </table>
              <TablePagination
                component="div"
                count={totalPages}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={'Registros por página'}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModalCenter"
        tabIndex="1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="card">
              <div className="card-header"></div>
              <span className="span-card-titulo">
                <img src={img_usuario} className="img-card" alt="logo" />{' '}
                Excluir usuário
              </span>
              <div id="triangulo-para-baixo"></div>
              <div className="card-body">
                Tem certeza que deseja excluir o usuário
                <i>
                  <b className="text-danger">"</b>
                  {nomeModal}
                  <b className="text-danger">"</b>
                </i>{' '}
              </div>
              <div className="modal-footer-alert">
                <button
                  className="btn btn-sm btn-51comvc "
                  type="button"
                  data-dismiss="modal"
                >
                  {Times}
                  &nbsp;Cancelar
                </button>
                &nbsp;&nbsp;
                <button
                  className="btn btn-sm btn-51comvc "
                  onClick={(e) => save(e, 'delete', 0)}
                  type="button"
                  data-dismiss="modal"
                >
                  Excluir
                  <img src={img_excluir} className="img-btn" alt="logo" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Clientes;
