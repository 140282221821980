import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';

import PrivateRoute from './auth';
// React Boostrap e outros  css Import

//import PrivateRoute from './auth';
// React Boostrap e outros  css Import

import 'bootstrap/dist/css/bootstrap.min.css';
//import "jquery/dist/jquery.slim.min.js";
//import "bootstrap/dist/js/bootstrap.min.js";
//import "bootstrap/dist/js/bootstrap.bundle.min.js";

import GlobalStyle from './components/styles/toastify';
// React Router Import
import { Switch, Route, BrowserRouter, Link } from 'react-router-dom';

// React Menu e Footer Import
//import Navbar from "./components/layouts/NavBar";
import Footer from './components/layouts/Footer';
// Import Pages

import Home from './components/pages/Home';

//  Componentes de login
import Login from './components/pages/Login';
import Logout from './components/pages/Logout';

//  Componentes de Cadastros
import Clientes from './components/pages/Clientes';
import Regional from './components/pages/Regional';
import Distribuidora from './components/pages/Distribuidoramodal';
import Cidade from './components/pages/Cidade';
import Bar from './components/pages/Bar';
import Produto from './components/pages/Produto';
import Campanha from './components/pages/Campanha';
import PontoDeMidia from './components/pages/PontoDeMidia';
import TipoPontoDeMidia from './components/pages/TipoPontoDeMidia';
import DashBoard from './components/pages/DashBoard';
import AlterarSenha from './components/pages/AlterarSenha';
import RecuperarSenha from './components/pages/RecuperarSenha';

//  Componentes de vendaS
import CadastroVendas from './components/pages/vendas/CadastroVendas';
import Acompanhamentos from './components/pages/vendas/Acompanhamentos';
import IndicacaoBar from './components/pages/vendas/IndicacaoBar';
import Rastreamento from './components/pages/vendas/Rastreamento';
import './components/styles/main.css';
import img_51comvc from './components/imagens/img_51comvc.jpg';

/*
window.onload = function () {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    console.log("Dispositivo Movel");
    var el_array = document.querySelectorAll(".sidebar-link");
    console.log(el_array);
    for (var el of el_array) {
      el.addEventListener(
        "click",
        function FecharMenuResponsivo() {
          document.getElementById("sidebarCollapse").click();
        },
        false
      );
    }
  }
};*/

export const cadastros = [
  { text: 'Alterar Senha', link: '/alterarsenha', icon: '', nivel: 'GSP' },
  { text: 'Usuários', link: '/clientes', icon: '', nivel: 'GS' },
  { text: 'Cidades', link: '/cidade', icon: '', nivel: 'GS' },
  { text: 'Regional', link: '/regional', icon: '', nivel: 'G' },
  { text: 'Distribuidoras', link: '/distribuidora', icon: '', nivel: 'GS' },
  { text: 'Campanhas', link: '/campanha', icon: '', nivel: 'G' },
  { text: 'Bares', link: '/bar', icon: '', nivel: 'GSP' },
  { text: 'Produtos', link: '/produto', icon: '', nivel: 'G' },
  {
    text: 'Tipo de ponto de mídia',
    link: '/tipopontodemidia',
    icon: '',
    nivel: 'G',
  },
  { text: 'Pontos de mídia', link: '/pontodemidia', icon: '', nivel: 'G' },
];
export const vendas = [
  { text: 'Indicação do Bar', link: '/indicacaobar', icon: '', nivel: 'GS' },
  {
    text: 'Lançamento de Vendas',
    link: '/acompanhamentos',
    icon: '',
    nivel: 'GSP',
  },
  { text: 'DashBoard', link: '/dashboard', icon: '', nivel: 'GS' },
  { text: 'Google Maps', link: '/rastreamento', icon: '', nivel: 'G' },
];

export const permissoes = (value) => {
  if (value.nivel) {
    if (value.nivel.indexOf(String(sessionStorage.getItem('perfil'))) !== -1) {
      return value;
    }
  }
};
function Routes() {
  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
      )
    ) {
      var el_array = document.querySelectorAll('.sidebar-link');

      for (var el of el_array) {
        el.addEventListener(
          'click',
          function FecharMenuResponsivo() {
            document.getElementById('sidebarCollapse').click();
          },
          false,
        );
      }
    }
  });
  const Layout = ({ children }) => (
    <div>
      <div className="wrapper">
        <nav id="sidebar">
          <Link to={'/dashboard'}>
            <div className="sidebar-header">
              <img src={img_51comvc} className="logo-51comvc" alt="51comvc" />
            </div>
          </Link>

          <div>
            <a className="bg-black aside-user" href="#">
              {sessionStorage.getItem('nome')}
              <br />
              {sessionStorage.getItem('perfil_nome')}
              {sessionStorage.getItem('regional') == 0
                ? ''
                : ': ' + sessionStorage.getItem('regional_nome')}
            </a>
          </div>
          <ul className="list-unstyled components">
            {/*<p>Dummy Heading</p>*/}

            <li>
              <a
                href="#pageSubmenu"
                data-toggle="collapse"
                aria-expanded="false"
                className="dropdown-toggle"
              >
                Cadastros
              </a>
              <ul className="collapse list-unstyled" id="pageSubmenu">
                {cadastros.filter(permissoes).map((item) => {
                  return (
                    <Link
                      key={item.link}
                      className="sidebar-link"
                      to={item.link}
                    >
                      {item.text}
                    </Link>
                  );
                })}
              </ul>
              <a
                href="#pageSubmenu2"
                data-toggle="collapse"
                aria-expanded="false"
                className="dropdown-toggle"
              >
                Vendas
              </a>
              <ul className="collapse list-unstyled" id="pageSubmenu2">
                {vendas.filter(permissoes).map((item, i) => {
                  return (
                    <Link
                      key={item.link}
                      className="sidebar-link"
                      to={item.link}
                    >
                      {item.text}
                    </Link>
                  );
                })}
              </ul>
            </li>
            <li>
              <Link
                className="sidebar-link"
                to="/logout"
                className="color-51comvc"
              >
                Sair da conta
              </Link>
            </li>
          </ul>
        </nav>

        <div id="content">
          <nav id="nav-mobile" className="navbar navbar-expand-lg  ">
            <div className="container-fluid">
              <button type="button" id="sidebarCollapse" className="navbar-btn">
                <span></span>
                <span></span>
                <span></span>
              </button>
              <div className="sidebar-header">
                <img
                  src={img_51comvc}
                  className="logo-51comvc-mobile"
                  alt="51comvc"
                />
              </div>
            </div>
          </nav>
          <Switch>{children}</Switch>
        </div>
      </div>

      <Footer />
    </div>
  );

  return (
    <div>
      <GlobalStyle />
      <ToastContainer />
      <BrowserRouter>
        <Switch>
          {/*   Componentes de login   */}
          <Route path="/logout" component={Logout} />
          <Route path="/recuperarsenha" component={RecuperarSenha} />
          <Route exact path="/" component={Login} />
          <Route path="/login" component={Login} />

          <Layout>
            <PrivateRoute path="/home" component={Home} />

            {/*   Cadastros   */}
            <PrivateRoute path="/clientes" component={Clientes} />
            <PrivateRoute path="/alterarsenha" component={AlterarSenha} />

            <PrivateRoute path="/regional" component={Regional} />
            <PrivateRoute path="/cidade" component={Cidade} />
            <PrivateRoute path="/distribuidora" component={Distribuidora} />
            <PrivateRoute path="/bar" component={Bar} />
            <PrivateRoute path="/produto" component={Produto} />
            <PrivateRoute path="/campanha" component={Campanha} />
            <PrivateRoute path="/pontodemidia" component={PontoDeMidia} />
            <PrivateRoute
              path="/tipopontodemidia"
              component={TipoPontoDeMidia}
            />

            {/*   Vendas   */}
            <PrivateRoute path="/dashboard" component={DashBoard} />
            <PrivateRoute path="/acompanhamentos" component={Acompanhamentos} />
            <PrivateRoute path="/indicacaobar" component={IndicacaoBar} />
            <PrivateRoute
              path="/cadastrovendas/:bai_id/:bar_nomebar"
              component={CadastroVendas}
            />
            <PrivateRoute path="/rastreamento" component={Rastreamento} />
          </Layout>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default Routes;
