import React, { useState, useEffect } from "react";
import Select from "react-select";
import { api } from "../../utils/WebApiConfig";
import { handleError, handleSuccess, handleInfo } from "../../utils/Toastify";
import { useHistory,Link } from "react-router-dom";
import { Trash, Save, Edit, Times } from "../../utils/Fontawesome";
import { TablePagination } from "@material-ui/core";
import {
  mask_Moeda,
  limpar_Moeda,
  mask_Qtd,
  mask_Numeros,
  ilikeToRegExp,
  removeAcento,
  replaceSpecialChars,
} from "../../utils/Mascara";
import img_usuario from "../../imagens/svg/img_usuario.png";
import img_cadastrar from "../../imagens/svg/img_cadastrar.png";
import img_excluir from "../../imagens/svg/img_excluir.png";
import img_alterar from "../../imagens/svg/img_alterar.png";
import img_excluir2 from "../../imagens/svg/img_excluir2.png";
import pesquisar from "../../imagens/svg/img_lupa.png";
const Clientes = () => {
  let history = useHistory();
  var [url, setUrl] = useState("api/acompanhamentovendas");

  const [av_id, setAv_id] = useState("");
  const [av_produto, setAv_produto] = useState([]);
  const [statusAv_produto, setStatusAv_produto] = useState([]);
  const [av_bar, setAv_bar] = useState([]);
  const [statusAv_bar, setStatusAv_bar] = useState([]);

  const [av_qtdecompra, setAv_qtdecompra] = useState("");
  const [av_precocompra, setAv_precocompra] = useState("");
  const [av_qtdevenda, setAv_qtdevenda] = useState("");
  const [av_precovenda, setAv_precovenda] = useState("");
  const [av_mes, setAv_mes] = useState();
  const [av_ano, setAv_ano] = useState();
  const [acompanhamentovenda, setAcompanhamentovenda] = useState("");
  const [nomeModal, setNomeModal] = useState("");
  const [idModal, setIdModal] = useState("");

  // variaveis da paginaçãp
  const [arrayFiltrado, setArrayFiltrado] = useState("");
  const [busca, setBusca] = useState("");
  const [acompanhamentovendaPag, setAcompanhamentovendaPag] = useState([]);
  const [onloadStatus, setOnloadStatus] = useState(0);
  const [lastID, setLastID] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = async (event, newPage) => {
    await setPage(newPage);

    var inicio = newPage * rowsPerPage + 1;
    var fim = inicio + (rowsPerPage - 1);
    /*  console.log(
      "rowsPerPage:" +
        rowsPerPage +
        " newPage " +
        newPage +
        " " +
        inicio +
        " ---- " +
        fim
    );*/
    if (fim > arrayFiltrado.length) {
      fim = arrayFiltrado.length;
    }
    const usuariosPag = [];
    for (let i = inicio - 1; i < fim; i++) {
      usuariosPag.push(arrayFiltrado[i]);
    }
    setAcompanhamentovendaPag(usuariosPag);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

    var inicio = 0 * event.target.value + 1;
    var fim = inicio + (event.target.value - 1);
    if (fim > arrayFiltrado.length) {
      fim = arrayFiltrado.length;
    }
    const usuariosPag = [];
    for (let i = inicio - 1; i < fim; i++) {
      usuariosPag.push(arrayFiltrado[i]);
    }
    setAcompanhamentovendaPag(usuariosPag);
  };

  useEffect(() => {
    if(document.getElementsByClassName('modal-backdrop')[0]){
      var no = document.getElementsByClassName("modal-backdrop")[0];
      if (no.parentNode) {
        no.parentNode.removeChild(no);
      }
    }
    getAcompanhamentovenda();
  //  getProduto();
  //  getBar();
  }, []);

  const catchResponse = (status) => {
    if (status) {
      if (status === 401) {
        if(document.getElementById("btn_limpar")){
          document.getElementById("btn_limpar").click();
        } 
        handleInfo("Sessão expirada.");
        history.push("/login");
      }
    }
    handleError("Ocorreu um erro inesperado na execução desta operação");
  };

  const getAcompanhamentovenda = () => {
    var getPromotor = '';
    if(sessionStorage.getItem("perfil") == "P"){
       getPromotor  = "&nivel=1&id="+sessionStorage.getItem("id");
    }

    api
      .get("api/bares?regional=" + sessionStorage.getItem("regional") +
      "&status=1"+"&acompanhamento=1"+getPromotor, {
        headers: {
          Authorization: "bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((response) => {
        setAcompanhamentovenda(response.data);
        setArrayFiltrado(response.data);
        setTotalPages(response.data.length);
        var inicio = 0;
        var fim = 10;
        if (fim > response.data.length) {
          fim = response.data.length;
        }

        const usuarioPag = [];
        for (let i = inicio; i < fim; i++) {
          usuarioPag.push(response.data[i]);
        }
        setAcompanhamentovendaPag(usuarioPag);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };
  const getBar = () => {
   

    api
      .get("api/bares?regional=" +
      sessionStorage.getItem("regional") +
      "&status=1"+"&acompanhamento=1", {
        headers: {
          Authorization: "bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((response) => {
        const arrayBar = [];

        for (let i = 0; i < response.data.length; i++) {
          arrayBar.push({
            value: response.data[i].bar_id,
            label: response.data[i].bar_nomebar,
          });
        }

        setStatusAv_bar(arrayBar);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };

  const getProduto = () => {
    api
      .get("api/produtos", {
        headers: {
          Authorization: "bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((response) => {
        const arrayProdutos = [];

        for (let i = 0; i < response.data.length; i++) {
          arrayProdutos.push({
            value: response.data[i].prod_id,
            label: response.data[i].prod_nome,
          });
        }
        setStatusAv_produto(arrayProdutos);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };
  const handleChangeAv_bar = (av_bar) => {
    setAv_bar(av_bar);
  };
  const handleChangeAv_produto = (av_produto) => {
    setAv_produto(av_produto);
  };
  const editar = (acompanha) => {
    //  console.log(cliente);
    document.getElementById("av_bar_html5_validate").focus();
    setAv_produto({
      value: acompanha.av_produto,
      label: acompanha.prod_nome,
    });
    setAv_bar({
      value: acompanha.av_bar,
      label: acompanha.bar_nomebar,
    });
    setAv_id(acompanha.av_id);
    setAv_qtdecompra(acompanha.av_qtdecompra);
    setAv_precocompra(acompanha.av_precocompra);
    setAv_qtdevenda(acompanha.av_qtdevenda);
    setAv_precovenda(acompanha.av_precovenda);
    setAv_mes(acompanha.av_mes);
    setAv_ano(acompanha.av_ano);
  };

  const dadosModal = (nome, id) => {
    setNomeModal(nome);
    setIdModal(id);
  };
  const save = (e, metodo) => {
    e.preventDefault();
    e.stopPropagation();
    let data, method;
    if (metodo) {
      method = "delete";
    } else {
      if (!av_id) {
        method = "post";
      } else {
        method = "put";
      }
    }

    if (method === "put") {
      data = {
        av_id: av_id,
        av_produto: av_produto.value,
        av_bar: av_bar.value,
        av_qtdecompra: mask_Numeros(av_qtdecompra),
        av_precocompra: limpar_Moeda(av_precocompra),
        av_qtdevenda: mask_Numeros(av_qtdevenda),
        av_precovenda: limpar_Moeda(av_precovenda),
        av_mes: av_mes,
        av_ano: av_ano,
      };
    } else if (method === "post") {
      data = {
        av_produto: av_produto.value,
        av_bar: av_bar.value,
        av_qtdecompra: mask_Numeros(av_qtdecompra),
        av_precocompra: limpar_Moeda(av_precocompra),
        av_qtdevenda: mask_Numeros(av_qtdevenda),
        av_precovenda: limpar_Moeda(av_precovenda),
        av_mes: av_mes,
        av_ano: av_ano,
      };
    } else if (method === "delete") {
      url = url + "/" + idModal;
      data = {
        id: idModal,
      };
      setNomeModal("");
      setIdModal("");
    }

    api({
      method: method,
      url: url,
      data: data,
      headers: {
        Authorization: "bearer " + sessionStorage.getItem("token"),
      },
    })
      .then((response) => {
     
        handleSuccess(response.headers.statusmessage);   
        if(document.getElementById("btn_limpar")){
          document.getElementById("btn_limpar").click();
        } 
        getAcompanhamentovenda();
      })
      .catch((error) => {       

        catchResponse(error.response.status);
        console.log(error);
      });
  };

  const limpar = () => {
    setAv_id("");
    setAv_produto("");
    setAv_bar("");
    setAv_qtdecompra("");
    setAv_precocompra("");
    setAv_precocompra("");
    setAv_qtdevenda("");
    setAv_precovenda("");
    setAv_mes(new Date().getMonth() + 1);
    setAv_ano(new Date().getFullYear());

    setNomeModal("");
    setIdModal("");
  };
  const buscar = async () => {
    let filtrado;
    if (arrayFiltrado) {
      if (!busca) {
        filtrado = acompanhamentovenda;
      } else {
        filtrado = arrayFiltrado.filter(function (obj) {
          return ilikeToRegExp("%" + busca + "%").test(obj.bar_nomebar);
        });
      }
      var inicio = 0 * rowsPerPage + 1;
      var fim = inicio + rowsPerPage - 1;
      if(!filtrado.length){
        filtrado = acompanhamentovenda;
handleInfo('Nenhum registro encontrado.');
      }
      if (fim > filtrado.length) {
        fim = filtrado.length;
      }
      const usuariosPag = [];
      for (let i = inicio - 1; i < fim; i++) {
        usuariosPag.push(filtrado[i]);
      }

      await setAcompanhamentovendaPag(usuariosPag);
      await setTotalPages(filtrado.length);
      await setArrayFiltrado(filtrado);
      await setPage(0);
    } else {
      handleInfo("Nenhum registro encontrado");
    }
  };
  return (
    <>
      <div className="container-fluid">
        <div
          class="modal fade bd-example-modal-xl"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-xl">
            <div class="modal-content">
              <div className="card">
                <div className="card-header"></div>
                <span className="span-card-titulo">
                  <img src={img_usuario} className="img-card" alt="logo" />
                  Cadastro de Acompanhamento de Vendas
                </span>
                <div id="triangulo-para-baixo"></div>
                <div className="card-body">
                  <form id="form_cliente" onSubmit={(e) => save(e)}>
                    <div className="form-row mb-3">
                      <div className="col-md-4 mb-3">
                        <label htmlFor="av_bar">Bar:</label>
                        <Select
                          id="av_bar"
                          value={av_bar}
                          onChange={handleChangeAv_bar}
                          options={statusAv_bar}
                        />
                        <input
                          style={{
                            top: "43px",
                            position: "absolute",
                            zIndex: "-1",
                          }}
                          type="password"
                          id="av_bar_html5_validate"
                          autoComplete="new-password"
                          value={av_bar}
                          required
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label htmlFor="av_produto">Produto:</label>
                        <Select
                          id="av_produto"
                          value={av_produto}
                          onChange={handleChangeAv_produto}
                          options={statusAv_produto}
                        />
                        <input
                          style={{
                            top: "43px",
                            position: "absolute",
                            zIndex: "-1",
                          }}
                          type="password"
                          id="av_produto_html5_validate"
                          autoComplete="new-password"
                          value={av_produto}
                          required
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label htmlFor="av_qtdecompra">
                          Quantidade de compra:
                          <b style={{ fontSize: "10px" }}>Unitário</b>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="av_qtdecompra"
                          placeholder="Qtd. de compra"
                          style={{ width: "100%" }}
                          maxLength="12"
                          autoComplete="new-password"
                          onChange={(e) =>
                            setAv_qtdecompra(mask_Qtd(e.target.value))
                          }
                          value={av_qtdecompra}
                          required
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label htmlFor="av_precocompra">Preço de compra:</label>
                        <input
                          type="text"
                          className="form-control"
                          id="av_precocompra"
                          placeholder="Preço de compra"
                          maxLength="12"
                          autoComplete="new-password"
                          onChange={(e) =>
                            setAv_precocompra(mask_Moeda(e.target.value))
                          }
                          value={av_precocompra}
                          required
                        />
                      </div>

                      <div className="col-md-4 mb-3">
                        <label htmlFor="av_qtdevenda">
                          Quantidade de venda:
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="av_qtdevenda"
                          placeholder="Qtd. de venda"
                          maxLength="12"
                          autoComplete="new-password"
                          onChange={(e) =>
                            setAv_qtdevenda(mask_Qtd(e.target.value))
                          }
                          value={av_qtdevenda}
                          required
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label htmlFor="av_precovenda">Preço de venda:</label>
                        <input
                          type="text"
                          className="form-control"
                          id="av_precovenda"
                          placeholder="Preço de venda"
                          maxLength="12"
                          autoComplete="new-password"
                          onChange={(e) =>
                            setAv_precovenda(mask_Moeda(e.target.value))
                          }
                          value={av_precovenda}
                          required
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label htmlFor="av_mes">Mes:</label>
                        <input
                          type="text"
                          className="form-control"
                          id="av_mes"
                          placeholder="Mes"
                          maxLength="2"
                          autoComplete="new-password"
                          onChange={(e) =>
                            setAv_mes(
                              mask_Numeros(e.target.value) <= 12
                                ? mask_Numeros(e.target.value)
                                : ""
                            )
                          }
                          value={av_mes}
                          required
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label htmlFor="av_ano">Ano:</label>
                        <input
                          type="text"
                          className="form-control"
                          id="av_ano"
                          placeholder="Ano"
                          maxLength="4"
                          autoComplete="new-password"
                          onChange={(e) =>
                            setAv_ano(mask_Numeros(e.target.value))
                          }
                          value={av_ano}
                          required
                        />
                      </div>
                    </div>
                    <div className="text-left ">
                      <button
                        className="btn btn-sm btn-51comvc "
                        onClick={(e) => limpar(e)}
                        type="button"
                        data-dismiss="modal"
                        aria-label="Close"
                        id="btn_limpar"
                      >
                        Fechar
                        <img src={img_excluir} className="img-btn" alt="logo" />
                      </button>
                      &nbsp;&nbsp;
                      {av_id ? (
                        <button
                          className="btn btn-sm btn-51comvc "
                          type="submit"
                          id="btnAlterar"
                        >
                          Alterar
                          <img
                            src={img_cadastrar}
                            className="img-btn"
                            alt="logo"
                          />
                        </button>
                      ) : (
                        <button
                          className="btn btn-sm btn-51comvc "
                          type="submit"
                          id="btnCadastrar"
                        >
                          Cadastrar
                          <img
                            src={img_cadastrar}
                            className="img-btn"
                            alt="logo"
                          />
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-header"></div>
          <span className="span-card-titulo">
            <img src={img_usuario} className="img-card" alt="logo" />
            Lista de acompanhamento de vendas
          </span>
          <div id="triangulo-para-baixo"></div>
          <div className="card-body card-body-lista">
            <form>
              <div className="form-group">
                <div className="form-row">
                  <div className="col-md-5 col-12">
                    <div className="row">
                      <div
                        className="col-md-10  col-10 col-sm-10"
                        style={{ paddingRight: "0px" }}
                      >
                        <label htmlFor="filtro">Filtrar Tabela:</label>
                        <input
                          id="filtro"
                          className="form-control"
                          style={{ display: "inline" }}
                          type="text"
                          autoComplete="new-password"
                          placeholder="Termo..."
                          onChange={(e) =>
                            setBusca(removeAcento(e.target.value))
                          }
                          value={busca}
                        />
                      </div>
                      <div
                        className="col-md-1 col-1  col-sm-1"
                        style={{ paddingLeft: "0px" }}
                      >
                        {" "}
                        <label htmlFor="btn_visualizar">&nbsp;&nbsp;</label>
                        <button
                          style={{ display: "inline" }}
                          type="button"
                          className="btn  bg-51comvc bl-3"
                          id="btn_visualizar"
                          onClick={(e) => buscar(e)}
                        >
                          <img src={pesquisar} className="img-btn" alt="logo" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    {/*
                    <label htmlFor="btn_Cadastrar">&nbsp;&nbsp;&nbsp;</label>
                    <br />
                    <button
                      type="button"
                      className="btn  bg-51comvc btn-block "
                      id="btn_Cadastrar"
                      onClick={(e) => limpar(e)}
                      data-toggle="modal"
                      data-target=".bd-example-modal-xl"
                    >
                      Cadastrar
                      <img src={img_cadastrar} className="img-btn" alt="logo" />
                        </button>
                        */}
                  </div>
                  <div className="col-md-5"></div>
                </div>
              </div>
            </form>

            <div className=" table-responsive">
              <table className="table  table-hover table-striped table-light ">
                <thead className="text-white  bg-dark ">
                  <tr>
                    <th style={{ width: "85%" }} scope="col">
                      Bar
                    </th>
                    {/* <th scope="col">Proprietario</th>
                    <th scope="col">Endereço</th>
                    <th scope="col">Cidade</th>*/}
                    <th
                      style={{ width: "15%", textAlign: "center" }}
                      scope="col"
                    >
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {acompanhamentovendaPag.length > 0
                    ? acompanhamentovendaPag.map(function (acompanha, i) {
                        return (
                          <>
                            <tr key={acompanha.av_id}>
                              <th>
                                {acompanha.bar_nomebar}
                                <br />
                                <i style={{ fontSize: "12px" }}>
                                  {"(" + acompanha.bar_dono_bar + ")"}
                                </i>
                              </th>
                              <td>
                              <Link to={`/cadastrovendas/${acompanha.bar_id}/${replaceSpecialChars(acompanha.bar_nomebar)}`}>
                              <button
                                type="button"
                                className="btn btn-sm "
                                title="Vizualizar Visitas"
                              
                             
                              >
                              <img
                                    src={img_alterar}
                                    className="img-btn-acao"
                                    alt="logo"
                                  />
                              </button>
                            </Link>
                              
                                {/* <button
                                  type="button"
                                  onClick={(e) => editar(acompanha)}
                                  className="btn btn-sm btn-excluir "
                                  data-toggle="modal"
                                  data-target=".bd-example-modal-xl"
                                >
                                  <img
                                    src={img_alterar}
                                    className="img-btn-acao"
                                    alt="logo"
                                  />
                                </button>
                               */}
                                <button
                                  type="button"
                                  data-toggle="modal"
                                  data-target="#exampleModalCenter"
                                  onClick={(e) =>
                                    dadosModal(
                                      acompanha.bar_nomebar,
                                      acompanha.av_id
                                    )
                                  }
                                  className="btn btn-sm  btn-excluir"
                                >
                                  <img
                                    src={img_excluir2}
                                    className="img-btn-acao"
                                    alt="logo"
                                  />
                                </button>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    : ""}
                </tbody>
              </table>
              <TablePagination
                component="div"
                count={totalPages}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={"Registros por página"}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModalCenter"
        tabIndex="1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="card">
              <div className="card-header"></div>
              <span className="span-card-titulo">
                <img src={img_usuario} className="img-card" alt="logo" />{" "}
                Excluir acompanhamento
              </span>
              <div id="triangulo-para-baixo"></div>
              <div className="card-body">
                Tem certeza que deseja excluir o acompanhamento{" "}
                <i>
                  <b className="text-danger">"</b>
                  {nomeModal}
                  <b className="text-danger">"</b>
                </i>
              </div>
              <div className="modal-footer-alert">
                <button
                  className="btn btn-sm btn-51comvc "
                  type="button"
                  data-dismiss="modal"
                >
                  {Times}
                  &nbsp;Cancelar
                </button>
                &nbsp;&nbsp;
                <button
                  className="btn btn-sm btn-51comvc "
                  onClick={(e) => save(e, "delete", 0)}
                  type="button"
                  data-dismiss="modal"
                >
                  Excluir
                  <img src={img_excluir} className="img-btn" alt="logo" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Clientes;
