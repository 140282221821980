import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import { clone } from 'lodash';
import { api } from '../utils/WebApiConfig';
import { TablePagination } from '@material-ui/core';
import {
  handleError,
  handleSuccess,
  handleInfo,
  handleWarn,
} from '../utils/Toastify';
import { useHistory } from 'react-router-dom';
import { Times, FileExcel } from '../utils/Fontawesome';
import axios from 'axios';
import img_usuario from '../imagens/svg/img_usuario.png';
import img_cadastrar from '../imagens/svg/img_cadastrar.png';
import img_excluir from '../imagens/svg/img_excluir.png';
import img_alterar from '../imagens/svg/img_alterar.png';
import img_excluir2 from '../imagens/svg/img_excluir2.png';
import pesquisar from '../imagens/svg/img_lupa.png';
import {
  mask_tel_celular_area,
  mask_CNPJ,
  mask_CPF,
  mask_CEP,
  removeAcento,
  removeAcentoNormalize,
  mask_Numeros,
  ilikeToRegExp,
  mask_data,
} from '../utils/Mascara';
import ReactExport from 'react-export-excel';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const isIncludingString = (string, option) => {
  let result = false;
  if (
    !string ||
    option.label.toString().includes(string) ||
    option.value.toString().includes(string)
  ) {
    result = true;
  }
  return result;
};

const Clientes = () => {
  let history = useHistory();

  const [cid_uf, setCid_uf] = useState([]);
  const [statusCid_uf, setStatusCid_uf] = useState([
    { value: 'TO', label: 'TO - Tocantins' },
    { value: 'SP', label: 'SP - São Paulo' },
    { value: 'SE', label: 'SE - Sergipe' },
    { value: 'SC', label: 'SC - Santa Catarina' },
    { value: 'RS', label: 'RS - Rio Grande do Sul' },
    { value: 'RR', label: 'RR - Roraima' },
    { value: 'RO', label: 'RO - Rondônia' },
    { value: 'RN', label: 'RN - Rio Grande do Norte' },
    { value: 'RJ', label: 'RJ - Rio de Janeiro' },
    { value: 'PR', label: 'PR - Paraná' },
    { value: 'PI', label: 'PI - Piauí' },
    { value: 'PE', label: 'PE - Pernambuco' },
    { value: 'PB', label: 'PB - Paraíba' },
    { value: 'PA', label: 'PA - Pará' },
    { value: 'MT', label: 'MT - Mato Grosso' },
    { value: 'MS', label: 'MS - Mato Grosso do Sul' },
    { value: 'MG', label: 'MG - Minas Gerais' },
    { value: 'MA', label: 'MA - Maranhão' },
    { value: 'GO', label: 'GO - Goiás' },
    { value: 'ES', label: 'ES - Espírito Santo' },
    { value: 'DF', label: 'DF - Distrito Federal' },
    { value: 'CE', label: 'CE - Ceará' },
    { value: 'BA', label: 'BA - Bahia' },
    { value: 'AP', label: 'AP - Amapá' },
    { value: 'AM', label: 'AM - Amazonas' },
    { value: 'AL', label: 'AL - Alagoas' },
    { value: 'AC', label: 'AC - Acre' },
  ]);

  const [bar_id, setBar_id] = useState('');
  const [bar_nomebar, setBar_nomebar] = useState('');
  const [bar_cnpj, setBar_cnpj] = useState('');
  const [bar_endereco, setBar_endereco] = useState('');
  const [bar_numero, setBar_numero] = useState('');
  const [bar_dono_bar, setBar_dono_bar] = useState('');
  const [bar_bairro, setBar_bairro] = useState('');
  const [bar_cidade, setBar_cidade] = useState([]);
  const [statusBar_cidade, setStatusBar_cidade] = useState([]);
  const [bar_campanha, setBar_campanha] = useState([]);
  const [statusBar_campanha, setStatusBar_campanha] = useState([]);
  const [bar_telefone, setBar_telefone] = useState('');
  const [bar_celular, setBar_celular] = useState('');
  const [bar_email, setBar_email] = useState('');
  const [bar_observacao, setBar_observacao] = useState('');
  const [bar_distribuidor, setBar_distribuidor] = useState('');
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [bar_latitude, setBar_latitude] = useState('');
  const [bar_longitude, setBar_longitude] = useState('');
  const [statusBar_distribuidor, setStatusBar_distribuidor] = useState('');
  const [bar_cpf, setBar_cpf] = useState('');
  const [bar_rg, setBar_rg] = useState('');
  const [bar_cep, setBar_cep] = useState('');
  const [barImgSQL, setbarImgSQL] = useState('');
  const [bar_promotor, setBar_promotor] = useState('');
  const [atualiza_fotos, setAtualiza_fotos] = useState('');
  const [statusBar_promotor, setStatusBar_promotor] = useState('');
  const [bar_novo, setBar_novo] = useState(0);
  const [bar_antigo, setBar_antigo] = useState(0);
  const [bar_boteco, setBar_boteco] = useState(0);
  const [bar_ativo, setBar_ativo] = useState(1);
  const [barExcel, setBarExcel] = useState([]);

  const [tempo_status, setTempo_status] = useState(false);
  const [bar_data_cadastro, setBar_data_cadastro] = useState('');

  const [bar_foto1, setBar_foto1] = useState('');

  const [bar_btn, setBar_btn] = useState('');
  const [bares, setBares] = useState([]);
  const [idModal, setIdModal] = useState('');
  const [nomeModal, setNomeModal] = useState('');
  const [idStatusModal, setStatusModal] = useState('');

  // select multiple
  const [searchField, setSearchField] = useState('');
  const [values, setValues] = useState([]);
  const [valuesId, setValuesId] = useState([]);

  // variaveis da paginaçãp
  const [arrayFiltrado, setArrayFiltrado] = useState('');
  const [busca, setBusca] = useState('');
  const [baresPag, setBaresPag] = useState([]);
  const [lastID, setLastID] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const styles = {
    option: (base, value) => {
      return bar_distribuidor ? { ...base } : { display: 'none' };
    },
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    var inicio = newPage * rowsPerPage + 1;
    var fim = inicio + (rowsPerPage - 1);
    /*  console.log(
      "rowsPerPage:" +
        rowsPerPage +
        " newPage " +
        newPage +
        " " +
        inicio +
        " ---- " +
        fim
    );*/
    if (fim > arrayFiltrado.length) {
      fim = arrayFiltrado.length;
    }
    const cidadePag = [];
    for (let i = inicio - 1; i < fim; i++) {
      cidadePag.push(arrayFiltrado[i]);
    }
    setBaresPag(cidadePag);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

    var inicio = 0 * event.target.value + 1;
    var fim = inicio + (event.target.value - 1);
    if (fim > arrayFiltrado.length) {
      fim = arrayFiltrado.length;
    }
    const cidadePag = [];
    for (let i = inicio - 1; i < fim; i++) {
      cidadePag.push(arrayFiltrado[i]);
    }
    setBaresPag(cidadePag);
  };

  const catchResponse = (status) => {
    if (status) {
      if (status === 401) {
        if (document.getElementById('btn_limpar')) {
          document.getElementById('btn_limpar').click();
        }
        handleInfo('Sessão expirada.');
        history.push('/login');
      }
    }
    handleError('Ocorreu um erro inesperado na execução desta operação');
  };

  useEffect(() => {
    if (document.getElementsByClassName('modal-backdrop')[0]) {
      var no = document.getElementsByClassName('modal-backdrop')[0];
      if (no.parentNode) {
        no.parentNode.removeChild(no);
      }
    }

    if (!sessionStorage.getItem('refresh')) {
      sessionStorage.setItem('refresh', 1);
      window.location.reload(false);
    }
    document.getElementById('bar_ativo').checked = true;
    getBar();
    getCampanha();
    getDistribuidora();

    if (sessionStorage.getItem('perfil') !== 'P') {
      getPromotor();
    }
  }, []);

  // select multiple

  useEffect(() => {
    if (tempo_status == true) {
      setTempo_status(false);
      save();
    }
  }, [tempo_status]);

  const multiOnChange = (opt, { option }) => {
    let newOpts = opt;
    let string = searchField.toUpperCase();

    if (option && option.value === 'all') {
      let filteredOptions = clone(statusBar_distribuidor);

      filteredOptions = filteredOptions.filter(
        (filteredOption) =>
          isIncludingString(string, filteredOption) &&
          !newOpts.includes(filteredOption),
      );

      string = null;
      newOpts = newOpts
        .concat(filteredOptions)
        .filter((newOpt) => newOpt.value !== 'all');
    }
    setSearchField(string);
    setValues(newOpts);
    //console.log(newOpts);
  };

  const multiOnInputChange = (string, { action }) => {
    if (action === 'input-change') {
      setSearchField(string);
    }
  };
  const multiFilterOption = ({ label, value }, string) => {
    if (value === 'all') {
      return true;
    } else if (string) {
      return (
        label.includes(string.toUpperCase()) ||
        value.toString().includes(string.toUpperCase())
      );
    } else {
      return true;
    }
  };
  // fim do select multiple
  const getDistribuidora = () => {
    api
      .get(
        'api/distribuidoras?regional=' +
          sessionStorage.getItem('regional') +
          '&select=1',
        {
          headers: {
            Authorization: 'bearer ' + sessionStorage.getItem('token'),
          },
        },
      )
      .then((response) => {
        const arrayDistribuidoras = [];
        if (sessionStorage.getItem('regional') > 1) {
          arrayDistribuidoras.push({
            value: 0,
            label: '0 - NAO DEFINIDO',
          });
        }
        var nomeFantasia = '';
        for (let i = 0; i < response.data.length; i++) {
          nomeFantasia = response.data[i].di_nomefantasia
            ? ' (' + response.data[i].di_nomefantasia + ')'
            : '';
          arrayDistribuidoras.push({
            value: response.data[i].di_id,
            label:
              response.data[i].di_codigo +
              ' - ' +
              response.data[i].di_nome +
              nomeFantasia,
          });
        }

        setStatusBar_distribuidor(arrayDistribuidoras);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };
  const getCampanha = () => {
    api
      .get('api/campanhas', {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
        },
      })
      .then((response) => {
        const arrayCampanhas = [];

        for (let i = 0; i < response.data.length; i++) {
          arrayCampanhas.push({
            value: response.data[i].camp_id,
            label: response.data[i].camp_nome,
          });
        }

        setStatusBar_campanha(arrayCampanhas);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };
  const getBar = () => {
    var getPromotor = '';
    if (sessionStorage.getItem('perfil') == 'P') {
      getPromotor = '&nivel=1&id=' + sessionStorage.getItem('id');
    }

    api
      .get(
        'api/bares?regional=' +
          sessionStorage.getItem('regional') +
          getPromotor,
        {
          headers: {
            Authorization: 'bearer ' + sessionStorage.getItem('token'),
          },
        },
      )
      .then((response) => {
        setBares(response.data);
        setArrayFiltrado(response.data);
        setTotalPages(response.data.length);
        var inicio = 0;
        var fim = 10;
        const cidadePag = [];
        if (fim > response.data.length) {
          fim = response.data.length;
        }
        for (let i = inicio; i < fim; i++) {
          cidadePag.push(response.data[i]);
        }
        setBaresPag(cidadePag);
        let barExcel = [];
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].bar_telefone = mask_tel_celular_area(
            response.data[i].bar_telefone,
          );
          response.data[i].bar_celular = mask_tel_celular_area(
            response.data[i].bar_celular,
          );
          response.data[i].bar_data_cadastro = mask_data(
            response.data[i].bar_data_cadastro,
          );
          response.data[i].bar_cnpj = mask_CNPJ(response.data[i].bar_cnpj);
          response.data[i].bar_cpf = mask_CPF(response.data[i].bar_cpf);
          let distribuidora_nome =
            response.data[i].nome_distribuidora.split(',');

          let Bar_distribuidor = '';

          for (let i = 0; i < distribuidora_nome.length; i++) {
            Bar_distribuidor += RemoveAspasChaves(distribuidora_nome[i]) + ',';
          }

          response.data[i].nome_distribuidora = Bar_distribuidor.substr(
            0,
            Bar_distribuidor.length - 1,
          );
          barExcel.push(response.data[i]);

          setBarExcel(barExcel);
        }
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };
  const getCidade = (cid_uf) => {
    api
      .get('api/cidades?UF=' + cid_uf, {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
        },
      })
      .then((response) => {
        const arrayCidade = [];

        for (let i = 0; i < response.data.length; i++) {
          arrayCidade.push({
            value: response.data[i].cid_id,
            label: response.data[i].cid_municipio,
          });
        }

        setStatusBar_cidade(arrayCidade);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };
  const getPromotor = () => {
    api
      .get('api/usuarios?regional=' + sessionStorage.getItem('regional'), {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
        },
      })
      .then((response) => {
        const arrayPromotor = [];

        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].usu_perfil === 'P') {
            arrayPromotor.push({
              value: response.data[i].usu_id,
              label: response.data[i].usu_nome,
            });
          }
        }

        setStatusBar_promotor(arrayPromotor);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };

  const checkboxAtivo = () => {
    if (bar_ativo === 1) {
      setBar_ativo(0);
    } else {
      setBar_ativo(1);
    }
  };
  const checkboxAntigo = () => {
    if (bar_antigo === 1) {
      setBar_antigo(0);
    } else {
      setBar_antigo(1);
    }
  };
  const checkboxNovo = () => {
    if (bar_novo === 1) {
      setBar_novo(0);
    } else {
      setBar_novo(1);
    }
  };
  const checkboxBoteco = () => {
    if (bar_boteco === 1) {
      setBar_boteco(0);
    } else {
      setBar_boteco(1);
    }
  };
  const handleChangeUF = (cid_uf) => {
    setCid_uf(cid_uf);
    var uf = '';
    switch (cid_uf.value) {
      case 'TO':
        uf = 1;
        break;
      case 'SP':
        uf = 2;
        break;
      case 'SE':
        uf = 3;
        break;
      case 'SC':
        uf = 4;
        break;
      case 'RS':
        uf = 5;
        break;
      case 'RR':
        uf = 6;
        break;
      case 'RO':
        uf = 7;
        break;
      case 'RN':
        uf = 8;
        break;
      case 'RJ':
        uf = 9;
        break;
      case 'PR':
        uf = 10;
        break;
      case 'PI':
        uf = 11;
        break;
      case 'PE':
        uf = 12;
        break;
      case 'PB':
        uf = 13;
        break;
      case 'PA':
        uf = 14;
        break;
      case 'MT':
        uf = 15;
        break;
      case 'MS':
        uf = 16;
        break;
      case 'MG':
        uf = 17;
        break;
      case 'MA':
        uf = 18;
        break;
      case 'GO':
        uf = 19;
        break;
      case 'ES':
        uf = 20;
        break;
      case 'DF':
        uf = 21;
        break;
      case 'CE':
        uf = 22;
        break;
      case 'BA':
        uf = 23;
        break;
      case 'AP':
        uf = 24;
        break;
      case 'AM':
        uf = 25;
        break;
      case 'AL':
        uf = 26;
        break;
      case 'AC':
        uf = 27;
        break;
    }
    getCidade(uf);
  };

  const handleChange = (bar_cidade) => {
    setBar_cidade(bar_cidade);
  };
  const handleChangePromotor = (bar_promotor) => {
    setBar_promotor(bar_promotor);
  };

  const handleChangeCampanha = (bar_campanha) => {
    setBar_campanha(bar_campanha);
  };
  const RemoveAspasChaves = (value) => {
    value = value.replace(/"/gi, '');
    value = value.replace('}', '');
    value = value.replace('{', '');
    return value;
  };

  const deletar_upload_imagem = (key) => {
    var bar_foto1 = [];

    var atualiza_fotos = [];
    var els = document.querySelectorAll('output input');
    // var els = document.getElementsByClassName('checkbox_imagem');
    //  console.log(els);

    //console.log(els.length);
    for (var i = 0; i < els.length; i++) {
      if (els[i].checked) {
        //  console.log(els[i].value);
        bar_foto1.push(els[i].value);
      }
      if (!els[i].checked) {
        atualiza_fotos.push(els[i].value);
      }
    }
    setAtualiza_fotos(atualiza_fotos);

    // console.log(prod_caminhofoto);
    let data = {
      bar_foto1: bar_foto1,
    };
    //  console.log(JSON.stringify(data));
    // return false;
    api({
      method: 'DELETE',
      url: 'api/bares/' + bar_id,
      data: data,
      headers: {
        Authorization: 'bearer ' + sessionStorage.getItem('token'),
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        //   console.log(`${loaded}kb of ${total}kb | ${percent}%`);

        if (percent < 100) {
          setUploadPercentage(percent);
        }
      },
    })
      .then((response) => {
        handleSuccess(response.headers.statusmessage);
        setUploadPercentage(0);
        getBar();

        var barImgSQL = '';
        var spans = '';
        //  console.log(atualiza_fotos)
        for (let i = 0; i < atualiza_fotos.length; i++) {
          barImgSQL += barImgSQL = atualiza_fotos[i] + ',';
          if (atualiza_fotos[i] !== null) {
            spans +=
              '<input type="checkbox" name="checkbox_imagem[]" value="' +
              atualiza_fotos[i] +
              '" id="' +
              atualiza_fotos[i] +
              '" />';
            spans += '<label for=' + atualiza_fotos[i] + '>';
            spans +=
              '<img class="thumb-upload" style="margin-left:5px; max-width: 100px;max-height: 100px;" id=' +
              atualiza_fotos[i] +
              ' src=' +
              '../imagens/bar/' +
              bar_id +
              '/' +
              atualiza_fotos[i] +
              ' />';
            spans += '</label>';
          }
        }
        setbarImgSQL(barImgSQL);

        document.getElementById('list').innerHTML = spans;

        //    this.Get_produto();
        // this.Limpar();
      })
      .catch((error) => {
        //handleError(error.response.data.message);
        //  console.log(error);
        return false;
      });
  };
  const editar = (bar) => {
    //  console.log(cliente);
    document.getElementById('bar_nomebar').focus();

    var distribuidora_id = bar.id_distribuidora.split(',');
    var distribuidora_nome = bar.nome_distribuidora.split(',');

    var Bar_distribuidor = [];
    var ValuesId = [];
    for (let i = 0; i < distribuidora_id.length; i++) {
      Bar_distribuidor.push({
        value: parseInt(mask_Numeros(distribuidora_id[i])),
        label: RemoveAspasChaves(distribuidora_nome[i]),
      });
      ValuesId.push(parseInt(parseInt(mask_Numeros(distribuidora_id[i]))));
    }

    setValuesId(ValuesId);
    setValues(Bar_distribuidor);
    setBar_cnpj(bar.bar_cnpj);
    setBar_cpf(bar.bar_cpf);
    setBar_numero(bar.bar_numero);
    setBar_endereco(bar.bar_endereco);
    setBar_nomebar(bar.bar_nomebar);
    setBar_id(bar.bar_id);
    setBar_celular(bar.bar_celular);
    setBar_email(bar.bar_email);
    setBar_rg(bar.bar_rg);
    setBar_cep(bar.bar_cep);
    setBar_celular(bar.bar_celular);
    setBar_telefone(bar.bar_telefone);
    setBar_cidade(bar.bar_cidade);
    setBar_bairro(bar.bar_bairro);
    setBar_dono_bar(bar.bar_dono_bar);
    setBar_ativo(bar.bar_ativo);
    setBar_foto1(bar.bar_foto1);
    /*
    setBar_novo(bar.bar_novo);
    setBar_antigo(bar.bar_antigo);
    setBar_boteco(bar.bar_boteco);*/
    setBar_data_cadastro(bar.bar_cadastro);
    setBar_observacao(bar.bar_observacao);
    setBar_campanha({ value: bar.bar_campanha, label: bar.camp_nome });
    setCid_uf({ value: bar.cid_uf, label: bar.cid_uf });
    setbarImgSQL(bar.bar_foto1);
    if (bar.cid_uf) {
      getCidade(bar.cid_uf);
    }

    if (sessionStorage.getItem('perfil') == 'P') {
      setBar_promotor({
        value: sessionStorage.getItem('id'),
        label: sessionStorage.getItem('nome'),
      });
      setStatusBar_promotor([
        {
          value: sessionStorage.getItem('id'),
          label: sessionStorage.getItem('nome'),
        },
      ]);
    } else {
      setBar_promotor({
        value: bar.bar_promotor,
        label: bar.usu_nome,
      });
    }

    setBar_campanha({
      value: bar.camp_id,
      label: bar.camp_nome,
    });
    /* setBar_distribuidor({
      value: bar.bar_distribuidor,
      label: bar.di_codigo + " - " + bar.di_nome,
    });*/
    setBar_cidade({
      value: bar.bar_cidade,
      label: bar.cid_municipio,
    });

    if (bar.bar_ativo == 1) {
      document.getElementById('bar_ativo').checked = true;
    } else {
      document.getElementById('bar_ativo').checked = false;
    }
    /*   if (bar.bar_antigo == 1) {
      document.getElementById("bar_antigo").checked = true;
    } else {
      document.getElementById("bar_antigo").checked = false;
    }
    if (bar.bar_novo == 1) {
      document.getElementById("bar_novo").checked = true;
    } else {
      document.getElementById("bar_novo").checked = false;
    }
    if (bar.bar_boteco == 1) {
      document.getElementById("bar_boteco").checked = true;
    } else {
      document.getElementById("bar_boteco").checked = false;
    }*/

    var spans = '';

    // let imagem = dominio+"/"+produto.prod_caminhofoto;
    if (bar.bar_foto1) {
      setBar_btn(1);
      var bar_foto1 = bar.bar_foto1.split(',');
      for (let i = 0; i < bar_foto1.length - 1; i++) {
        if (bar_foto1[i] !== null) {
          spans +=
            '<input type="checkbox" name="checkbox_imagem[]" value="' +
            bar_foto1[i] +
            '" id="' +
            bar_foto1[i] +
            '" />';
          spans += '<label for=' + bar_foto1[i] + '>';
          spans +=
            '<img class="thumb-upload" style="margin-left:5px; max-width: 100px;max-height: 100px;" id=' +
            bar_foto1[i] +
            ' src=' +
            '../imagens/bar/' +
            bar.bar_id +
            '/' +
            bar_foto1[i] +
            ' />';
          spans += '</label>';
        }
      }

      document.getElementById('list').innerHTML = spans;
    } else {
      document.getElementById('list').innerHTML = '';
      setBar_btn(0);
    }
  };

  const viacep = (value) => {
    if (value) {
      if (value.length == 10) {
        axios
          .get('https://viacep.com.br/ws/' + mask_Numeros(value) + '/json/')
          .then((response) => {
            if (response.data.ibge) {
              // setStatusBar_cidade(arrayCidade);
              setBar_bairro(removeAcento(response.data.bairro));
              setBar_endereco(removeAcento(response.data.logradouro));
              setCid_uf({
                value: response.data.uf,
                label: response.data.uf,
              });
              if (response.data.uf) {
                getCidade(response.data.uf);
              }
              setBar_cidade({
                value: response.data.ibge,
                label: removeAcento(response.data.localidade),
              });
            }
          })
          .catch((error) => {
            if (error.response) {
              catchResponse(error.response.status);
            }
          });
      }
    }
    return value;
  };

  const dadosModal = (nome, id, status) => {
    setNomeModal(nome);
    setIdModal(id);
    setStatusModal(status);
  };

  const GetGeoLocation = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    //  var municipio = String(pm_cidade.label);
    //  municipio = municipio.replace(" / ", ",");
    var numero = '';
    if (bar_numero > 0) {
      numero = bar_numero + ',';
    }
    axios({
      method: 'get',
      url:
        'https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyBI7LOX1L6tbGwOeFcixa4rjWx2zvaiuqE&sensor=false&address=' +
        bar_endereco +
        ',' +
        numero +
        bar_bairro +
        ',' +
        bar_cidade.label +
        ',' +
        cid_uf.label +
        '&components=country:BR',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        setBar_longitude(response.data.results[0].geometry.location.lng);
        setBar_latitude(response.data.results[0].geometry.location.lat);
        setTempo_status(true);
      })
      .catch((error) => {
        console.log(error);
        handleWarn('Falha ao adquirir geolocalização');
        setBar_longitude(0);
        setBar_latitude(0);
        setTempo_status(true);
        /* if (error) {
          catchResponse(error);
        }*/
      });
  };
  const save = (e, metodo, status) => {
    let data, method;
    if (metodo) {
      method = 'delete';
    } else {
      if (!(bar_cnpj || bar_cpf || bar_rg)) {
        handleInfo('insera pelo menos um CPF ou CNPJ ou RG');
        return false;
      }
      var barDistribuidorIds = [];

      for (let i = 0; i < values.length; i++) {
        barDistribuidorIds.push(values[i].value);
      }
      if (!bar_id) {
        method = 'post';
      } else {
        method = 'put';
      }
    }

    var data_uri = document.querySelectorAll('output span img');
    // console.log(data_uri);
    var bar_foto1 = [];

    for (let i = 0; i < data_uri.length; i++) {
      bar_foto1.push(data_uri[i].src);
    }

    if (method === 'put') {
      //  console.log(barImgSQL);
      var ImgSQL = '';
      if (barImgSQL) {
        ImgSQL = barImgSQL.match(/,/gi).length;
      } else {
        ImgSQL = 0;
      }

      if (ImgSQL + bar_foto1.length > 4) {
        handleInfo('Cada Bar aceita no maximo até 4 imagens');
        return false;
      }
      var url = '';
      data = {
        bar_id: bar_id,
        bar_nomebar: bar_nomebar,
        bar_cnpj: bar_cnpj,
        bar_cpf: bar_cpf,
        bar_endereco: bar_endereco,
        bar_numero: bar_numero,
        bar_dono_bar: bar_dono_bar,
        bar_bairro: bar_bairro,
        bar_telefone: bar_telefone,
        bar_celular: bar_celular,
        bar_email: bar_email,
        bar_rg: bar_rg,
        bar_cep: bar_cep,
        bar_cidade: bar_cidade.value,
        bar_distribuidor: barDistribuidorIds,
        bar_promotor: bar_promotor.value,
        bar_novo: bar_novo,
        bar_antigo: bar_antigo,
        bar_boteco: bar_boteco,
        bar_cadastro: bar_data_cadastro,
        bar_ativo: bar_ativo,
        bar_foto1: bar_foto1,
        bar_observacao: bar_observacao,
        bar_campanha: bar_campanha.value,
        barImgSQL: barImgSQL,
        bar_latitude: bar_latitude,
        bar_longitude: bar_longitude,
        cid_uf: cid_uf.value,
        cid_municipio: bar_cidade.label,
      };

      url = 'api/bares/' + bar_id;
    } else if (method === 'post') {
      data = {
        bar_nomebar: bar_nomebar,
        bar_cnpj: bar_cnpj,
        bar_cpf: bar_cpf,
        bar_endereco: bar_endereco,
        bar_numero: bar_numero,
        bar_dono_bar: bar_dono_bar,
        bar_bairro: bar_bairro,
        bar_telefone: bar_telefone,
        bar_celular: bar_celular,
        bar_email: bar_email,
        bar_rg: bar_rg,
        bar_cep: bar_cep,
        bar_cidade: bar_cidade.value,
        bar_distribuidor: barDistribuidorIds,
        bar_promotor: bar_promotor.value,
        bar_novo: bar_novo,
        bar_antigo: bar_antigo,
        bar_boteco: bar_boteco,
        bar_cadastro: bar_data_cadastro,
        bar_ativo: bar_ativo,
        bar_foto1: bar_foto1,
        bar_acompanhamentovendas: 0,
        bar_observacao: bar_observacao,
        bar_campanha: bar_campanha.value,
        bar_latitude: bar_latitude,
        bar_longitude: bar_longitude,
        cid_uf: cid_uf.value,
        cid_municipio: bar_cidade.label,
      };
      url = 'api/bares';
    } else if (method === 'delete') {
      //    url = url + "/" + idModal + "/" + status;
      url = 'api/bares/' + idModal;
      data = {
        id: idModal,
        status: status,
      };
      setNomeModal('');
      setIdModal('');
    }
    //  console.log(values);
    //   console.log(data);
    //   return false;
    api({
      method: method,
      url: url,
      data: data,
      headers: {
        Authorization: 'bearer ' + sessionStorage.getItem('token'),
      },
    })
      .then((response) => {
        handleSuccess(response.headers.statusmessage);
        if (document.getElementById('btn_limpar')) {
          document.getElementById('btn_limpar').click();
        }
        getBar();
      })
      .catch((error) => {
        //  catchResponse();

        catchResponse(error.response.status);
        console.log(error);
      });
  };

  const limpar = () => {
    setBar_distribuidor([]);
    setBar_cidade([]);

    if (sessionStorage.getItem('perfil') == 'P') {
      setBar_promotor({
        value: sessionStorage.getItem('id'),
        label: sessionStorage.getItem('nome'),
      });
      setStatusBar_promotor([
        {
          value: sessionStorage.getItem('id'),
          label: sessionStorage.getItem('nome'),
        },
      ]);
    } else {
      setBar_promotor([]);
    }

    setBar_campanha([]);
    setValues([]);
    setValuesId([]);
    setBar_email('');
    setBar_celular('');
    setBar_telefone('');
    setBar_bairro('');
    setBar_dono_bar('');
    setBar_numero('');
    setBar_endereco('');
    setBar_cnpj('');
    setBar_nomebar('');
    setBar_id('');

    setBar_boteco(0);
    setBar_antigo(0);
    setBar_novo(0);
    setBar_cep('');
    setBar_rg('');
    setBar_cpf('');
    setBar_observacao('');
    setBar_data_cadastro('');
    setBar_ativo(1);
    document.getElementById('list').innerHTML = '';
    /*  document.getElementById("bar_antigo").checked = false;
    document.getElementById("bar_novo").checked = false;
    document.getElementById("bar_boteco").checked = false;*/
    document.getElementById('prod_foto').value = '';
    setNomeModal('');
    setIdModal('');
  };
  const _handleImageChange = (e) => {
    e.preventDefault();

    document.getElementById('list').innerHTML = '';

    var fileInput = document.getElementById('prod_foto');
    var fileList = [];

    if (fileInput.files.length > 4) {
      handleError('No máximo 4 imagem');
      document.getElementById('prod_foto').value = '';
      return false;
    }
    for (var i = 0; i < fileInput.files.length; i++) {
      fileList.push(fileInput.files[i]);
    }

    for (var i = 0, f; (f = fileList[i]); i++) {
      //    this.GerarDataURI(f, i);
      //  console.log(JSON.stringify(f.name) + " " + JSON.stringify(fileList[i]));

      // Only process image files.
      if (!f.type.match('image.*')) {
        continue;
      }

      var reader = new FileReader();

      reader.onload = (function (theFile) {
        return function (e) {
          (function () {
            var image = new Image();
            image.addEventListener(
              'load',
              function () {
                // tamanho original

                var canvas = document.createElement('canvas'),
                  ctx = canvas.getContext('2d');
                // coordenadas origem (source)

                // tamanho destino
                var MAX_WIDTH = 1200;
                var MAX_HEIGHT = 1200;
                var width = this.width;
                var height = this.height;

                if (width > height) {
                  if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                  }
                } else {
                  if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                  }
                }
                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(this, 0, 0, width, height);

                let ext = theFile.name.split('.');

                //   var dataurl = canvas.toDataURL("image/" + ext[ext.length - 1]);
                var dataurl = canvas.toDataURL('image/jpeg');

                //  console.log(dataurl);
                var span = document.createElement('span');
                span.innerHTML = [
                  '<img class="img_data_uri" style="margin-left:5px; max-width: 150px;max-height: 150px;" name="img_data_uris" src="',
                  dataurl,
                  '" title="',
                  escape(theFile.name),
                  '"/>',
                ].join('');
                document.getElementById('list').insertBefore(span, null);
              },
              false,
            );
            image.src = e.target.result;
          })();
        };
      })(f);

      // Read in the image file as a data URL.
      reader.readAsDataURL(f);
    }
  };
  const buscar = async () => {
    //  console.log(busca);
    let filtrado,
      filtradoDistribuidora,
      filtradocidade,
      filtradoUF,
      filtradoEstado,
      filtradoPromotor,
      filtradoDono;
    if (arrayFiltrado) {
      if (!busca) {
        filtrado = bares;
      } else {
        //console.log(arrayFiltrado);
        filtrado = arrayFiltrado.filter(function (obj) {
          return ilikeToRegExp('%' + busca + '%').test(obj.bar_nomebar);
        });

        filtradoDistribuidora = arrayFiltrado.filter(function (obj) {
          return ilikeToRegExp('%' + busca + '%').test(obj.nome_distribuidora);
        });
        filtrado = filtrado.concat(filtradoDistribuidora);
        filtradoDono = arrayFiltrado.filter(function (obj) {
          return ilikeToRegExp('%' + busca + '%').test(obj.bar_dono_bar);
        });
        filtrado = filtrado.concat(filtradoDono);
        filtradocidade = arrayFiltrado.filter(function (obj) {
          return ilikeToRegExp('%' + busca + '%').test(obj.cid_municipio);
        });
        filtrado = filtrado.concat(filtradocidade);
        filtradoUF = arrayFiltrado.filter(function (obj) {
          return ilikeToRegExp('%' + busca + '%').test(obj.cid_uf);
        });
        filtrado = filtrado.concat(filtradoUF);
        filtradoEstado = arrayFiltrado.filter(function (obj) {
          return ilikeToRegExp('%' + busca + '%').test(obj.cid_uf);
        });
        filtrado = filtrado.concat(filtradoEstado);
        filtradoPromotor = arrayFiltrado.filter(function (obj) {
          return ilikeToRegExp('%' + busca + '%').test(obj.usu_nome);
        });
        filtrado = filtrado.concat(filtradoPromotor);

        filtrado = filtrado.filter(function (a) {
          return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
        }, Object.create(null));
      }

      var inicio = 0 * rowsPerPage + 1;
      var fim = inicio + rowsPerPage - 1;
      if (!filtrado.length) {
        filtrado = bares;
        handleInfo('Nenhum registro encontrado.');
      }
      if (fim > filtrado.length) {
        fim = filtrado.length;
      }
      const cidadePag = [];
      for (let i = inicio - 1; i < fim; i++) {
        cidadePag.push(filtrado[i]);
      }

      await setBaresPag(cidadePag);
      await setTotalPages(filtrado.length);
      await setArrayFiltrado(filtrado);
      await setPage(0);
    } else {
      handleInfo('Nenhum registro encontrado');
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div
          class="modal fade bd-example-modal-xl"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-xl">
            <div class="modal-content">
              <div className="card">
                <div className="card-header"></div>
                <span className="span-card-titulo">
                  <img src={img_usuario} className="img-card" alt="logo" />
                  Cadastro de Bar
                </span>
                <div id="triangulo-para-baixo"></div>
                <div className="card-body">
                  <form id="form_cliente" onSubmit={(e) => GetGeoLocation(e)}>
                    <div className="form-row mb-3">
                      <div className="col-md-3 col-sm-4 mb-3">
                        <label htmlFor="bar_nomebar">
                          Bar:<strong className="fild-required">*</strong>
                        </label>
                        <input
                          type="text"
                          maxLength="100"
                          className="form-control"
                          id="bar_nomebar"
                          placeholder="Digite o Nome"
                          onChange={(e) =>
                            setBar_nomebar(e.target.value.toUpperCase())
                          }
                          value={bar_nomebar}
                          required
                        />
                      </div>
                      <div className="col-md-3 col-sm-4 mb-3">
                        <label htmlFor="bar_dono_bar">
                          Proprietario:
                          <strong className="fild-required">*</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="bar_dono_bar"
                          placeholder="Nome do dono do Bar"
                          onChange={(e) =>
                            setBar_dono_bar(e.target.value.toUpperCase())
                          }
                          value={bar_dono_bar}
                          required
                          maxLength="30"
                        />
                      </div>
                      <div className="col-md-3 col-sm-4 mb-3">
                        <label htmlFor="bar_cnpj">CNPJ:</label>
                        <input
                          type="text"
                          className="form-control"
                          id="bar_cnpj"
                          placeholder="CNPJ"
                          onChange={(e) =>
                            setBar_cnpj(mask_CNPJ(e.target.value))
                          }
                          value={bar_cnpj}
                          maxLength="18"
                        />
                      </div>
                      <div className="col-md-3 col-sm-4 mb-3">
                        <label htmlFor="bar_cpf">CPF:</label>
                        <input
                          type="text"
                          className="form-control"
                          id="bar_cpf"
                          placeholder="CPF"
                          onChange={(e) => setBar_cpf(mask_CPF(e.target.value))}
                          value={bar_cpf}
                          maxLength="14"
                        />
                      </div>
                      <div className="col-md-3 col-sm-4 mb-3">
                        <label htmlFor="bar_rg">RG:</label>
                        <input
                          type="text"
                          className="form-control"
                          id="bar_rg"
                          placeholder="Digite seu RG"
                          onChange={(e) => setBar_rg(e.target.value)}
                          value={bar_rg}
                          maxLength="15"
                        />
                      </div>
                      <div className="col-md-3 col-sm-4 mb-3">
                        <label htmlFor="bar_cep">
                          CEP:<strong className="fild-required">*</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="bar_cep"
                          maxLength="35"
                          required
                          placeholder="Digite o CEP"
                          autoComplete="new-password"
                          onChange={(e) =>
                            setBar_cep(viacep(mask_CEP(e.target.value)))
                          }
                          value={bar_cep}
                        />
                      </div>

                      <div className="col-md-3 col-sm-4 mb-3">
                        <label htmlFor="bar_endereco">
                          Endereço:<strong className="fild-required">*</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="bar_endereco"
                          placeholder="Digite seu Endereço"
                          maxLength="150"
                          autoComplete="new-password"
                          onChange={(e) =>
                            setBar_endereco(
                              removeAcentoNormalize(
                                e.target.value.toUpperCase(),
                              ),
                            )
                          }
                          value={bar_endereco}
                          required
                        />
                      </div>
                      <div className="col-md-3 col-sm-4 mb-3">
                        <label htmlFor="bar_numero">
                          Número:<strong className="fild-required">*</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="bar_numero"
                          placeholder="N°"
                          maxLength="20"
                          autoComplete="new-password"
                          onChange={(e) => setBar_numero(e.target.value)}
                          value={bar_numero}
                          required
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <label htmlFor="bar_bairro">
                          Bairro:<strong className="fild-required">*</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="bar_bairro"
                          placeholder="Digite o seu Bairro"
                          maxLength="80"
                          required
                          autoComplete="new-password"
                          onChange={(e) =>
                            setBar_bairro(e.target.value.toUpperCase())
                          }
                          value={bar_bairro}
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <label htmlFor="cid_uf">
                          Estado / UF:
                          <strong className="fild-required">*</strong>
                        </label>
                        <Select
                          id="cid_uf"
                          value={cid_uf}
                          onChange={handleChangeUF}
                          options={statusCid_uf}
                        />
                        <input
                          style={{
                            top: '43px',
                            position: 'absolute',
                            zIndex: '-1',
                          }}
                          type="password"
                          id="cid_uf_html5_validate"
                          autoComplete="new-password"
                          value={cid_uf}
                          required
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <label htmlFor="bar_cidade">
                          Cidade:<strong className="fild-required">*</strong>
                        </label>
                        <Select
                          id="bar_cidade"
                          value={bar_cidade}
                          onChange={handleChange}
                          options={statusBar_cidade}
                          placeholder={<div>Selecione o estado antes</div>}
                        />
                        <input
                          style={{
                            top: '43px',
                            position: 'absolute',
                            zIndex: '-1',
                          }}
                          type="password"
                          id="bar_cidade_html5_validate"
                          autoComplete="new-password"
                          value={bar_cidade}
                          required
                        />
                      </div>

                      <div className="col-md-3 mb-3">
                        <label htmlFor="bar_telefone">Telefone/Celular:</label>
                        <input
                          type="text"
                          className="form-control"
                          id="bar_telefone"
                          placeholder="Telefone com DDD"
                          autoComplete="new-password"
                          maxLength="20"
                          onChange={(e) =>
                            setBar_telefone(
                              mask_tel_celular_area(e.target.value),
                            )
                          }
                          value={bar_telefone}
                        />
                        {
                          // regra para deixar opcional telefone ou celular cadastrados desde que um seja preenchido
                          /* <input
                          style={{
                            top: "43px",
                            position: "absolute",
                            zIndex: "-1",
                          }}
                          type="password"
                          id="bar_telefone_html5_validate"
                          autoComplete="new-password"
                          value={bar_telefone ? bar_telefone : bar_celular}
                          required
                        />*/
                        }
                      </div>
                      <div className="col-md-3 mb-3">
                        <label htmlFor="bar_celular">
                          Telefone/Celular:
                          <strong className="fild-required">*</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="bar_celular"
                          placeholder="Celular com DDD"
                          autoComplete="new-password"
                          maxLength="20"
                          onChange={(e) =>
                            setBar_celular(
                              mask_tel_celular_area(e.target.value),
                            )
                          }
                          value={bar_celular}
                          required
                        />{' '}
                        {
                          // regra para deixar opcional telefone ou celular cadastrados desde que um seja preenchido
                          /*  <input
                        style={{
                          top: "43px",
                          position: "absolute",
                          zIndex: "-1",
                        }}
                        type="password"
                        id="bar_celular_html5_validate"
                        autoComplete="new-password"
                        value={bar_celular ? bar_celular  : bar_telefone}
                        required
                      />*/
                        }
                      </div>
                      <div className="col-md-3 mb-3">
                        <label htmlFor="bar_email">Email:</label>
                        <input
                          type="email"
                          className="form-control"
                          id="bar_email"
                          maxLength="100"
                          placeholder="Digite o seu Email"
                          autoComplete="new-password"
                          onChange={(e) => setBar_email(e.target.value)}
                          value={bar_email}
                        />
                      </div>

                      <div className="col-md-3 mb-3">
                        <label htmlFor="bar_promotor">
                          Promotor:<strong className="fild-required">*</strong>
                        </label>
                        <Select
                          id="bar_promotor"
                          value={bar_promotor}
                          onChange={handleChangePromotor}
                          options={statusBar_promotor}
                        />
                        <input
                          style={{
                            top: '43px',
                            position: 'absolute',
                            zIndex: '-1',
                            width: '5px',
                          }}
                          type="password"
                          id="bar_promotor_html5_validate"
                          autoComplete="new-password"
                          value={bar_promotor}
                          required
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <label htmlFor="bar_campanha">
                          Campanha:<strong className="fild-required">*</strong>
                        </label>
                        <Select
                          id="bar_campanha"
                          value={bar_campanha}
                          onChange={handleChangeCampanha}
                          options={statusBar_campanha}
                        />
                        <input
                          style={{
                            top: '43px',
                            position: 'absolute',
                            zIndex: '-1',
                            width: '5px',
                          }}
                          type="password"
                          id="bar_campanha_html5_validate"
                          autoComplete="new-password"
                          value={bar_campanha}
                          required
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <label htmlFor="bar_data_cadastro">
                          Data de cadastro:
                          <strong className="fild-required">*</strong>
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="bar_data_cadastro"
                          placeholder="Digite o seu Bairro"
                          maxLength="80"
                          required
                          autoComplete="new-password"
                          onChange={(e) => setBar_data_cadastro(e.target.value)}
                          value={bar_data_cadastro}
                        />
                      </div>
                      <div className="col-md-2 col-6 mb-3">
                        <label htmlFor="bar_ativo">
                          {bar_ativo === 1 ? (
                            <b className="text-success">Status</b>
                          ) : (
                            <b className="text-secondary">Status</b>
                          )}
                        </label>
                        <div class="material-switch   pull-right">
                          <input
                            onClick={(e) => checkboxAtivo()}
                            id="bar_ativo"
                            name="ativo"
                            type="checkbox"
                          />
                          <label
                            htmlFor="bar_ativo"
                            class="badge-success"
                          ></label>
                        </div>
                      </div>
                      <div className="col-md-12  col-12 mb-3">
                        <label htmlFor="bar_distribuidor">
                          Distribuidor:
                          <strong className="fild-required">*</strong>
                        </label>
                        <div>
                          <Select
                            filterOption={multiFilterOption}
                            hideSelectedOptions
                            onInputChange={multiOnInputChange}
                            onChange={multiOnChange}
                            options={statusBar_distribuidor}
                            isMulti
                            value={values}
                          />
                        </div>
                        <input
                          style={{
                            top: '43px',
                            position: 'absolute',
                            zIndex: '-1',
                          }}
                          type="password"
                          id="bar_distribuidor_html5_validate"
                          autoComplete="new-password"
                          value={values}
                          required
                        />
                      </div>

                      <div className="col-md-12  col-12 mb-3">
                        <label htmlFor="bar_observacao">
                          Descrição do Bar:
                        </label>
                        <br />
                        <textarea
                          id="bar_observacao"
                          value={bar_observacao}
                          className="form-control"
                          placeholder="Descrição do Bar"
                          onChange={(e) =>
                            setBar_observacao(removeAcento(e.target.value))
                          }
                        />
                      </div>

                      <div className="col-md-12 mb-3">
                        <label htmlFor="prod_foto">
                          <b>Imagens:</b>{' '}
                          {bar_foto1.length > 0 ? (
                            ''
                          ) : (
                            <strong className="fild-required">*</strong>
                          )}
                        </label>
                        <br />
                        {bar_foto1.length > 0 ? (
                          <>
                            {' '}
                            <input
                              type="file"
                              name="prod_foto[]"
                              style={{
                                backgroundColor: '#ecf0f4',
                                border: '0px',
                              }}
                              accept=".jpg, .jpeg, .png, .webp"
                              id="prod_foto"
                              multiple
                              onChange={(e) => _handleImageChange(e)}
                            />{' '}
                          </>
                        ) : (
                          <>
                            <input
                              required
                              type="file"
                              name="prod_foto[]"
                              style={{
                                backgroundColor: '#ecf0f4',
                                border: '0px',
                              }}
                              accept=".jpg, .jpeg, .png, .webp"
                              id="prod_foto"
                              multiple
                              onChange={(e) => _handleImageChange(e)}
                            />
                          </>
                        )}
                      </div>
                    </div>
                    {uploadPercentage > 0 ? (
                      <>
                        <br />
                        <div className="progress">
                          <div
                            className="progress-bar progress-bar-striped progress-bar-animated"
                            role="progressbar"
                            aria-valuenow={uploadPercentage}
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: uploadPercentage + '%' }}
                          >
                            <b>{uploadPercentage}</b>
                          </div>
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                    <img id="output"></img>
                    <output id="list"></output>
                    <div className="text-left mb-3 mt-3 ">
                      {bar_id ? (
                        <>
                          <button
                            className="btn btn-sm btn-51comvc "
                            type="submit"
                            id="btnAlterar"
                          >
                            Alterar
                            <img
                              src={img_cadastrar}
                              className="img-btn"
                              alt="logo"
                            />
                          </button>
                          {bar_btn == 1 ? (
                            <>
                              &nbsp;&nbsp;
                              <button
                                type="button"
                                className="btn btn-sm btn-danger "
                                onClick={(e) => deletar_upload_imagem(e)}
                              >
                                Deletar imagens seleciondas &nbsp;
                                <i className="fas fa-save"></i>{' '}
                              </button>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <button
                          className="btn btn-sm btn-51comvc "
                          type="submit"
                          id="btnCadastrar"
                        >
                          Cadastrar
                          <img
                            src={img_cadastrar}
                            className="img-btn"
                            alt="logo"
                          />
                        </button>
                      )}{' '}
                      &nbsp;&nbsp;{' '}
                      <button
                        className="btn btn-sm btn-51comvc "
                        onClick={(e) => limpar(e)}
                        type="button"
                        id="btn_limpar"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        Fechar
                        <img src={img_excluir} className="img-btn" alt="logo" />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header"></div>
          <span className="span-card-titulo">
            <img src={img_usuario} className="img-card" alt="logo" />
            Lista de Bares
          </span>
          <div id="triangulo-para-baixo"></div>
          <div className="card-body card-body-lista">
            <form>
              <div className="form-group">
                <div className="form-row">
                  <div className="col-md-5 col-12">
                    <div className="row">
                      <div
                        className="col-md-10  col-10 col-sm-10"
                        style={{ paddingRight: '0px' }}
                      >
                        <label htmlFor="filtro">Filtrar Tabela:</label>
                        <input
                          id="filtro"
                          className="form-control"
                          style={{ display: 'inline' }}
                          type="text"
                          autoComplete="new-password"
                          placeholder="Termo..."
                          onChange={(e) =>
                            setBusca(removeAcento(e.target.value))
                          }
                          value={busca}
                        />
                      </div>
                      <div
                        className="col-md-1 col-1  col-sm-1"
                        style={{ paddingLeft: '0px' }}
                      >
                        {' '}
                        <label htmlFor="btn_visualizar">&nbsp;&nbsp;</label>
                        <button
                          style={{ display: 'inline' }}
                          type="button"
                          className="btn  bg-51comvc bl-3"
                          id="btn_visualizar"
                          onClick={(e) => buscar(e)}
                        >
                          <img src={pesquisar} className="img-btn" alt="logo" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2">
                    <label htmlFor="btn_cadastar">&nbsp;&nbsp;&nbsp;</label>
                    <button
                      type="button"
                      className="btn  bg-51comvc btn-block "
                      id="btn_cadastar"
                      onClick={(e) => limpar(e)}
                      data-toggle="modal"
                      data-target=".bd-example-modal-xl"
                    >
                      Cadastrar
                      <img src={img_cadastrar} className="img-btn" alt="logo" />
                    </button>
                  </div>
                  <div className="col-md-2">
                    <label htmlFor="export-excel">&nbsp;&nbsp;&nbsp;</label>
                    <ExcelFile
                      element={
                        <button
                          type="button"
                          className="btn  bg-51comvc btn-block "
                          id="export-excel"
                        >
                          Export Excel&nbsp;
                          {FileExcel}
                        </button>
                      }
                    >
                      <ExcelSheet data={barExcel} name="Employees">
                        <ExcelColumn label="Bar" value="bar_nomebar" />
                        <ExcelColumn
                          label="Proprietario"
                          value="bar_dono_bar"
                        />
                        <ExcelColumn label="CNPJ" value="bar_cnpj" />
                        <ExcelColumn label="CPF" value="bar_cpf" />
                        <ExcelColumn label="RG" value="bar_rg" />
                        <ExcelColumn label="CEP" value="bar_cep" />
                        <ExcelColumn label="Endereço" value="bar_endereco" />
                        <ExcelColumn label="Número" value="bar_numero" />
                        <ExcelColumn label="Bairro" value="bar_bairro" />
                        <ExcelColumn label="UF" value="cid_uf" />
                        <ExcelColumn label="Cidade" value="cid_municipio" />
                        <ExcelColumn label="Telefone" value="bar_telefone" />
                        <ExcelColumn label="Celular" value="bar_celular" />
                        <ExcelColumn label="Email" value="bar_email" />
                        <ExcelColumn label="Promotor" value="usu_nome" />
                        <ExcelColumn label="Campanha" value="camp_nome" />
                        <ExcelColumn
                          label="Distribuidor"
                          value="nome_distribuidora"
                        />
                        <ExcelColumn
                          label="Data de cadastro"
                          value="bar_data_cadastro"
                        />
                        <ExcelColumn
                          label="Status"
                          value={(col) =>
                            col.bar_ativo == 1 ? 'ATIVO' : 'INATIVO'
                          }
                        />
                      </ExcelSheet>
                    </ExcelFile>
                  </div>
                  <div className="col-md-4"></div>
                </div>
              </div>
            </form>

            <div className=" table-responsive">
              <table className="table  table-hover table-striped table-light ">
                <thead className="text-white  bg-dark ">
                  <tr>
                    <th style={{ width: '85%' }} scope="col">
                      Bar
                    </th>
                    {/* <th scope="col">Proprietario</th>
                    <th scope="col">Endereço</th>
                    <th scope="col">Cidade</th>
                      <th scope="col">Status</th>*/}
                    <th style={{ width: '15%' }} scope="col">
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {baresPag.length > 0
                    ? baresPag.map(function (bar, i) {
                        var distribuidora_nome =
                          bar.nome_distribuidora.split(',');
                        // console.log(distribuidora_nome);

                        var Bar_distribuidor = [];
                        for (let i = 0; i < distribuidora_nome.length; i++) {
                          Bar_distribuidor.push({
                            distribuidora: RemoveAspasChaves(
                              distribuidora_nome[i],
                            ),
                          });
                        }
                        return (
                          <>
                            <tr key={bar.bar_id}>
                              <th>
                                {bar.bar_nomebar}
                                <br />
                                <i
                                  className="text-uppercase"
                                  style={{
                                    fontSize: '12px',
                                  }}
                                >
                                  {'Proprietário: ' + bar.bar_dono_bar}
                                  <br />
                                  {'Promotor: ' + bar.usu_nome}
                                  <br />
                                  {bar.cid_municipio + ' / ' + bar.cid_uf}
                                  <br />
                                  {Bar_distribuidor.map(function (dist, i) {
                                    return (
                                      <>
                                        {dist.distribuidora}
                                        <br />
                                      </>
                                    );
                                  })}

                                  {bar.bar_ativo == 1 ? (
                                    <>
                                      <span className="color-51comvc text-lowercase ">
                                        Ativo
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      {' '}
                                      <span className="text-lowercase ">
                                        Inativo
                                      </span>{' '}
                                    </>
                                  )}
                                  {/* <br />
                                  {bar.bar_bairro + " " + bar.bar_numero}
                                  <br />
                                  {bar.cid_municipio}
                                  <br />
                                {bar.di_nome}*/}
                                </i>
                              </th>
                              {/* <th>{bar.bar_dono_bar}</th>
                              <th>{bar.bar_endereco}</th>
                        <th>{bar.bar_cidade}</th>
                              <th>
                                {bar.bar_ativo === 1 ? (
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-success font-weight-bold"
                                  >
                                    Ativo
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-secondary font-weight-bold"
                                  >
                                    Inativo
                                  </button>
                                )}
                              </th>*/}
                              <td>
                                <button
                                  type="button"
                                  onClick={(e) => editar(bar)}
                                  className="btn btn-sm "
                                  data-toggle="modal"
                                  data-target=".bd-example-modal-xl"
                                >
                                  <img
                                    src={img_alterar}
                                    className="img-btn-acao"
                                    alt="logo"
                                  />
                                </button>

                                <button
                                  type="button"
                                  data-toggle="modal"
                                  data-target="#exampleModalCenter"
                                  onClick={(e) =>
                                    dadosModal(
                                      bar.bar_nomebar,
                                      bar.bar_id,
                                      bar.bar_ativo,
                                    )
                                  }
                                  className="btn btn-sm btn-excluir"
                                >
                                  <img
                                    src={img_excluir2}
                                    className="img-btn-acao"
                                    alt="logo"
                                  />
                                </button>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    : ''}
                </tbody>
              </table>
              <TablePagination
                component="div"
                count={totalPages}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={'Registros por página'}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModalCenter"
        tabIndex="1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="card">
              <div className="card-header"></div>
              <span className="span-card-titulo">
                <img src={img_usuario} className="img-card" alt="logo" />{' '}
                Excluir bar
              </span>
              <div id="triangulo-para-baixo"></div>
              <div className="card-body">
                Tem certeza que deseja excluir o bar
                <i>
                  <b className="text-danger">"</b>
                  {nomeModal}
                  <b className="text-danger">"</b>
                </i>{' '}
              </div>
              <div className="modal-footer-alert">
                <button
                  className="btn btn-sm btn-51comvc "
                  type="button"
                  data-dismiss="modal"
                >
                  {Times}
                  &nbsp;Cancelar
                </button>
                &nbsp;&nbsp;
                <button
                  className="btn btn-sm btn-51comvc "
                  onClick={(e) => save(e, 'delete', 0)}
                  type="button"
                  data-dismiss="modal"
                >
                  Excluir
                  <img src={img_excluir} className="img-btn" alt="logo" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Clientes;
